import { BEAT_TYPE } from 'constant/EventConst';
import { TAB_VALUE_OBJECT } from 'constant/Const';

import { BeatType } from '@type/ecgEventType/baseEventType';
import {
  optimisticEventDataUpdateForTimeEventType,
  OptimisticEventDataUpdateForBeats,
  optimisticEventDataUpdateCase,
} from '@type/optimisticUpdate/type';
import { BeatsNBeatEvents } from '@type/beatNEctopic/process';
import { WaveformIndex } from '@type/ecgEventType/eventUnit';

import { optimisticVerification } from '../verificationUtil';

export interface findIndexCondition {
  cursor: any;
  target: any;
}

export class Command {
  executeClass: any;
  undo: any;
  value: any;

  constructor(executeClass: any, undo: any, value: any) {
    this.executeClass = executeClass;
    this.undo = undo;
    this.value = value;
  }
}

export interface VerificationData {
  tabType:
    | typeof TAB_VALUE_OBJECT.BEAT_REVIEW
    | typeof TAB_VALUE_OBJECT.EVENT_REVIEW;
  currentTimeStamp: Date | number;
  reqTime: any; // utc
  reqBody: any;
  response: any;
  eventType: BeatType | optimisticEventDataUpdateForTimeEventType;
  waveformIndex:
    | WaveformIndex[]
    | { leftWaveformIndex: WaveformIndex; rightWaveformIndex: WaveformIndex };
  waveformIndexRage: WaveformIndex[] | undefined;
  isRemove: boolean | undefined;
}

// ### Receiver 역할
export class EventUpdate {
  current: any;
  commands: any[] = [];
  private _isBeatUpdated: boolean = false;

  action(command: any) {}

  *execute(command: any) {
    yield command.execute(this.current);
    // todo: jyoon(240815) - undo하는 기능 비활성화함 - 추후에 undo 기능 활성화할 경우 주석 해제
    // this.commands.push(command);
    // this.verifyOptimisticUpdate(command);
    const updateCaseType =
      command.command.value.updateReqOption.optimisticEventDataUpdateCase;
    this.setIsBeatUpdated(updateCaseType);
  }

  private verifyOptimisticUpdate(command: any) {
    const updateReqOption = command.command.value.updateReqOption;
    const currentUTC = new Date(updateReqOption.reqTime).toUTCString();
    const currentTimeStamp = updateReqOption.reqTime;
    const editTarget = getWaveformIndexes(
      command.command.value.filterBeatsNEctopicList
    );

    let reqBody, eventType, waveformIndexRage, isRemove;
    if (!updateReqOption.reqBody) {
      // timeEvent
      reqBody = updateReqOption;
      eventType = updateReqOption.timeEventType;
      waveformIndexRage = [
        updateReqOption.onsetWaveformIndex,
        updateReqOption.terminationWaveformIndex,
      ];
      isRemove = updateReqOption.isRemove;
    } else {
      reqBody = updateReqOption.reqBody;
      eventType = Object.entries(BEAT_TYPE).find(
        ([key, value]) => value === updateReqOption.reqBody.beatType
      )?.[0];
      waveformIndexRage = [
        updateReqOption.onsetWaveformIndex,
        updateReqOption.terminationWaveformIndex,
      ];

      isRemove = undefined;
    }

    const verificationData: VerificationData = {
      tabType: TAB_VALUE_OBJECT.EVENT_REVIEW,
      currentTimeStamp,
      reqTime: currentUTC,
      reqBody,
      response: null,
      eventType,
      waveformIndex: editTarget,
      waveformIndexRage,
      isRemove,
    };
    optimisticVerification.setVerificationDataList(verificationData);

    function getWaveformIndexes(filterBeatsNEctopicList: any) {
      if (
        filterBeatsNEctopicList === undefined ||
        filterBeatsNEctopicList.length === 0
      ) {
        return;
      }
      if (updateReqOption.reqBody.leftWaveformIndex !== undefined) {
        const mergeBeatsNEctopicList = getMergeBeatsNEctopicList(
          command.command.value.filterBeatsNEctopicList
        );
        const waveformIndexes = mergeBeatsNEctopicList.waveformIndex.filter(
          (waveformIndex) => {
            const { leftWaveformIndex, rightWaveformIndex } =
              updateReqOption.reqBody;
            return (
              leftWaveformIndex <= waveformIndex &&
              waveformIndex <= rightWaveformIndex
            );
          }
        );

        return waveformIndexes;
      } else if (updateReqOption.reqBody.waveformIndexes !== undefined) {
        return updateReqOption.reqBody.waveformIndexes;
      }
      return;
    }
    function getMergeBeatsNEctopicList(
      filterBeatsNEctopicList: BeatsNBeatEvents[]
    ) {
      const initResult: OptimisticEventDataUpdateForBeats = {
        beatType: [],
        hr: [],
        waveformIndex: [],
      };

      const mergedBeatsNEctopicList: OptimisticEventDataUpdateForBeats =
        filterBeatsNEctopicList.reduce((acc, cur) => {
          acc.beatType = [...acc.beatType, ...cur.beats.beatType];
          acc.hr = [...acc.hr, ...cur.beats.hr];
          acc.waveformIndex = [
            ...acc.waveformIndex,
            ...cur.beats.waveformIndex,
          ];

          return acc;
        }, initResult);

      return mergedBeatsNEctopicList;
    }
  }

  // todo: jyoon(240815) - undo기능 미리 구현
  undo() {
    const command = this.commands.pop();
    this.current = command.undo(this.current, command.value);
  }

  getCurrentValue() {}

  getResult() {}

  getIsBeatUpdated(): boolean {
    return this._isBeatUpdated;
  }

  setIsBeatUpdated(updateCaseType: optimisticEventDataUpdateCase) {
    // pause event list 를 불러와야하는지에 대한 여부
    // 비트편집이 발생하고나서, 30초차트에서 pause이벤트를 클릭해 상세조회할 때, 서버에 있는 값을 가져오기 위해 사용
    const isBeatUpdated = [
      optimisticEventDataUpdateCase.postBeats,
      optimisticEventDataUpdateCase.patchBeatsByWaveformIndexList,
      optimisticEventDataUpdateCase.patchBeatsByRangeList,
      optimisticEventDataUpdateCase.deleteBeats,
    ].includes(updateCaseType);

    this.setIsBeatUpdatedFlag(isBeatUpdated);
  }
  setIsBeatUpdatedFlag(isBeatUpdated: boolean) {
    this._isBeatUpdated = isBeatUpdated;
  }
}

// export 설정
// todo: jyoon - eventUpdateInst로 의해서 event review optimistic update를 수행하는 내용 문서에 작성
export const eventUpdateInst = new EventUpdate();
