import ReactINTL, { defineMessages, useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';

import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

import { type ITheme } from 'theme/StyledComponentsTheme';

interface AccountLockedDialogProps {
  open: boolean;
  params: {
    passwordIncorrectLimit: number;
    loginLockoutTtlSec: number;
    onClickShowResetPasswordDialog: () => void;
  };
  callback: () => void;
  onClose: () => void;
}

function AccountLockedDialog(props: AccountLockedDialogProps) {
  const theme = useTheme() as ITheme;

  const { open, params, onClose } = props;
  const {
    passwordIncorrectLimit,
    loginLockoutTtlSec,
    onClickShowResetPasswordDialog,
  } = params;

  const loginLockoutTtlMin = Math.floor(loginLockoutTtlSec / 60);

  const { t } = useIntlMsg();
  return (
    <DialogWrapper open={open} width={360}>
      <TitleText>
        {t(INTL_MAP.ACCOUNT_LOCKED_DIALOG_TITLE, {
          passwordIncorrectLimit,
        })}
      </TitleText>

      <MessageContainer>
        <MessageText>
          {t(INTL_MAP.ACCOUNT_LOCKED_DIALOG_DESCRIPTION_01)}
          <br />
          <span>{t(INTL_MAP.ACCOUNT_LOCKED_DIALOG_DESCRIPTION_02)}</span>
          <br />
          <span>
            {t(INTL_MAP.ACCOUNT_LOCKED_DIALOG_DESCRIPTION_03, {
              loginLockoutTtlMin,
            })}
          </span>
        </MessageText>
      </MessageContainer>

      <DialogButtonWrapper>
        <TextButton
          textColor={theme.color.COOL_GRAY_80}
          title={t(INTL_MAP.DIALOG_CLOSE_BUTTON)}
          onClick={() => {
            onClose();
          }}
        />
        <TextButton
          title={t(INTL_MAP.DIALOG_RESET_PASSWORD_BUTTON)}
          onClick={() => {
            onClickShowResetPasswordDialog();
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

const TitleText = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageContainer = styled.div`
  margin-bottom: 16px;
  font-size: 12px;

  span {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

const MessageText = styled.div`
  color: ${(props) => props.theme.color.BLACK};
`;

function useIntlMsg() {
  //@todo @ykmo - global useIntlMsg로 변경 필요
  const intl = useIntl();
  const t = (messageDescriptor: ReactINTL.MessageDescriptor, values = {}) =>
    intl.formatMessage(messageDescriptor, values);

  return { t };
}

const INTL_MAP = defineMessages({
  DIALOG_CLOSE_BUTTON: {
    id: '99-Dialog-Button-title-close',
    description: '팝업 Dialog의 닫기 버튼',
    defaultMessage: '닫기',
  },
  DIALOG_RESET_PASSWORD_BUTTON: {
    id: '99-AccountLockedDialog-Button-title-close',
    description: '비밀번호 재설정하기 / Reset Password',
    defaultMessage: '비밀번호 재설정하기',
  },
  ACCOUNT_LOCKED_DIALOG_TITLE: {
    id: '99-AccountLockedDialog-TitleText',
    description:
      'passwordIncorrectLimit회 로그인을 실패하여 보안상의 이유로 계정이 잠겼습니다. / You have entered the wrong password {passwordIncorrectLimit} times.',
    defaultMessage:
      '{passwordIncorrectLimit}회 로그인을 실패하여 보안상의 이유로 계정이 잠겼습니다.',
  },
  ACCOUNT_LOCKED_DIALOG_DESCRIPTION_01: {
    id: '99-AccountLockedDialog-DescText-01',
    description: '계정 잠금을 해제하시려면 / To unlock your account',
    defaultMessage: '계정 잠금을 해제하시려면',
  },
  ACCOUNT_LOCKED_DIALOG_DESCRIPTION_02: {
    id: '99-AccountLockedDialog-DescText-02',
    description:
      '비밀번호 재설정 후에 로그인을 재시도하시거나 / please reset your password and attempt to log in again',
    defaultMessage: '비밀번호 재설정 후에 로그인을 재시도하시거나',
  },
  ACCOUNT_LOCKED_DIALOG_DESCRIPTION_03: {
    id: '99-AccountLockedDialog-DescText-03',
    description:
      '{loginLockoutTtlMin분 후 로그인을 재시도해주세요. / or try logging in after {loginLockoutTtlMin} minute.',
    defaultMessage: '{loginLockoutTtlMin}분 후 로그인을 재시도해주세요.',
  },
});

export default AccountLockedDialog;
