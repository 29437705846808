import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import {
  selectIsCreditAlertShow,
  hideCreditAlert,
} from 'redux/duck/feedback/feedbackDuck';

function useCreditAlertManagement() {
  const dispatch = useDispatch();

  const isShow: boolean = useSelector(selectIsCreditAlertShow, shallowEqual);

  const closeAlert = () => {
    dispatch(hideCreditAlert());
  };

  return {
    isShow,
    closeAlert,
  };
}

export default useCreditAlertManagement;
