import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager/ApiManager';
import StatusCode from 'network/StatusCode';
import { resetPasswordWithTokenRequested } from './passwordDuck';
import Const from 'constant/Const';

// Actions
// Create
const CREATE_MEDICAL_STAFFS_REQUESTED =
  'memo-web/medical-staffs/CREATE_MEDICAL_STAFFS_REQUESTED';
const CREATE_MEDICAL_STAFFS_SUCCEED =
  'memo-web/medical-staffs/CREATE_MEDICAL_STAFFS_SUCCEED';
const CREATE_MEDICAL_STAFFS_FAILED =
  'memo-web/medical-staffs/CREATE_MEDICAL_STAFFS_FAILED';
const UPDATE_MEDICAL_STAFFS_REQUESTED =
  'memo-web/medical-staffs/UPDATE_MEDICAL_STAFFS_REQUESTED';
const UPDATE_MEDICAL_STAFFS_SUCCEED =
  'memo-web/medical-staffs/UPDATE_MEDICAL_STAFFS_SUCCEED';
const UPDATE_MEDICAL_STAFFS_FAILED =
  'memo-web/medical-staffs/UPDATE_MEDICAL_STAFFS_FAILED';
const REMOVE_MEDICAL_STAFFS_REQUESTED =
  'memo-web/medical-staffs/REMOVE_MEDICAL_STAFFS_REQUESTED';
const REMOVE_MEDICAL_STAFFS_SUCCEED =
  'memo-web/medical-staffs/REMOVE_MEDICAL_STAFFS_SUCCEED';
const REMOVE_MEDICAL_STAFFS_FAILED =
  'memo-web/medical-staffs/REMOVE_MEDICAL_STAFFS_FAILED';
// Fetch (Multiple)
const FETCH_MEDICAL_STAFFS_REQUESTED =
  'memo-web/medical-staffs/FETCH_MEDICAL_STAFFS_REQUESTED';
const FETCH_MEDICAL_STAFFS_SUCCEED =
  'memo-web/medical-staffs/FETCH_MEDICAL_STAFFS_SUCCEED';
const FETCH_MEDICAL_STAFFS_FAILED =
  'memo-web/medical-staffs/FETCH_MEDICAL_STAFFS_FAILED';

// Reducer
const initialState = {
  cu: {
    pending: false,
    data: null,
    error: null,
  },
  remove: {
    pending: false,
    data: null,
    error: null,
  },
  fetch: {
    pending: false,
    firstPage: 1,
    currentPage: 1,
    lastPage: null,
    totalCount: null,
    data: [],
    error: null,
  },
  fetchByQuery: {
    pending: false,
    data: null,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Create
    case CREATE_MEDICAL_STAFFS_REQUESTED:
    case UPDATE_MEDICAL_STAFFS_REQUESTED:
      return {
        ...state,
        cu: {
          ...state.cu,
          pending: true,
          error: null,
        },
      };
    case CREATE_MEDICAL_STAFFS_SUCCEED:
    case UPDATE_MEDICAL_STAFFS_SUCCEED:
      return {
        ...state,
        cu: {
          ...state.cu,
          pending: false,
          data: action.data,
        },
      };
    case CREATE_MEDICAL_STAFFS_FAILED:
    case UPDATE_MEDICAL_STAFFS_FAILED:
      return {
        ...state,
        cu: {
          ...state.cu,
          pending: false,
          error: action.error,
        },
      };
    case REMOVE_MEDICAL_STAFFS_REQUESTED:
      return {
        ...state,
        remove: {
          ...state.remove,
          pending: true,
          error: null,
        },
      };
    case REMOVE_MEDICAL_STAFFS_SUCCEED:
      return {
        ...state,
        remove: {
          ...state.remove,
          pending: false,
          data: action.data,
        },
      };
    case REMOVE_MEDICAL_STAFFS_FAILED:
      return {
        ...state,
        remove: {
          ...state.remove,
          pending: false,
          error: action.error,
        },
      };
    // Fetch (Multiple)
    case FETCH_MEDICAL_STAFFS_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true,
          error: null,
        },
      };
    case FETCH_MEDICAL_STAFFS_SUCCEED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          currentPage: action.currentPage,
          lastPage: action.lastPage,
          totalCount: action.totalCount,
          data: action.data,
        },
      };
    case FETCH_MEDICAL_STAFFS_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Create
export function createMedicalStaffRequested(
  firstName,
  email,
  staffType,
  accessLevel,
  isHospitalAdmin
) {
  return {
    type: CREATE_MEDICAL_STAFFS_REQUESTED,
    firstName,
    email,
    staffType,
    accessLevel,
    isHospitalAdmin,
  };
}
function createMedicalStaffSucceed(data) {
  return {
    type: CREATE_MEDICAL_STAFFS_SUCCEED,
    data: data,
  };
}
function createMedicalStaffFailed(error) {
  return {
    type: CREATE_MEDICAL_STAFFS_FAILED,
    error: error,
  };
}
export function updateMedicalStaffRequested(username, staffType, accessLevel) {
  return {
    type: UPDATE_MEDICAL_STAFFS_REQUESTED,
    username,
    staffType,
    accessLevel,
  };
}
function updateMedicalStaffSucceed(data) {
  return {
    type: UPDATE_MEDICAL_STAFFS_SUCCEED,
    data: data,
  };
}
function updateMedicalStaffFailed(error) {
  return {
    type: UPDATE_MEDICAL_STAFFS_FAILED,
    error: error,
  };
}
export function removeMedicalStaffRequested(username) {
  return {
    type: REMOVE_MEDICAL_STAFFS_REQUESTED,
    username,
  };
}
function removeMedicalStaffSucceed(data) {
  return {
    type: REMOVE_MEDICAL_STAFFS_SUCCEED,
    data: data,
  };
}
function removeMedicalStaffFailed(error) {
  return {
    type: REMOVE_MEDICAL_STAFFS_FAILED,
    error: error,
  };
}
// Fetch (Multiple)
export function fetchMedicalStaffsRequested(
  hid,
  page,
  pageSize,
  queryKeyword,
  staffType = '',
  ordering
) {
  return {
    type: FETCH_MEDICAL_STAFFS_REQUESTED,
    hid,
    page,
    pageSize,
    queryKeyword,
    staffType,
    ordering,
  };
}
function fetchMedicalStaffsSucceed(currentPage, lastPage, totalCount, data) {
  return {
    type: FETCH_MEDICAL_STAFFS_SUCCEED,
    currentPage: currentPage,
    lastPage: lastPage,
    totalCount: totalCount,
    data: data,
  };
}
function fetchMedicalStaffsFailed(error) {
  return { type: FETCH_MEDICAL_STAFFS_FAILED, error: error };
}

// Sagas
function* createMedicalStaff(action) {
  try {
    const { status, data } = yield call(
      ApiManager.createMedicalStaff,
      action.firstName,
      action.email,
      action.staffType,
      action.accessLevel,
      action.isHospitalAdmin
    );

    // action.staffType 에 따라 Docter(1) 인 경우엔 메일 발송 생략
    if (action.staffType !== Const.STAFF_TYPE.DOCTOR.value) {
      yield put(resetPasswordWithTokenRequested(action.email));
    }

    yield put(createMedicalStaffSucceed(data));
  } catch (error) {
    yield put(createMedicalStaffFailed(error));
  }
}

function* updateMedicalStaff(action) {
  try {
    const { status, data } = yield call(
      ApiManager.updateMedicalStaff,
      action.username,
      action.staffType,
      action.accessLevel
    );

    yield put(updateMedicalStaffSucceed(data));
  } catch (error) {
    yield put(updateMedicalStaffFailed(error));
  }
}

function* removeMedicalStaff(action) {
  try {
    const { status, data } = yield call(
      ApiManager.deleteMedicalStaff,
      action.username
    );

    yield put(removeMedicalStaffSucceed(data));
  } catch (error) {
    yield put(removeMedicalStaffFailed(error));
  }
}

function* readMedicalStaffs(action) {
  try {
    const { status, data } = yield call(
      ApiManager.readMedicalStaffs,
      action.hid,
      action.page,
      action.pageSize,
      action.queryKeyword,
      action.staffType,
      action.ordering
    );

    const { count, next, previous, results } = data;
    const lastPage = Math.ceil(count / action.pageSize);
    yield put(fetchMedicalStaffsSucceed(action.page, lastPage, count, results));
  } catch (error) {
    yield put(fetchMedicalStaffsFailed(error));
  }
}

export function* saga() {
  yield takeLatest(CREATE_MEDICAL_STAFFS_REQUESTED, createMedicalStaff);
  yield takeLatest(UPDATE_MEDICAL_STAFFS_REQUESTED, updateMedicalStaff);
  yield takeLatest(REMOVE_MEDICAL_STAFFS_REQUESTED, removeMedicalStaff);
  yield takeLatest(FETCH_MEDICAL_STAFFS_REQUESTED, readMedicalStaffs);
}
