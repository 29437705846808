import styled from 'styled-components';

import { HEIGHT_HEADER } from 'constant/StyleConst';

import HeaderContainer from 'redux/container/HeaderContainer';

import Footer from 'component/base/Footer';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding-top: ${HEIGHT_HEADER}px;
`;

function Layout(props) {
  return (
    <Wrapper>
      <HeaderContainer />
      <Content>{props.children}</Content>
      {false && <Footer />}
    </Wrapper>
  );
}

export default Layout;
