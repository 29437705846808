/**
 * 신규 검사정보 생성, 검사 정보 수정에 사용되는 로직을 정의한 util
 */

import Const from 'constant/Const';

/**
 * 검증 처리된 처방일수를 반환
 *
 * @param text 유저가 입력하는 처방일수 텍스트
 * @returns number
 */
export const getParsedTestDuration = (
  text: string
): string | number | undefined => {
  const dayString = text.replace(/\D/g, '');
  const DAY_LENGTH_LIMIT = 2;

  const isInValidLength = dayString.length > DAY_LENGTH_LIMIT;
  if (isInValidLength) return undefined;

  const regex = /^[0-9]+$/g;
  const isInValidText = !regex.test(text) || text === '' || text === '0';
  if (isInValidText) {
    return '';
  }

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  return clamp(
    Number(text),
    Const.MIN_PRESCRIPTION_DURATION_DAY,
    Const.MAX_PRESCRIPTION_DURATION_DAY
  );
};
