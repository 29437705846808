import { defineMessages } from 'react-intl';

import {
  UNDER_TWO_DAYS,
  UNDER_SEVEN_DAYS,
  UNDER_FOURTEEN_DAYS,
  PATCH_LIST_SORT_ORDER,
} from 'constant/StatisticsConst';

const INTL_MAP = defineMessages({
  ANALYTICS_TITLE: {
    id: '00-StatisticsPage-analytics-title',
    description: '분석 통계',
    defaultMessage: '분석 통계',
  },
  ANALYTICS_SUBTITLE_PRE_PAID: {
    id: '00-StatisticsPage-analytics-subtitle-pre-paid',
    description: '처방 구간별 크레딧 및 검사 현황',
    defaultMessage: '처방 구간별 크레딧 및 검사 현황',
  },
  ANALYTICS_SUBTITLE_POST_PAID: {
    id: '00-StatisticsPage-analytics-subtitle-post-paid',
    description: '처방 구간별 검사 현황',
    defaultMessage: '처방 구간별 검사 현황',
  },
  ANALYTICS_HELP_MESSAGE: {
    id: '00-StatisticsPage-analytics-help-message',
    description:
      '크레딧은 처방 구간별로 차감됩니다.\n48시간 이내 2 크레딧ㅣ48시간 초과~7일 이내 3 크레딧ㅣ7일 초과~14일 이내 10 크레딧',
    defaultMessage:
      '크레딧은 처방 구간별로 차감됩니다.\n48시간 이내 {underTwoDays} 크레딧ㅣ48시간 초과~7일 이내 {underSevenDays} 크레딧ㅣ7일 초과~14일 이내 {underFourteenDays} 크레딧',
  },
  ANALYTICS_ACCUMULATED: {
    id: '00-StatisticsPage-analytics-accumulated',
    description: '누적 충전 크레딧',
    defaultMessage: '누적 충전 크레딧',
  },
  ANALYTICS_CHARGED: {
    id: '00-StatisticsPage-analytics-charged',
    description: '사용 크레딧 수',
    defaultMessage: '사용 크레딧 수',
  },
  ANALYTICS_REMAINED: {
    id: '00-StatisticsPage-analytics-remained',
    description: '잔여 크레딧 수',
    defaultMessage: '잔여 크레딧 수',
  },
  ANALYTICS_PENDING: {
    id: '00-StatisticsPage-analytics-pending',
    description: '차감예정 : 0',
    defaultMessage: '차감예정 : {value}',
  },
  ANALYTICS_WILL_CHARGE_HELP: {
    id: '00-StatisticsPage-analytics-will-charge-help',
    description: '현재 진행중인 검사의 차감 예정인 크레딧 수',
    defaultMessage: '현재 진행중인 검사의 차감 예정인 크레딧 수',
  },
  ANALYTICS_COMPLETED: {
    id: '00-StatisticsPage-analytics-completed',
    description: '분석 완료된 검사 수',
    defaultMessage: '분석 완료된 검사 수',
  },
  ANALYTICS_COUNT_UNIT: {
    id: '00-StatisticsPage-analytics-count-unit',
    description: '16 건',
    defaultMessage: '건',
  },
  ANALYTICS_USED_CREDIT: {
    id: '00-StatisticsPage-analytics-used-credit',
    description: '(2 크레딧)',
    defaultMessage: '({value} 크레딧)',
  },
  ANALYTICS_ONGOING: {
    id: '00-StatisticsPage-analytics-ongoing',
    description: '진행 중인 검사',
    defaultMessage: '진행 중인 검사',
  },

  [`STATS_${UNDER_TWO_DAYS}`]: {
    id: '00-StatisticsPage-analytics-under-two-days',
    description: '48시간 이내',
    defaultMessage: '48시간 이내',
  },
  [`STATS_${UNDER_SEVEN_DAYS}`]: {
    id: '00-StatisticsPage-analytics-under-seven-days',
    description: '48시간~7일 이내',
    defaultMessage: '48시간~7일 이내',
  },
  [`STATS_${UNDER_FOURTEEN_DAYS}`]: {
    id: '00-StatisticsPage-analytics-under-fourteen-days',
    description: '7일~14일 이내',
    defaultMessage: '7일~14일 이내',
  },

  PATCH_TITLE: {
    id: '00-StatisticsPage-patch-title',
    description: '패치 사용 통계',
    defaultMessage: '패치 사용 통계',
  },
  [PATCH_LIST_SORT_ORDER.SERIAL_NUMBER]: {
    id: '00-StatisticsPage-patch-serial-number',
    description: '패치 일련번호',
    defaultMessage: '패치 일련번호',
  },
  [PATCH_LIST_SORT_ORDER.PATCH_STATUS]: {
    id: '00-StatisticsPage-patch-status',
    description: '상태',
    defaultMessage: '상태',
  },
  PATCH_STAND_BY: {
    id: '00-StatisticsPage-patch-stand-by',
    description: '사용 가능',
    defaultMessage: '사용 가능',
  },
  PATCH_RECORDING: {
    id: '00-StatisticsPage-patch-recording',
    description: '측정중',
    defaultMessage: '측정중',
  },
  PATCH_READY_TO_UPLOAD: {
    id: '00-StatisticsPage-patch-ready-to-upload',
    description: '업로드 대기',
    defaultMessage: '업로드 대기',
  },
  PATCH_EXPIRED: {
    id: '00-StatisticsPage-patch-expired',
    description: '사용 만료',
    defaultMessage: '사용 만료',
  },
  PATCH_INVALID: {
    id: '00-StatisticsPage-patch-invalid',
    description: '사용 불가',
    defaultMessage: '사용 불가',
  },
  [PATCH_LIST_SORT_ORDER.USED]: {
    id: '00-StatisticsPage-patch-used',
    description: '사용횟수',
    defaultMessage: '사용횟수',
  },
  [PATCH_LIST_SORT_ORDER.REMAINING]: {
    id: '00-StatisticsPage-patch-remaining',
    description: '잔여사용횟수',
    defaultMessage: '잔여사용횟수',
  },
  PATCH_PAGE_NATION: {
    id: '00-StatisticsPage-patch-page-nation',
    description: '100개 중 1-20',
    defaultMessage: '{total}개 중 {start}-{end}',
  },

  // TODO: joonhonoh - revamp 병합 전 임시!
  EMPTY_LABEL_01: {
    id: '03-EcgTestListFragment-TableRowEmptyLabel-01',
    description: '데이터가 없습니다.',
    defaultMessage: '데이터가 없습니다.',
  },
  EMPTY_LABEL_02: {
    id: '03-EcgTestListFragment-TableRowEmptyLabel-02',
    description: '검색 결과가 없습니다.',
    defaultMessage: '검색 결과가 없습니다.',
  },
});

export default INTL_MAP;
