import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager/ApiManager';
import StatusCode from 'network/StatusCode';

// Actions
// Calculate
const CALCULATE_REPORT_REQUESTED =
  'memo-web/reports/CALCULATE_REPORT_REQUESTED';
const CALCULATE_REPORT_SUCCEED = 'memo-web/reports/CALCULATE_REPORT_SUCCEED';
const CALCULATE_REPORT_FAILED = 'memo-web/reports/CALCULATE_REPORT_FAILED';
// Validate
const VALIDATE_REPORT_REQUESTED = 'memo-web/reports/VALIDATE_REPORT_REQUESTED';
const VALIDATE_REPORT_SUCCEED = 'memo-web/reports/VALIDATE_REPORT_SUCCEED';
const VALIDATE_REPORT_FAILED = 'memo-web/reports/VALIDATE_REPORT_FAILED';

// Reducer
const initialState = {
  calculate: {
    pending: false,
    data: null,
    error: null,
  },
  validate: {
    pending: false,
    data: null,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Calculate
    case CALCULATE_REPORT_REQUESTED:
      return {
        ...state,
        calculate: {
          ...state.calculate,
          pending: true,
          error: null,
        },
      };
    case CALCULATE_REPORT_SUCCEED:
      return {
        ...state,
        calculate: {
          ...state.calculate,
          pending: false,
          data: action.data,
        },
      };
    case CALCULATE_REPORT_FAILED:
      return {
        ...state,
        calculate: {
          ...state.calculate,
          pending: false,
          error: action.error,
        },
      };
    // Validate
    case VALIDATE_REPORT_REQUESTED:
      return {
        ...state,
        validate: {
          ...state.validate,
          pending: true,
          error: null,
        },
      };
    case VALIDATE_REPORT_SUCCEED:
      return {
        ...state,
        validate: {
          ...state.validate,
          pending: false,
          data: action.data,
        },
      };
    case VALIDATE_REPORT_FAILED:
      return {
        ...state,
        validate: {
          ...state.validate,
          pending: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Calculate
export function calculateReportsRequested(reportId) {
  return { type: CALCULATE_REPORT_REQUESTED, reportId: reportId };
}
function calculateReportsSucceed(data) {
  return {
    type: CALCULATE_REPORT_SUCCEED,
    data: data,
  };
}
function calculateReportsFailed(error) {
  return { type: CALCULATE_REPORT_FAILED, error: error };
}
// Validate
export function validateReportsRequested(reportId) {
  return { type: VALIDATE_REPORT_REQUESTED, reportId: reportId };
}
function validateReportsSucceed(data) {
  return {
    type: VALIDATE_REPORT_SUCCEED,
    data: data,
  };
}
function validateReportsFailed(error) {
  return { type: VALIDATE_REPORT_FAILED, error: error };
}

// Sagas
function* calculateReport(action) {
  try {
    const { status, data } = yield call(
      ApiManager.calculateReport,
      action.reportId
    );

    const { patient } = data;
    yield put(calculateReportsSucceed(patient));
  } catch (error) {
    yield put(calculateReportsFailed(error));
  }
}

function* validateReport(action) {
  try {
    const { status, data } = yield call(
      ApiManager.getReportIsValid,
      action.reportId
    );

    const { result } = data;
    yield put(validateReportsSucceed(result));
  } catch (error) {
    yield put(validateReportsFailed(error));
  }
}

export function* saga() {
  yield takeLatest(CALCULATE_REPORT_REQUESTED, calculateReport);
  yield takeLatest(VALIDATE_REPORT_REQUESTED, validateReport);
}
