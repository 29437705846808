import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import LocalStorageKey from 'constant/LocalStorageKey';

import DateUtil from 'util/DateUtil';
import { GaUtil } from 'util/GaUtil';

import { DialogWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

import LocalStorageManager from 'manager/LocalStorageManager';

import { ReactComponent as CloseIcon } from 'static/icon/icon-close-x.svg';

export const NOTICE_DIALOG_ENV = {
  startDate: process.env.REACT_APP_NOTICE_DIALOG_START_DATE,
  endDate: process.env.REACT_APP_NOTICE_DIALOG_END_DATE,
  clickedStaticURL: process.env.REACT_APP_NOTICE_DIALOG_CLICKED_STATIC_URL,
  svgKoStaticURL: process.env.REACT_APP_NOTICE_DIALOG_SVG_KO_STATIC_URL,
  svgEngStaticURL: process.env.REACT_APP_NOTICE_DIALOG_SVG_EN_STATIC_URL,
};

function NoticeDialog(props) {
  const { open, params, callback, onClose, zIndex } = props;
  const {
    showCloseIcon,
    noticeContentUrl,
    toGoUrl,
    handleOnclick,
    confirmButtonText,
  } = params;

  const intl = useIntl();

  const [doNotShowToday, setDoNotShowToday] = useState(false);

  useEffect(() => {
    GaUtil.NOTICE_POP_UP_VIEWED();
  }, []);

  const setDoNotShowTilThisDate = () => {
    const doNotShowTilThisDate = DateUtil.getUserLocationTime().setHours(
      24,
      0,
      0,
      0
    );

    LocalStorageManager.setItem(
      LocalStorageKey.DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE,
      doNotShowTilThisDate
    );
  };

  const onClickDoNotShowToday = () => {
    setDoNotShowToday((prev) => !prev);
    GaUtil.NOTICE_POPUP_DO_NOW_SHOW_TODAY_CLICK();
  };

  const handleCloseBtnClick = () => {
    if (doNotShowToday) {
      setDoNotShowTilThisDate();
    }
    onClose();
  };

  if (window.location.pathname !== '/') return null;

  return (
    <DialogWrapper
      zIndex={zIndex ?? 12}
      open={open}
      innerStyle={{ padding: '0px', position: 'relative' }}>
      <SvgWrapper
        showCursor={toGoUrl || NOTICE_DIALOG_ENV.clickedStaticURL}
        onClick={() => {
          if (toGoUrl) window.location.replace(`${toGoUrl}`);
          if (handleOnclick) handleOnclick();
        }}>
        {/* 공지 팝업 SVG 바꾸는 부분 */}
        <img src={noticeContentUrl} alt="notice" />
      </SvgWrapper>

      {showCloseIcon && (
        <CloseIconWrapper onClick={handleCloseBtnClick}>
          <CloseIcon />
        </CloseIconWrapper>
      )}

      <DialogButtonContainer>
        <DoNotShowTodayWrapper onClick={onClickDoNotShowToday}>
          <input type="checkbox" checked={doNotShowToday} onChange={() => {}} />
          <span>{intl.formatMessage(INTL_MAP.DO_NOT_OPEN_FOR_A_DAY)}</span>
        </DoNotShowTodayWrapper>
        <TextButton
          title={
            confirmButtonText ||
            intl.formatMessage(INTL_MAP.DIALOG_CLOSE_BUTTON)
          }
          onClick={() => {
            if (typeof callback === 'function') {
              callback();
            }
            handleCloseBtnClick();
          }}
        />
      </DialogButtonContainer>
    </DialogWrapper>
  );
}

const SvgWrapper = styled.div`
  cursor: ${({ showCursor }) => {
    if (showCursor) {
      return 'pointer';
    }
  }};
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const DialogButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const DoNotShowTodayWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  color: ${(props) => props.theme.color.DARK};
  margin-left: 21px;
  gap: 3px;
  cursor: pointer;
  input {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

const INTL_MAP = defineMessages({
  DO_NOT_OPEN_FOR_A_DAY: {
    id: '99-Dialog-Button-title-do-not-open-for-a-day',
    description: '오늘 다시 보지 않기',
    defaultMessage: '오늘 다시 보지 않기',
  },
  DIALOG_CLOSE_BUTTON: {
    id: '99-Dialog-Button-title-close',
    description: '팝업 Dialog의 닫기 버튼',
    defaultMessage: '닫기',
  },
});

export default NoticeDialog;
