const ResultCode = {
  AUTH_STATUS: {
    /**
     * @description 로그인 성공
     */
    NORMAL: 1000,

    /**
     * @description 로그인 성공, 패스워드 변경 권고
     */
    CHANGE_PASSWORD_REQUIRED: 1001,

    /**
     * @description 로그인 성공, 휴면 계정
     */
    DORMANT: 1002,

    /**
     * @description 이메일 체크 성공, 비밀번호 최초 설정 필요
     */
    INITIAL_PASSWORD_REQUIRED: 1003,

    /**
     * @description 이메일 체크 성공, 존재하지 않는 계정
     */
    NON_EXISTENT: 1004,
  },
};

export default ResultCode;
