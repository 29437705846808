import { Action } from 'redux';

// Actions
// Credit Alert
export const SHOW_CREDIT_ALERT = 'memo-web/feedback/SHOW_CREDIT_ALERT';
export const HIDE_CREDIT_ALERT = 'memo-web/feedback/HIDE_CREDIT_ALERT';
export const INIT_ClOSE_LOG_CREDIT_ALERT =
  'memo-web/feedback/INIT_ClOSE_LOG_CREDIT_ALERT';
export const HANDLE_CREDIT_ALERT_STATE =
  'memo-web/feedback/HANDLE_CREDIT_ALERT_STATE';

// Reducer
export type FeedbackReducerState = {
  /** 잔여 크레딧 경고 Alert */
  creditAlert: CreditAlertState;
};
export type CreditAlertState = {
  /** True 면 Alert 보여줌 */
  isShow: boolean;
  /** Alert 닫기 이력 */
  closeLog: number | null;
};

// Action Creators
export type FeedbackReducerAction =
  | ShowCreditAlertAction
  | HideCreditAlertAction
  | InitCloseLogCreditAlertAction
  | HandleCreditAlertStateAction;
// Credit Alert
export interface ShowCreditAlertAction
  extends Action<typeof SHOW_CREDIT_ALERT> {}
export interface HideCreditAlertAction
  extends Action<typeof HIDE_CREDIT_ALERT> {}
export interface InitCloseLogCreditAlertAction
  extends Action<typeof INIT_ClOSE_LOG_CREDIT_ALERT> {}
export interface HandleCreditAlertStateAction
  extends Action<typeof HANDLE_CREDIT_ALERT_STATE> {
  payload: CreditAlertState;
}
