/**
 * ❗️❗️❗️
 * *주의*
 * ❗️❗️❗️
 *
 * 하기 코드는 파트너 웹과 병원 웹 간 차이가 있습니다.
 */
import { isNotNullOrUndefined, optionalParameter } from 'util/Utility';

import RestClient from 'network/RestClient';
import UrlList from 'network/UrlList';

import { OptimisticEventDataUpdateForBeats } from '@type/optimisticUpdate/type';

import {
  ICreateEcgTestApiParams,
  IDeleteEcgTestParams,
  IEcgTestConfirmReview,
  IEcgTestEditDoneReview,
  IEcgTestReturnDeviceParams,
  IGetHrHistogramBinPrams as IGetHrHistogramBinDetailPrams,
  IGetHrHistogramPrams,
  IGetRrHistogramBinPrams as IGetRrHistogramBinDetailPrams,
  IGetRrHistogramPrams,
  IPatchEcgTestParams,
  IPatchRevertStatusParams,
} from './ApiManagerType';

/**
 * 함수 이름은 API 의 HTTP Method 종류와 Postman에 명명된 것을 기준으로 합니다.
 */
const ApiManager = {
  /** ---------- 신규 추가되는 API 아래에 리스트업(리뉴얼 후 정리예정) ----------- */

  // Events
  // getEventList: (params, callback) => {
  //   return RestClient.get(UrlList.getEventsUrl(), params, callback);
  // },

  // TimeEvents
  // getTimeEvents: (params) => {
  //   return RestClient.get(UrlList.getTimeEvents(params));
  // },

  // getTimeEventDetailById: (params) => {
  //   return RestClient.get(UrlList.getTimeEventDetailById(params));
  // },

  // Patch ECGs
  // getEctopicDetail: (params) => {
  //   return RestClient.get(
  //     UrlList.getEctopicDetailUrl(params.ecgTestId),
  //     params
  //   );
  // },

  getEcgsStatistics: (params) => {
    return RestClient.get(UrlList.getPatchEcgsIdStatistics(params));
  },

  // Time Event
  /**
   *
   * @param {*} param0
   * @param {*} callback
   * @param {?*} cancelToken
   * @returns {promise<array>}
   */
  getTimeEventList: ({ ...restParams }, callback, cancelToken) => {
    const params = {
      ...restParams,
    };
    return RestClient.getWithCancelToken(
      UrlList.getTimeEventsDirectUrl(),
      params,
      callback,
      { token: cancelToken }
    );
  },
  getTimeEventDetail: ({ timeEventId }, callback) => {
    const params = {};
    return RestClient.get(
      UrlList.getTimeEventsIdUrl(timeEventId),
      params,
      callback
    );
  },
  postTimeEvent: ({ ...params }, callback) => {
    return RestClient.post(UrlList.getTimeEventsUrl(), params, callback);
  },
  // Episode
  getPauseEventDetail: ({ ecgTestId, episodeId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getEpisodeDetailByIdUrl(ecgTestId, episodeId),
      params,
      callback
    );
  },
  getPauseWaveformIndexes: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getPauseWaveformIndexesUrl(ecgTestId),
      params,
      callback
    );
  },
  // Patch ECGs
  /**
   *
   * @param {{ecgTestId, onsetWaveformIndex, terminationWaveformIndex}} param0
   * @param {*} callback
   * @returns
   */
  getBeatsFilterWaveformIndexRange: (
    {
      ecgTestId,
      ...params
    }: {
      ecgTestId: string;
      params: {
        onsetWaveformIndex: number;
        terminationWaveformIndex: number;
      };
    },
    callback: () => void
  ): Promise<OptimisticEventDataUpdateForBeats> => {
    return RestClient.get(
      UrlList.getBeatsFilterWaveformIndexRangeUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   * orderBy = hr_avg, onset_waveform_index
   * ascending: boolean
   * @param {{ecgTestId, beatType: BeatType, ectopicType: EctopicType }}
   * @param {*} callback
   * @returns
   */
  getEctopicWaveformIndexes: (
    { ecgTestId, beatType, ectopicType },
    callback
  ) => {
    return RestClient.get(
      UrlList.getEctopicWaveformIndexesUrl(ecgTestId),
      { beatType, ectopicType },
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId: string, waveformIndexes: number[], sampleSize: number, withRegisteredStrip: boolean, withRaw: boolean, axiosSource?: any, signal?: any}}
   * @param {Function} [callback]
   * @returns
   */
  getBeatsFilterWaveformIndexWithSampleSize: (
    {
      ecgTestId,
      waveformIndexes,
      sampleSize,
      withRegisteredStrip,
      withRaw,
      waveformIndexesForRegisteredReport,
    },
    callback
  ) => {
    return RestClient.get(
      UrlList.getBeatsUrl(ecgTestId),
      {
        waveformIndexes,
        sampleSize,
        withRegisteredStrip,
        withRaw,
        ...optionalParameter({
          condition: isNotNullOrUndefined(waveformIndexesForRegisteredReport),
          key: 'waveformIndexesForRegisteredReport',
          value: waveformIndexesForRegisteredReport,
        }),
      },
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId, beatType, ectopicType, position, isIncludeAll}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicListFilterType: ({ ecgTestId, ...params }, callback?) => {
    return RestClient.get(
      UrlList.getEctopicFilterTypeUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId, waveformIndex}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicListFilterWaveformIndex: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getEctopicFilterWaveformIndexUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   *
   * @param {{ecgTestId, beatType, ectopicType, position, isIncludeAll = false, perPage = 10}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicFilterBulkType: ({ ecgTestId, ...params }, callback) => {
    if (params.source) {
      return RestClient.getWithCancelToken(
        UrlList.getEctopicFilterBulkTypeUrl(ecgTestId),
        params,
        callback,
        params.source
      );
    } else {
      return RestClient.get(
        UrlList.getEctopicFilterBulkTypeUrl(ecgTestId),
        params,
        callback
      );
    }
  },
  /**
   *
   * @param {{ecgTestId, onsetWaveformIndex, terminationWaveformIndex}} param0
   * @param {*} callback
   * @returns
   */
  getEctopicListFilterWaveformIndexRange: (
    { ecgTestId, ...params },
    callback
  ) => {
    return RestClient.get(
      UrlList.getEctopicFilterWaveformIndexRangeUrl(ecgTestId),
      params,
      callback
    );
  },
  // Geminy
  /**
   * @param {{ecgTestId, beatType, geminyType, position, ordering}} param0
   * @param {*} callback
   * @returns
   * */
  getGeminyListFilterType: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getGeminyFilterTypeUrl(ecgTestId),
      params,
      callback
    );
  },
  /**
   * @param {{ecgTestId, waveformIndex}} param0
   * @param {*} callback
   * @returns
   */
  getGeminyListFilterWaveformIndex: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getGeminyFilterWaveformIndexUrl(ecgTestId),
      params,
      callback
    );
  },
  getDailyStatChart: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getPatchEcgsIdDailyStatChart(ecgTestId),
      params,
      callback
    );
  },
  getRawEcg: ({ ecgTestId, ...params }, callback) => {
    return RestClient.get(
      UrlList.getPatchRawEcgsUrl(ecgTestId),
      params,
      callback
    );
  },
  /* Patch ECGs > Histogram */
  /** HR Histogram 데이터 요청 */
  getHrHistogram: (
    { ecgTestId, ...restParams }: IGetHrHistogramPrams,
    callback
  ) => {
    //
    return RestClient.get(
      UrlList.getHrHistogramUrl(ecgTestId),
      restParams,
      callback
    );
  },
  /** HR Histogram 의 특정 Bin 데이터 요청 */
  getHrHistogramBinDetail: (
    { ecgTestId, ...restParams }: IGetHrHistogramBinDetailPrams,
    callback
  ) => {
    //
    return RestClient.get(
      UrlList.getHrHistogramBinDetailUrl(ecgTestId),
      restParams,
      callback
    );
  },
  /** R-R Histogram 데이터 요청 */
  getRrHistogram: (
    { ecgTestId, ...restParams }: IGetRrHistogramPrams,
    callback
  ) => {
    //
    return RestClient.get(
      UrlList.getRrHistogramUrl(ecgTestId),
      restParams,
      callback
    );
  },
  /** R-R Histogram 의 특정 Bin 데이터 요청 */
  getRrHistogramBinDetail: (
    { ecgTestId, ...restParams }: IGetRrHistogramBinDetailPrams,
    callback
  ) => {
    //
    return RestClient.get(
      UrlList.getRrHistogramBinDetailUrl(ecgTestId),
      restParams,
      callback
    );
  },
  postAddBeats: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.post(UrlList.getAddBeatsUrl(ecgTestId), params, callback);
  },
  patchUpdateBeatsByBetweenIndex: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getUpdateBeatsByBetweenIndexUrl(ecgTestId),
      params,
      callback
    );
  },
  patchUpdateBeatsByIndexes: (ecgTestId, body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getUpdateBeatsByIndexesUrl(ecgTestId),
      params,
      callback
    );
  },
  deleteRemoveBeats: (ecgTestId, body, callback) => {
    const params = {
      data: { ...body },
    };

    return RestClient.delete(
      UrlList.getRemoveBeatsUrl(ecgTestId),
      params,
      callback
    );
  },
  // postBeats: (ecgTestId, suffix, body, callback) => {
  //   const params = {
  //     ...body,
  //   };

  //   return RestClient.post(
  //     UrlList.getPatchEcgsIdBeatsUrl(ecgTestId, suffix),
  //     params,
  //     callback
  //   );
  // },
  // patchBeats: (ecgTestId, suffix, body, callback) => {
  //   const params = {
  //     ...body,
  //   };

  //   return RestClient.patch(
  //     UrlList.getPatchEcgsIdBeatsUrl(ecgTestId, suffix),
  //     params,
  //     callback
  //   );
  // },
  // deleteBeats: (ecgTestId, suffix, body, callback) => {
  //   const params = {
  //     data: { ...body },
  //   };

  //   return RestClient.delete(
  //     UrlList.getPatchEcgsIdBeatsUrl(ecgTestId, suffix),
  //     params,
  //     callback
  //   );
  // },
  patchLimit: ({ ecgTestId, ...restParams }, callback) => {
    const params = {
      ...restParams,
    };
    return RestClient.patch(UrlList.getLimitUrl(ecgTestId), params, callback);
  },

  // HR Review Min / Max Revert
  patchRevert: ({ ecgTestId, ...restParams }, callback) => {
    const params = {
      ...restParams,
    };
    return RestClient.patch(UrlList.getRevertUrl(ecgTestId), params, callback);
  },

  // bulkConfirm: (tid, payload, callback) => {
  //   return RestClient.post(UrlList.bulkConfirmUrl(tid), payload, callback);
  // },

  // Patient Events
  getPatientTriggerEventList: (params, callback) => {
    return RestClient.get(UrlList.getPatientEventsUrl(), params, callback);
  },
  patchPatientTriggerEvent: ({ pteId, ...restParams }, callback) => {
    const params = { ...restParams };
    return RestClient.patch(
      UrlList.getPatientEventIdUrl(pteId),
      params,
      callback
    );
  },

  // Report-Events
  postReportEvents: (params, callback) => {
    return RestClient.post(UrlList.getReportEventsUrl(), params, callback);
  },
  getReportEvents: (params, callback) => {
    return RestClient.get(UrlList.getReportEventsUrl(), params, callback);
  },
  updateReportEvents: ({ reportEventId, ...params }, callback) => {
    return RestClient.patch(
      UrlList.getReportEventsIdUrl(reportEventId),
      params,
      callback
    );
  },
  deleteReportEvents: ({ reportEventId, ...params }, callback) => {
    return RestClient.delete(
      UrlList.getReportEventsIdUrl(reportEventId),
      callback
    );
  },
  getReportsStatistics: (params, callback) => {
    return RestClient.get(UrlList.getReportsStatistics(params), callback);
  },
  patchBeatPostprocess: ({ ecgTestId, ...params }, callback) => {
    return RestClient.patch(
      UrlList.getBeatPostprocessUrl(ecgTestId),
      params,
      callback
    );
  },
  // Hospital Statistics
  getAnalyticsStatistic: (prams, callback?: Function) => {
    const { hospitalId, ...restPrams } = prams;
    return RestClient.get(
      UrlList.getAnalyticsStatisticUrl(hospitalId),
      restPrams,
      callback
    );
  },
  getPatchStatistics: (params, callback?: Function) => {
    return RestClient.get(UrlList._getPatchStatisticsUrl(), params, callback);
  },
  getEcgTestsMedicalFeeExcelDownloadUrl: (
    params: { [x: string]: any },
    progressCallback?: (x: any) => void,
    callback?: (x: any) => void
  ) => {
    return RestClient.getFile(
      UrlList.getEcgTestsMedicalFeeExcelDownloadUrl(),
      params,
      progressCallback,
      callback
    );
  },
  getEcgTestsPaymentExcelDownloadUrl: (
    params: { [x: string]: any },
    progressCallback?: (x: any) => void,
    callback?: (x: any) => void
  ) => {
    return RestClient.getFile(
      UrlList.getEcgTestsPaymentExcelDownloadUrl(),
      params,
      progressCallback,
      callback
    );
  },

  /** ----------------------------------------------------------------- */

  /**
   * Authentication
   **/
  getPublicKey: (callback) => {
    return RestClient.post(UrlList.getPublicKeyUrl(), callback);
  },
  checkEmail: (email, callback) => {
    const params = { email };
    return RestClient.post(UrlList.getEmailCheckUrl(), params, callback);
  },

  login: (email, password, passwordToken, callback) => {
    const params = {
      clientName: process.env.REACT_APP_CLIENT_NAME,
      email,
      password,
      passwordToken,
    };
    return RestClient.post(UrlList.getLoginUrl(), params, callback);
  },

  // logout: (refreshToken, callback) => {
  //   const params = { refreshToken };
  //   return RestClient.delete(UrlList.getLogoutUrl(), params, callback);
  // },

  refreshAccessToken: (refreshToken, callback) => {
    const params = {
      clientName: process.env.REACT_APP_CLIENT_NAME,
      refreshToken,
    };
    return RestClient.post(UrlList.getTokenRefreshUrl(), params, callback);
  },

  readMyInformation: (callback) => {
    const params = {};
    return RestClient.get(UrlList.getReadMyInformationURL(), params, callback);
  },

  /**
   * Password
   **/
  resetPassword: (newPassword, callback) => {
    const params = { newPassword: newPassword };
    return RestClient.post(UrlList.getPasswordResetUrl(), params, callback);
  },

  resetPasswordByEmailWithToken: (email, redirectPath, callback) => {
    const params = {
      email: email,
      redirectPath: redirectPath,
      changeType: 'new-account',
    };
    return RestClient.post(
      UrlList.getPasswordResetEmailWithTokenUrl(),
      params,
      callback
    );
  },

  resetPasswordByEmail: (email, redirectPath, changeType, callback) => {
    const params = {
      email: email,
      redirectPath: redirectPath,
      changeType: changeType,
    };
    return RestClient.post(
      UrlList.getPasswordResetEmailUrl(),
      params,
      callback
    );
  },

  completeResetPasswordByEmail: (password, token, uidb64, callback) => {
    const params = { password: password, token: token, uidb64: uidb64 };
    return RestClient.post(
      UrlList.getPasswordResetEmailCompleteUrl(),
      params,
      callback
    );
  },

  resetPasswordNextTime: (callback) => {
    const params = {};
    return RestClient.post(
      UrlList.getPasswordResetNextTimeUrl(),
      params,
      callback
    );
  },

  resetPasswordWithOld: (oldPassword, newPassword, callback) => {
    const params = { oldPassword: oldPassword, newPassword: newPassword };
    return RestClient.post(
      UrlList.getPasswordResetWithOldPasswordUrl(),
      params,
      callback
    );
  },

  /**
   * User Consent
   **/
  readUserConsent: (callback) => {
    const params = {};
    return RestClient.get(UrlList.getUserConsentUrl(), params, callback);
  },

  updateUserConsent: (isConfirmed, callback) => {
    const params = { isConfirmed: isConfirmed };
    return RestClient.post(UrlList.getUserConsentUrl(), params, callback);
  },

  /**
   * ECG Tests
   **/
  createEcgTest: function ({
    patientName,
    patientNumber,
    patientSex,
    patientPhoneNumber,
    patientBirth,
    prescriptionDuration,
    referredBy,
    confirmedBy,
    note,
    pacemaker,
    callback,
  }: ICreateEcgTestApiParams) {
    const params = {
      patientName,
      patientNumber,
      patientSex,
      patientPhoneNumber,
      patientBirth,
      prescriptionDuration,
      referredBy,
      confirmedBy,
      note,
      pacemaker,
    };
    return RestClient.post(UrlList.getEcgTestsUrl(), params, callback);
  },

  readEcgTests: function ({ params, callback }) {
    return RestClient.get(UrlList.getEcgTestsUrl(), params, callback);
  },

  readEcgTest: function ({ ecgTestId, callback = null }) {
    const params = {
      version: 'v2',
      ecgTestId,
    };

    return RestClient.get(
      UrlList.getEcgTestsIdUrl(ecgTestId),
      params,
      callback
    );
  },

  patchEcgTest: function ({ ecgTestId, body, callback }: IPatchEcgTestParams) {
    const params = {
      ...body,
    };
    return RestClient.patch(
      UrlList.getEcgTestsIdUrl(ecgTestId),
      params,
      callback
    );
  },

  patchRevertStatus: function ({
    ecgTestId,
    callback,
  }: IPatchRevertStatusParams) {
    const params = {};
    return RestClient.patch(
      UrlList.getEcgTestsIdRevertUrl(ecgTestId),
      params,
      callback
    );
  },

  deleteEcgTest: function ({ ecgTestId, callback }: IDeleteEcgTestParams) {
    const params = {};
    return RestClient.delete(
      UrlList.getEcgTestsIdUrl(ecgTestId),
      params,
      callback
    );
  },

  ecgTestReturnDevice: function ({
    ecgTestId,
    callback,
  }: IEcgTestReturnDeviceParams) {
    const params = {};
    return RestClient.post(
      UrlList.getEcgTestsIdReturnDeviceUrl(ecgTestId),
      params,
      callback
    );
  },

  ecgTestConfirmReview: function ({ ecgTestId }: IEcgTestConfirmReview) {
    return RestClient.post(UrlList.getEcgTestsIdConfirmReviewUrl(ecgTestId));
  },

  ecgTestEditDoneReview: function ({ ecgTestId }: IEcgTestEditDoneReview) {
    return RestClient.post(UrlList.getEcgTestsIdEditDoneReviewUrl(ecgTestId));
  },

  validateUpload: function (fileName, serialNumber, startTimestamp, callback) {
    const params = {
      fileName: fileName,
      serialNumber: serialNumber,
      originStartTimestamp: startTimestamp,
    };
    return RestClient.post(
      UrlList.getEcgTestsValidateUploadUrl(),
      params,
      callback
    );
  },

  // Deprecated
  // uploadEcgFile: function (
  //   ecgFile,
  //   serialNumber,
  //   startTimestamp,
  //   progressCallback,
  //   callback
  // ) {
  //   const params = {
  //     originFile: ecgFile,
  //     serialNumber: serialNumber,
  //     originStartTimestamp: startTimestamp,
  //   };
  //   return RestClient.uploadFile(
  //     UrlList.getEcgTestsUploadEcgUrl(),
  //     params,
  //     progressCallback,
  //     callback
  //   );
  // },

  // uploadEcgFileToS3: function (
  //   ecgFile,
  //   presignedS3Url,
  //   progressCallback,
  //   cancelFileUpload,
  //   callback
  // ) {
  //   return RestClient.uploadFileToS3(
  //     presignedS3Url,
  //     ecgFile,
  //     progressCallback,
  //     cancelFileUpload,
  //     callback
  //   );
  // },

  completeUpload: function (ecgTestId, normalizedFileName, callback) {
    const params = {
      normalizedFileName: normalizedFileName,
    };
    return RestClient.post(
      UrlList.getEcgTestsIdCompleteUploadUrl(ecgTestId),
      params,
      callback
    );
  },

  readExplorerData: function (
    ecgTestId,
    atTime,
    secStep,
    backward,
    forward,
    callback?
  ) {
    const params = {
      // ...(atTime && {
      //   atTime: atTime,
      //   secStep: secStep,
      //   backward: backward,
      //   forward: forward,
      // }),
      // TEMPORARILY DISABLED
      // secStep: secStep,
    };
    return RestClient.get(
      UrlList.getPatchRawEcgsUrl(ecgTestId),
      params,
      callback
    );
  },

  getEventCounts: function ({ tid, ...restParams }, callback) {
    const params = {
      clientName: process.env.REACT_APP_CLIENT_NAME,
      ...restParams,
    };
    return RestClient.get(UrlList.getGetEventCountsUrl(tid), params, callback);
  },

  /**
   * Patient Events
   */
  getPatientEvents: function (sagaParams, callback) {
    const params = {
      ...sagaParams,
    };
    return RestClient.get(UrlList.getPatientEventsUrl(), params, callback);
  },

  /**
   * Events
   **/
  readEvents: function (sagaParams, callback) {
    const params = {
      ...sagaParams,
    };
    return RestClient.get(UrlList.getEventsUrl(), params, callback);
  },

  readEvent: function (eventId, callback) {
    const params = {};
    return RestClient.get(UrlList.getEventsIdUrl(eventId), params, callback);
  },

  updateEvent: function (eventId, params, callback) {
    // const params = {};
    return RestClient.patch(UrlList.getEventsIdUrl(eventId), params, callback);
  },

  confirmEvent: function (eventId, params, callback) {
    // const params = {};
    return RestClient.post(
      UrlList.getEventsIdConfirmUrl(eventId),
      params,
      callback
    );
  },

  /**
   * Medical Staffs
   **/
  createMedicalStaff: function (
    firstName,
    email,
    staffType,
    accessLevel,
    isHospitalAdmin,
    callback?
  ) {
    const params = {
      firstName,
      email,
      staffType,
      accessLevel,
      isHospitalAdmin,
    };
    return RestClient.post(UrlList.getMedicalStaffsUrl(), params, callback);
  },
  updateMedicalStaff: function (username, staffType, accessLevel, callback?) {
    const params = {
      staffType,
      accessLevel,
    };
    return RestClient.patch(
      UrlList.getMedicalStaffsUserNameUrl(username),
      params,
      callback
    );
  },
  deleteMedicalStaff: function (username, callback) {
    const params = {};
    return RestClient.delete(
      UrlList.getMedicalStaffsUserNameUrl(username),
      params,
      callback
    );
  },

  readMedicalStaffs: function (
    hid,
    page,
    pageSize,
    queryKeyword,
    staffType,
    ordering,
    callback
  ) {
    const params = {
      hid,
      page,
      pageSize,
      q: queryKeyword,
      staffType,
      ordering,
    };

    return RestClient.get(UrlList.getMedicalStaffsUrl(), params, callback);
  },

  /**
   * Events
   */
  getEventsList: (sagaParams, callback) => {
    const params = {
      ...sagaParams,
    };
    return RestClient.get(UrlList.getEventsUrl(), params, callback);
  },

  // Reports
  requestPrintReport: (sagaParams, callback) => {
    const params = {
      ...sagaParams,
      reportClient: process.env.REACT_APP_CLIENT_NAME,
    };
    const config = {
      timeout: 2 * 60 * 1000, // timeout 제한 시간 예외처리 2 분
    };

    return RestClient.post(UrlList.getReportsUrl(), params, callback, config);
  },
  // readReportDetail: (sagaParams, callback) => {
  //   const params = {
  //     ...sagaParams,
  //   };
  //   return RestClient.get(
  //     UrlList.getReportsDetailURL(sagaParams.reportId),
  //     params,
  //     callback
  //   );
  // },
  readReportSelected: (sagaParams, callback) => {
    const params = {
      ...sagaParams,
    };
    return RestClient.get(
      UrlList.getReportSelectedEventUrl(sagaParams.reportId),
      params,
      callback
    );
  },
  calculateReport: function (reportId, callback) {
    const params = {};
    return RestClient.post(
      UrlList.getReportIdCalculateUrl(reportId),
      params,
      callback
    );
  },
  getReportIsValid: function (reportId, callback) {
    const params = {};
    return RestClient.get(
      UrlList.getReportIdIsValidUrl(reportId),
      params,
      callback
    );
  },

  // Report Server
  // generateReport: function (callback) {
  //   const params = {};
  //   return RestClient.get(UrlList.getReportGenerateUrl(), params, callback);
  // },

  /**
   *
   **/
  // readProfile: (callback) => {
  //     const params = {};
  //     return RestClient.get(UrlList.getUrl(), params, callback);
  // },

  // readDeviceDetail: (deviceId, callback) => {
  //     const params = {};
  //     return RestClient.get(UrlList.getUrl(), params, callback);
  // },

  // updateDeviceConfirm: (deviceId, callback) => {
  //     const params = {};
  //     return RestClient.post(UrlList.getUrl(), params, callback);
  // },

  // updateUserProfile: (userId, callback) => {
  //     const params = {};
  //     return RestClient.patch(UrlList.getUrl(), params, callback);
  // },

  // readDeviceReport: (deviceId, perform, callback) => {
  //     const params = {};
  //     return RestClient.get(UrlList.getUrl(), params, callback);
  // },

  // readTotalData: (callback) => {
  //     const params = {};
  //     return RestClient.get(UrlList.getUrl(), params, callback);
  // },

  /**
   * Markings
   **/
  // createDeviceMarkings: (deviceId, callback) => {
  //     const params = {};
  //     return RestClient.post(UrlList.getUrl(), params, callback);
  // },

  // readDeviceMarkings: (deviceId, callback) => {
  //     const params = {};
  //     return RestClient.get(UrlList.getUrl(), params, callback);
  // },

  // updateDeviceMarkings: (deviceId, callback) => {
  //     const params = {};
  //     return RestClient.patch(UrlList.getUrl(), params, callback);
  // },

  // deleteDeviceMarkings: (deviceId, callback) => {
  //     const params = {};
  //     return RestClient.get(UrlList.getUrl(), params, callback);
  // },

  // readDeviceEcgSection: (deviceId, callback) => {
  //     const params = {};
  //     return RestClient.get(UrlList.getUrl(), params, callback);
  // },

  /**
   * Common
   **/
  // readStaffPatients: (callback) => {
  //   const params = {};
  //   return RestClient.get(UrlList.getStaffPatientsUrl(), params, callback);
  // },

  readDeviceSummary: (deviceId, callback) => {
    const params = {};
    return RestClient.get(
      UrlList.getDeviceSummaryUrl(deviceId),
      params,
      callback
    );
  },

  readDeviceEcgTotal: (deviceId, atTime, callback) => {
    const params = {
      atTime: atTime,
    };
    return RestClient.get(
      UrlList.getDeviceEcgTotalUrl(deviceId),
      params,
      callback
    );
  },

  /**
   * Test Notification
   */
  getTestNotification: (params, callback) => {
    return RestClient.get(UrlList.getTestNotificationUrl(), params, callback);
  },
  patchReadByTid: (body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(UrlList.getPatchReadByTidUrl(), params, callback);
  },
  patchReadByDateRange: (body, callback) => {
    const params = {
      ...body,
    };

    return RestClient.patch(
      UrlList.getPatchReadByDateRangeUrl(),
      params,
      callback
    );
  },

  /**
   *  Parcel
   */
  getParcelById: (parcelId: string, callback: Function) => {
    return RestClient.get(UrlList.getParcelByIdUrl(parcelId), {}, callback);
  },
  postParcel: (body, callback?) => {
    return RestClient.post(UrlList.getParcelUrl(), body, callback);
  },
  patchParcelById: (parcelId: string, body, callback?) => {
    return RestClient.patch(UrlList.getParcelByIdUrl(parcelId), body, callback);
  },
  /**
   *  EMR / Patch Report Status
   */
  patchReportUploadStatusById: function ({
    ecgTestId,
    body,
  }: {
    ecgTestId: string;
    body;
  }) {
    return RestClient.patch(
      UrlList.getPatchReportUploadStatusUrl(ecgTestId),
      body
    );
  },

  /**
   *  MFD Download Path
   */
  getMfdNDataloaderFile: (callback) => {
    const params = {};
    return RestClient.get(UrlList.getMfdVersionUrl(), params, callback);
  },
};

export default ApiManager;
