import styled from 'styled-components';

function Precaution({ isEditable, isCreatedToday }) {
  return (
    <PrecautionWrapper>
      <PrecautionList>
        {isEditable && <EditablePrecaution />}
        {!isEditable && !isCreatedToday && <ExpiredPrecaution />}
        <ContactPrecaution />
      </PrecautionList>
    </PrecautionWrapper>
  );
}

function EditablePrecaution() {
  return (
    <PrecautionText>
      정보 수정/취소는 <BoldText>택배 예약 당일</BoldText>만 가능합니다.
    </PrecautionText>
  );
}

function ExpiredPrecaution() {
  return <PrecautionText>수정 및 취소 기한이 만료되었습니다.</PrecautionText>;
}

function ContactPrecaution() {
  return (
    <PrecautionText>
      불편사항은 고객센터로 문의해주세요.(고객센터: 02-2088-8393)
    </PrecautionText>
  );
}

const PrecautionWrapper = styled.div`
  display: flex;
  padding: 12px 10px;
  margin: 4px 0px;
  gap: 10px;
  background-color: ${({ theme }) => theme.color.COOL_GRAY_20};
`;

const PrecautionList = styled.ul`
  padding-left: 15px;
  margin: 0;
`;

const PrecautionText = styled.li`
  font-size: 11px;
  line-height: 150%; /* 16.5px */
  color: ${({ theme }) => theme.color.COOL_GRAY_80};
`;

const BoldText = styled.span`
  font-weight: 700;
`;

export default Precaution;
