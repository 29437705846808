import { ReactNode, CSSProperties } from 'react';

import { ITestNotification } from 'redux/container/TestNotificationHistoryPageContainer';

export function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function highlightChar(
  text: string,
  charToHighlight: string,
  style: CSSProperties
): ReactNode[] {
  return text
    .split(charToHighlight)
    .reduce(
      (acc: ReactNode[], segment: string, index: number, array: string[]) => {
        if (index === array.length - 1) {
          return [...acc, segment];
        }
        return [
          ...acc,
          segment,
          <span key={index} style={style}>
            {charToHighlight}
          </span>,
        ];
      },
      []
    );
}

interface IUpdateIsRead {
  noti: ITestNotification;
  tidsSet: Set<string>;
}
export function updateIsRead({
  noti,
  tidsSet,
}: IUpdateIsRead): ITestNotification {
  if (tidsSet.has(noti.tid))
    return { ...noti, isHospitalUserReadNotification: true };
  return noti;
}

export function getThirtyDaysAgoTimestamp(timestamp: number): number {
  // 30일에 해당하는 밀리초 계산 (1일 = 24시간 * 60분 * 60초 * 1000밀리초)
  const thirtyDaysInMilliseconds = 30 * (24 * 60 * 60 * 1000);

  // 현재 시간에서 30일 전의 밀리초를 빼서 반환
  const thirtyDaysAgoTimestamp = timestamp - thirtyDaysInMilliseconds;

  return thirtyDaysAgoTimestamp;
}
