import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import axios, { CancelToken, isCancel } from 'axios';
import ApiManager from 'network/ApiManager/ApiManager';
import StatusCode from 'network/StatusCode';
import FileUploadProgress from 'component/ui/progress/FileUploadProgress';
import Button from 'component/ui/button/Button';
import FileUtil from 'util/FileUtil';
import { ReactComponent as FileIcon } from 'static/icon/icon-file.svg';
import { ReactComponent as DeleteIcon } from 'static/icon/icon-delete.svg';
import { ReactComponent as RetryIcon } from 'static/icon/icon-retry.svg';

const Wrapper = styled.div`
  padding: 12px 12px 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(props) => props.theme.color.MEDIUM_LIGHT};
  box-sizing: border-box;
  border-radius: 6px;
`;

const InfoContainer = styled.div`
  flex: 1;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > * {
    :not(:last-child) {
      margin-bottom: 4px;
    }
  }
`;

const FileNameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FileNameText = styled.div`
  margin-right: 16px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StatusText = styled.div`
  font-size: 10px;
  color: ${(props) =>
    props.isError ? props.theme.color.RED : props.theme.color.BLACK};
`;

const PercentageText = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.color.BLACK};
`;

function UploadingFileListItem(props) {
  const intl = useIntl();
  const theme = useTheme();

  const getStatusTextByPercentage = useCallback((percentage) => {
    if (percentage === 0) {
      return intl.formatMessage({
        id: '03-UploadingFileListItem-StatusText-children-01',
        description: '업로드 대기',
        defaultMessage: '업로드 대기',
      });
    } else if (percentage > 0 && percentage < 100) {
      return intl.formatMessage({
        id: '03-UploadingFileListItem-StatusText-children-02',
        description: '업로드 중...',
        defaultMessage: '업로드 중...',
      });
    } else if (percentage >= 100) {
      return intl.formatMessage({
        id: '03-UploadingFileListItem-StatusText-children-03',
        description: '업로드 완료',
        defaultMessage: '업로드 완료',
      });
    } else if (percentage === -2) {
      return intl.formatMessage({
        id: '03-UploadingFileListItem-StatusText-children-06',
        description: '업로드 에러',
        defaultMessage: '업로드 실패 - 일치하는 검사 정보가 없습니다.',
      });
    } else if (percentage === -3) {
      return intl.formatMessage({
        id: '03-UploadingFileListItem-StatusText-children-05',
        description: '업로드 에러',
        defaultMessage: '업로드 실패 - 잘못된 파일 형식입니다.',
      });
    } else if (percentage === -4) {
      return intl.formatMessage({
        id: '03-UploadingFileListItem-StatusText-children-07',
        description: '업로드 에러',
        defaultMessage: '업로드 실패 - 이미 업로드된 파일입니다.',
      });
    } else {
      return intl.formatMessage({
        id: '03-UploadingFileListItem-StatusText-children-04',
        description: '업로드 에러',
        defaultMessage: '업로드 실패 - 네트워크 연결 상태를 확인하세요.',
      });
    }
  }, []);

  const [percentage, setPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const unmounted = useRef(false);

  async function uploadFileToServer() {
    const ecgFile = props.file;
    setPercentage(0);
    try {
      const [serialNumber, startTimestamp] = await FileUtil.extractMetadata(
        ecgFile
      );
      if (unmounted.current) return;
      await ApiManager.validateUpload(
        ecgFile.name,
        serialNumber,
        startTimestamp,
        validateUploadCallback
      );
    } catch (error) {
      const { status, message } = error;
      switch (status) {
        case StatusCode.BAD_REQUEST:
          // FIXME(준호): 이미 업로드 된 케이스 처리 추가
          if (
            'nonFieldErrors' in message &&
            message['nonFieldErrors'].includes(
              'ECGTest matching query does not exist'
            )
          )
            setPercentage(-2);
          else if ('serialNumber' in message) setPercentage(-3);
          else if ('nonFieldErrors' in message) setPercentage(-4);
          else setPercentage(-1);
          break;
        default:
          setPercentage(-1);
          break;
      }
    }
  }

  useEffect(() => {
    uploadFileToServer();

    return () => {
      unmounted.current = true;
    };
  }, []);

  const validateUploadCallback = async (status, response) => {
    // FIXME(준호): 파일 검증 결과에 기반한 메시징 처리 필요!
    switch (status) {
      case StatusCode.OK:
        const { tid, ecgTestStatus, normalizedFileName, fileUploadPath } =
          response.result;

        // Upload ECG file to pre-signed S3 URL
        // const uploadResponse = await ApiManager.uploadEcgFileToS3(
        //   props.file,
        //   fileUploadPath,
        //   progressCallback,
        //   cancelFileUpload,
        //   (status, response) => {
        //   }
        // );
        // TODO(준호): axios cancelToken을 사용한 파일 업로드 중단 필요!!
        if (unmounted.current) break;
        const arrayBuffer = await FileUtil.readFileAsync(props.file);
        const config = {
          timeout: 10 * 60 * 1000, // Set timeout to 10 minutes
          headers: {
            'Content-Type': 'application/octet-stream',
            // To prevent attaching custom auth token to header
            'Disable-Custom-Auth': true,
          },
          onUploadProgress: function (progressEvent) {
            if (progressCallback && typeof progressCallback === 'function') {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              progressCallback(percentCompleted);
            }
          },
          cancelToken: new CancelToken(function executor(cancel) {
            cancelFileUpload.current = cancel;
          }),
        };
        if (unmounted.current) break;
        axios
          .put(fileUploadPath, arrayBuffer, config)
          .then((res) => {
            console.log(res);
            progressCallback(100);

            const completeUploadResponse = ApiManager.completeUpload(
              tid,
              normalizedFileName
            ).catch((error) => {
              // s3에 파일 업로드 중 문제 발생되어 실패한 경우!!!
              progressCallback(-1);
            });
          })
          .catch((err) => {
            console.error(err);
            if (isCancel(err)) {
              alert(err.message);
            }
            // 업로드 취소?!
            progressCallback(-1);
          });

        break;
      default:
        alert('An error occurred.');
    }
  };

  const progressCallback = (percentCompleted) => {
    setPercentage(percentCompleted);
    if (percentCompleted === 100) {
      props.handleProgressesDone();
    }
  };

  return (
    <Wrapper>
      <FileIcon />

      <InfoContainer>
        <FileNameContainer>
          <FileNameText>{props.file.name}</FileNameText>

          {percentage < 100 && (
            <Button
              outline={false}
              title={intl.formatMessage({
                id: '03-UploadingFileListItem-Button-title-01',
                description: '삭제',
                defaultMessage: '삭제',
              })}
              backgroundColor="transparent"
              hoverBackgroundColor={theme.color.ULTRA_LIGHT}
              textColor={theme.color.MEDIUM_DARK}
              style={{
                width: 35,
                height: 12,
                borderRadius: '10%',
                fontSize: 9,
              }}
              endIcon={<DeleteIcon />}
              onClick={() => {
                if (cancelFileUpload.current) cancelFileUpload.current('ss');
                props.onClickDelete();
              }}
            />
          )}
          {percentage === -1 && (
            <Button
              outline={false}
              title={intl.formatMessage({
                id: '99-UploadingFileListItem-Button-title-02',
                description: '재시도',
                defaultMessage: '재시도',
              })}
              backgroundColor="transparent"
              hoverBackgroundColor={theme.color.ULTRA_LIGHT}
              textColor={theme.color.PRIMARY_BLUE}
              style={{ padding: '4px 8px', fontSize: 10, marginLeft: 8 }}
              endIcon={<RetryIcon />}
              onClick={() => {
                uploadFileToServer();
              }}
            />
          )}
        </FileNameContainer>

        <FileUploadProgress percentage={percentage} />

        <StatusContainer>
          <StatusText isError={percentage < 0}>
            {getStatusTextByPercentage(percentage)}
          </StatusText>
          {percentage > 0 && (
            <PercentageText>{`${percentage}%`}</PercentageText>
          )}
        </StatusContainer>
      </InfoContainer>
    </Wrapper>
  );
}

export default UploadingFileListItem;
