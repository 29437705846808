/**
 * MFD(memo for desktop)에서 WebView에게 전달할 Message를 정의한 파일입니다.
 * WebViewMessageConst.js
 */
const WebViewMessageConst = {
  LOGOUT: () => {
    return {
      code: 1000,
      message: 'Logout',
    };
  },
  LOGOUT_MULTIPLE_LOGIN: () => {
    return {
      code: 1001,
      message: 'Logout due to multiple login',
    };
  },
  LOGOUT_SESSION_DURATION_TIMEOUT: () => {
    return {
      code: 1002,
      message: 'Logout due to session duration timeout',
    };
  },
  REPORT_CLICK: (tid) => {
    return {
      code: 2000,
      message: 'Report Button Clicked',
      data: {
        tid,
      },
    };
  },
};

function sendMessage(message) {
  // DesktopApp의 WebView에게 Message 전달 w/ 이강욱님
  window.chrome.webview?.postMessage(JSON.stringify(message));
}

export { WebViewMessageConst, sendMessage };
