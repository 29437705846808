import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Const from 'constant/Const';
import LocalStorageKey from 'constant/LocalStorageKey';

import TrickUtil from 'util/TrickUtil';
import { debounce, overFlowBody } from 'util/Utility';
import { gaInitScript } from 'util/TrackerUtility';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { showDialog } from 'redux/duck/dialogDuck';
import { authLogoutRequested } from 'redux/duck/authDuck';
import App from 'component/base/App';

const { SESSION_DURATION_TIME } = Const;

function AppContainer() {
  const dispatch = useDispatch();

  // selector
  const someDialogOpenedState = useShallowEqualSelector(
    (state) => state.dialogReducer.isSomethingOpen
  );
  const authState = useShallowEqualSelector((state) => state.authReducer);

  // dispatch
  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };
  const handleLogout = () => {
    dispatch(authLogoutRequested());
  };

  const favicon = useMemo(
    () => document.querySelectorAll('link[rel=icon]')[0],
    []
  );

  // useEffect
  useEffect(() => {
    if (process.env.REACT_APP_CUSTOM_ENV === 'prod') {
      favicon.href = '/favicon_production/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'qa') {
      favicon.href = '/favicon_staging/favicon.ico';
    } else if (process.env.REACT_APP_CUSTOM_ENV === 'dev') {
      favicon.href = '/favicon_dev/favicon.ico';
    } else {
      favicon.href = '/favicon_aux/favicon.ico';
    }
  }, []);

  useEffect(() => {
    window.bypass = {
      on: function () {
        localStorage.setItem(LocalStorageKey.BYPASS, 'true');
        window.location.reload();
      },
      off: function () {
        localStorage.removeItem(LocalStorageKey.BYPASS);
        window.location.reload();
      },
    };
  }, []);

  let timeoutId = null;
  let flag = false;
  const handleEvent = (e) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (
      authState.isLoggedIn &&
      !flag &&
      !window.location.pathname.includes('login')
    ) {
      const duration = SESSION_DURATION_TIME * 60 * 60 * 1000;

      timeoutId = setTimeout(() => {
        flag = true;
        handleLogout();
        handleShowDialog(
          'AlertDialog',
          {
            message: `${SESSION_DURATION_TIME}시간 동안 사용하지 않아 자동 로그아웃 되었습니다.`,
          },
          () => {
            flag = false;
          }
        );
      }, duration);
    }
  };

  useEffect(() => {
    if (authState?.data?.device === Const.DEVICE.MFD) return;

    const debouncedHandlerEvent = debounce(handleEvent, 500);
    const handleEventList = ['scroll', 'click', 'mousemove', 'keydown'];

    for (const e of handleEventList) {
      document.addEventListener(e, debouncedHandlerEvent);
    }
    return () => {
      for (const e of handleEventList) {
        document.removeEventListener(e, debouncedHandlerEvent);
      }
    };
  }, [authState.isLoggedIn]);

  useEffect(() => {
    TrickUtil.setExpireAlert(() => handleShowDialog('AlertExpireDialog'));
    TrickUtil.setNetworkAlert(
      ({ errorData, shouldLogout, showTitle, showErrorCode, callback }) =>
        handleShowDialog(
          'AlertNetworkDialog',
          { errorData, shouldLogout, showTitle, showErrorCode },
          callback
        )
    );
  }, []);

  useEffect(() => {
    someDialogOpenedState ? overFlowBody.hidden() : overFlowBody.auto();
  }, [someDialogOpenedState]);

  // Google Analytics 실행하는 로직
  useEffect(() => {
    if (!authState.isLoggedIn) return;

    const ALLOW_ENV_LIST = ['prod', 'qa'];
    if (!ALLOW_ENV_LIST.includes(process.env.REACT_APP_CUSTOM_ENV)) return;

    try {
      gaInitScript({ userName: authState?.user?.username });
    } catch (error) {
      console.error(error);
    }
  }, [authState.isLoggedIn]);

  return <App authState={authState} />;
}

export default AppContainer;
