import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2px 16px;
  display: flex;
  border-radius: 4px;
  background-color: ${(props) => props.theme.color.WHITE};
  ${(props) =>
    !props.disabled
      ? `
        color: ${props.theme.color.BLACK};
        :hover {
            background-color: ${props.theme.color.LIGHT};
            cursor: pointer;
        }
        transition-property: color, background-color;
        transition-duration: 0.1s;
        transition-timing-function: ease-in-out;
    `
      : `
        color: ${props.theme.color.MEDIUM};
    `};

  ${(props) => {
    if (props.isActive) {
      return `background-color: ${props.theme.color.LIGHT}`;
    }
  }}
`;

const TitleText = styled.div`
  font-size: 11px;
  color: ${(props) =>
    props.textColor ? props.textColor : props.theme.color.BLACK};
`;

function DropdownItem(props) {
  const { style, disabled, textColor, title, onClick, currentIndex, index } =
    props;

  return (
    <Wrapper
      style={style}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      isActive={currentIndex === index}>
      <TitleText textColor={textColor}>{title}</TitleText>
    </Wrapper>
  );
}

export default DropdownItem;
