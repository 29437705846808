interface AppendHeadScriptParams {
  isAsync?: boolean;
  scriptSrc?: string;
  scriptText?: string;
  scriptId: string;
}

interface GaInitScriptParams {
  userName: string;
}

export const appendHeadScript = ({
  isAsync = false,
  scriptSrc,
  scriptText = '',
  scriptId,
}: AppendHeadScriptParams): boolean => {
  try {
    const existentScript = document.getElementById(
      scriptId
    ) as HTMLScriptElement;

    const script = existentScript || document.createElement('script');

    if (scriptSrc) script.src = scriptSrc;
    script.async = isAsync;
    script.id = scriptId;
    script.innerText = scriptText;
    script.crossOrigin = 'anonymous';

    existentScript || document.head.appendChild(script);

    return true;
  } catch {
    return false;
  }
};

//
// Google Analytics
//
export function gaInitScript({ userName }: GaInitScriptParams): boolean {
  const hasWindow = typeof window !== 'undefined';
  const GA_ID = process.env.REACT_APP_GA_ID;

  if (!hasWindow) throw Error('GA depends on window. Window is undefined.');
  if (!GA_ID) throw Error('GA_ID is undefined.');

  const gaAsyncScriptSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
  const gaScriptCode = `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${GA_ID}', {
                          'user_id': '${userName || 'unknown-user'}'
                        });
                      `;

  const isAppendedAsync = appendHeadScript({
    isAsync: true,
    scriptSrc: gaAsyncScriptSrc,
    scriptId: 'ga-init-script-async',
  });
  const isAppended = appendHeadScript({
    scriptText: gaScriptCode,
    scriptId: 'ga-init-script',
  });

  if (isAppendedAsync && isAppended && hasWindow) return true;

  throw Error('GA initialization failed!');
}
