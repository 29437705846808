import { MessageDescriptor, defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

interface PasswordInputWrongDialogProps {
  open: boolean;
  params: {
    passwordIncorrectTimes: number;
    passwordIncorrectLimit: number;
  };
  callback: () => void;
  onClose: () => void;
}

function PasswordInputWrongDialog(props: PasswordInputWrongDialogProps) {
  const intl = useIntl();

  const { open, params, callback, onClose } = props;
  const { passwordIncorrectTimes, passwordIncorrectLimit } = params;

  const { t } = useIntlMsg();
  return (
    <DialogWrapper open={open} width={360}>
      <TitleText>
        {t(INTL_MAP.PASSWORD_INPUT_WRONG_DIALOG_TITLE, {
          passwordIncorrectTimes,
        })}
      </TitleText>

      <MessageContainer>
        <MessageText>
          {t(INTL_MAP.PASSWORD_INPUT_WRONG_DIALOG_DESCRIPTION, {
            passwordIncorrectLimit,
          })}
        </MessageText>
      </MessageContainer>

      <DialogButtonWrapper>
        <TextButton
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-close',
            description: '팝업 Dialog의 닫기 버튼',
            defaultMessage: '닫기',
          })}
          onClick={onClose}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

const TitleText = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageContainer = styled.div`
  margin-bottom: 16px;
  font-size: 12px;
`;

const MessageText = styled.div`
  color: ${(props) => props.theme.color.BLACK};
`;

function useIntlMsg() {
  const intl = useIntl();
  const t = (messageDescriptor: MessageDescriptor, values = {}) =>
    intl.formatMessage(messageDescriptor, values);

  return { t };
}

const INTL_MAP = defineMessages({
  PASSWORD_INPUT_WRONG_DIALOG_TITLE: {
    id: '99-PasswordInputWrongDialog-TitleText',
    description:
      '비밀번호를 {passwordIncorrectTimes}회 잘못 입력하였습니다. / You have entered the wrong password {passwordIncorrectTimes} times.',
    defaultMessage:
      '비밀번호를 {passwordIncorrectTimes}회 잘못 입력하였습니다.',
  },
  PASSWORD_INPUT_WRONG_DIALOG_DESCRIPTION: {
    id: '99-PasswordInputWrongDialog-DescText',
    description:
      '{passwordIncorrectLimit}회 비밀번호 잘못 입력 시 보안을 위해 계정이 잠금처리됩니다. / For security reasons, your account will be temporarily locked after {passwordIncorrectLimit} consecutive login attempts.',
    defaultMessage:
      '{passwordIncorrectLimit}회 비밀번호 잘못 입력 시 보안을 위해 계정이 잠금처리됩니다.',
  },
});

export default PasswordInputWrongDialog;
