import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { HEIGHT_HEADER, HEIGHT_CREDIT_ALERT } from 'constant/StyleConst';
import { selectIsCreditAlertShow } from 'redux/duck/feedback/feedbackDuck';

function useHeaderHeight() {
  const isShow: boolean = useSelector(selectIsCreditAlertShow, shallowEqual);

  const [headerHeight, setHeaderHight] = useState(getHeight(isShow));

  useEffect(() => {
    setHeaderHight(getHeight(isShow));
  }, [isShow]);

  return headerHeight;
}

function getHeight(isShow: boolean) {
  const result = HEIGHT_HEADER + Number(isShow) * HEIGHT_CREDIT_ALERT;

  return result;
}

export default useHeaderHeight;
