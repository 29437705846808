import styled, { useTheme } from 'styled-components';

import { removeNonDigits } from 'util/NumberUtil';

import useManageParcelDialogOnClickButtonHandler from 'component/hook/useManageParcelDialogOnClickButtonHandler';

import { DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextButton from 'component/ui/button/TextButton';

import { PARCEL_STATUS } from 'component/dialog/ManageParcelDialog/ParcelConst';

const {
  RECEIPT_AWAITING,
  PICK_UP_AWAITING,
  UPLOAD_AWAITING,
  DEVICE_RETURN_AWAITING,
  DEVICE_RETURN_COMPLETE,
  CANCELED,
} = PARCEL_STATUS;
const showCancelParcelButtonCondition = new Set(
  [
    RECEIPT_AWAITING,
    PICK_UP_AWAITING,
    UPLOAD_AWAITING,
    DEVICE_RETURN_AWAITING,
    DEVICE_RETURN_COMPLETE,
  ].map((status) => status.value)
);
const showEditButtonCondition = new Set(
  [
    RECEIPT_AWAITING,
    PICK_UP_AWAITING,
    UPLOAD_AWAITING,
    DEVICE_RETURN_AWAITING,
    DEVICE_RETURN_COMPLETE,
    CANCELED,
  ].map((status) => status.value)
);

function DialogButton({
  ecgTest,
  parcel,
  formData,
  isEditable,
  isFormModified,
  onClose,
  onCancel,
}) {
  const theme = useTheme();
  const { onClickConfirmButton, onClickEditButton, onClickCancelParcelButton } =
    useManageParcelDialogOnClickButtonHandler({ ecgTest, parcel, formData });

  const { estimatedEndDatetime } = ecgTest;
  const { status } = parcel;
  const showCancelParcelButton = showCancelParcelButtonCondition.has(status);
  const showEditButton = showEditButtonCondition.has(status);

  function validateForm(formData, estimatedEndDatetime) {
    const { senderName, senderPhoneNumber, address1, address2, pickUpDate } =
      formData;
    const isValidSenderName =
      senderName.length > 0 && !senderName.includes('*');
    const isValidSenderPhoneNumber =
      removeNonDigits(senderPhoneNumber).length === 11;
    const isValidAddress1 = address1.length > 0;
    const isValidAddress2 = address2.length > 0;
    const pickUpDatetime = new Date(pickUpDate).setHours(0, 0, 0, 0);
    const endDatetime = new Date(estimatedEndDatetime).setHours(0, 0, 0, 0);
    const isValidPickUpDate = pickUpDatetime >= endDatetime;

    return (
      isValidSenderName &&
      isValidSenderPhoneNumber &&
      isValidAddress1 &&
      isValidAddress2 &&
      isValidPickUpDate
    );
  }

  if (!isEditable)
    return (
      <DialogButtonWrapper
        style={{ justifyContent: 'space-between', paddingTop: '8px' }}>
        <Spacer />
        <TextButton
          textColor={theme.color.BLUE_70}
          title={'확인'}
          onClick={() => {
            onClose();
          }}
        />
      </DialogButtonWrapper>
    );

  return (
    <DialogButtonWrapper
      style={{ justifyContent: 'space-between', paddingTop: '8px' }}>
      {showCancelParcelButton && (
        <TextButton
          textColor={theme.color.RED_70}
          title={'예약 취소'}
          onClick={() => {
            onClose();
            onClickCancelParcelButton();
            typeof onCancel === 'function' && onCancel();
          }}
        />
      )}
      <Spacer />
      <TextButton
        textColor={theme.color.COOL_GRAY_80}
        title={'취소'}
        onClick={() => {
          onClose();
          typeof onCancel === 'function' && onCancel();
        }}
      />
      {showEditButton && (
        <TextButton
          disabled={
            !isFormModified || !validateForm(formData, estimatedEndDatetime)
          }
          textColor={theme.color.BLUE_70}
          title={'수정'}
          onClick={() => {
            onClickEditButton();
            onClose();
          }}
        />
      )}
      {!showEditButton && (
        <TextButton
          disabled={!validateForm(formData, estimatedEndDatetime)}
          textColor={theme.color.BLUE_70}
          title={'예약'}
          onClick={() => {
            onClickConfirmButton();
            onClose();
            typeof onCancel === 'function' && onCancel();
          }}
        />
      )}
    </DialogButtonWrapper>
  );
}

const Spacer = styled.div`
  // 취소,수정 버튼의 tabIndex를 유지하기 위해, flex를 사용하여 빈 공간을 만듦
  flex: 1;
`;

export default DialogButton;
