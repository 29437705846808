import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import Const from 'constant/Const';

import { type AuthState } from 'type/apiDataType';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { ReactComponent as ShortcutGuideSvg } from 'static/image/shortcut-guide.svg';

const { EVENT_TYPE, KEY_MAP } = Const;
const { QUESTION_MARK, ESCAPE } = KEY_MAP;
interface ShortcutGuideProps {
  children: ReactNode;
}
interface RootState {
  authReducer: AuthState;
}

function ShortcutGuide({ children }: ShortcutGuideProps) {
  const isLoggedIn = useShallowEqualSelector(
    ({ authReducer }: RootState) => authReducer.isLoggedIn
  );

  const [showSvg, setShowSvg] = useState(false);

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      switch (evt.key) {
        case ESCAPE:
          setShowSvg(false);
          break;
        case QUESTION_MARK:
          evt.stopImmediatePropagation();
          evt.target === document.body && setShowSvg(true);
          break;
        default:
          break;
      }
    };

    window.addEventListener(EVENT_TYPE.KEYDOWN as 'keydown', onKeyDown);
    return () => {
      window.removeEventListener(EVENT_TYPE.KEYDOWN as 'keydown', onKeyDown);
    };
  }, []);

  if (!isLoggedIn) return children;

  return (
    <Wrapper>
      {children}
      {showSvg && (
        <OverlayWrapper onClick={() => setShowSvg(false)}>
          <DimBackground />
          <OverlaySvg as={ShortcutGuideSvg} />
        </OverlayWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const OverlayWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000; // 10s strip detail 보다 1 크게
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DimBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;
const OverlaySvg = styled.svg`
  width: auto;
  height: 80%;
  z-index: 11;
`;

export default ShortcutGuide;
