import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import LocalStorageKey from 'constant/LocalStorageKey';
import Const from 'constant/Const';

import { GaUtil } from 'util/GaUtil';
import DateUtil from 'util/DateUtil';

import LocalStorageManager from 'manager/LocalStorageManager';
import { showDialog } from 'redux/duck/dialogDuck';
import { NOTICE_DIALOG_ENV } from 'component/dialog/NoticeDialog';

function useNoticeDialogDisplay() {
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };

  useEffect(() => {
    if (location.pathname !== '/') return;

    const currentDate = DateUtil.getUserLocationTime();
    const currentLocale = intl.locale;
    const noticeContentUrl = getNoticeDialogSvgUrlByLocale(currentLocale);

    if (!noticeContentUrl || !isWithinDateRange(currentDate)) {
      return;
    }

    if (
      LocalStorageManager.getItem(
        LocalStorageKey.DO_NOT_SHOW_NOTICE_POPUP_TIL_THIS_DATE
      ) <= currentDate
    ) {
      handleShowDialog('NoticeDialog', {
        showCloseIcon: true,
        noticeContentUrl,
        handleOnclick: () => {
          if (!process.env.REACT_APP_NOTICE_DIALOG_CLICKED_STATIC_URL) return;

          GaUtil.NOTICE_POPUP_IMAGE_CLICK();
          window.open(process.env.REACT_APP_NOTICE_DIALOG_CLICKED_STATIC_URL);
        },
      });
    }
  }, [location]);
}

// util Func
function isWithinDateRange(currentDate) {
  return (
    currentDate >= new Date(NOTICE_DIALOG_ENV.startDate) &&
    currentDate <= new Date(NOTICE_DIALOG_ENV.endDate)
  );
}
function getNoticeDialogSvgUrlByLocale(locale) {
  return locale === Const.LANGUAGE.KO
    ? NOTICE_DIALOG_ENV.svgKoStaticURL
    : NOTICE_DIALOG_ENV.svgEngStaticURL;
}

export default useNoticeDialogDisplay;
