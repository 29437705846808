import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import ButtonSpinner, { SPINNER_CLASS } from './ButtonSpinner';

const Wrapper = styled.div.attrs({
  tabIndex: 0,
  role: 'button',
})`
  position: relative;

  width: 100px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 6px;

  overflow: hidden;
  cursor: ${(props) =>
    props.disabled || props.pending ? 'default' : 'pointer'};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  line-height: ${(props) => (props.hasIcons ? 100 : 130)}%;
  font-weight: 700;
  text-align: center;

  ${(props) => {
    let color, borderColor, bgColor, hoverBgColor, fontWeight;

    let fontSize = 13;

    switch (props.color) {
      case 'default':
        color = props.theme.color.COOL_GRAY_90;
        borderColor = props.theme.color.COOL_GRAY_50;
        bgColor = props.theme.color.LIGHT;
        hoverBgColor = props.theme.color.LIGHT;
        break;
      case 'error':
        color = props.theme.color.RED;
        bgColor = props.theme.color.ULTRA_LIGHT_RED;
        break;
      case 'secondary':
        color = props.disabled
          ? props.theme.color.COOL_GRAY_50
          : props.theme.color.COOL_GRAY_90;
        bgColor = props.theme.color.WHITE;
        borderColor = props.theme.color.COOL_GRAY_50;
        hoverBgColor = props.theme.color.COOL_GRAY_30;
        fontSize = 12;
        fontWeight = 500;
        break;
      case 'blackAndWhite':
        color = props.theme.color.COOL_GRAY_90;
        bgColor = props.theme.color.WHITE;
        borderColor = props.theme.color.COOL_GRAY_50;
        hoverBgColor = props.theme.color.LIGHT;
        fontSize = 12;
        fontWeight = 500;
        break;
      case 'pressed':
        color = props.disabled
          ? props.theme.color.COOL_GRAY_50
          : props.theme.color.COOL_GRAY_90;
        bgColor = props.disabled
          ? props.theme.color.COOL_GRAY_30
          : props.theme.color.COOL_GRAY_40;
        hoverBgColor = props.theme.color.COOL_GRAY_40;
        borderColor = props.disabled
          ? props.theme.color.COOL_GRAY_50
          : props.theme.color.COOL_GRAY_90;
        fontSize = 12;
        fontWeight = 500;
        break;
      case 'primary':
        color = props.theme.color.BLUE_70;
        bgColor = props.theme.color.BLUE_30;
        borderColor = props.theme.color.BLUE_70;
        fontSize = 12;
        fontWeight = 500;
        break;
      default:
        if (props.outline) {
          color = props.disabled
            ? props.theme.color.BLUE_50
            : props.theme.color.BLUE_70;
          borderColor = props.disabled
            ? props.theme.color.BLUE_50
            : props.theme.color.BLUE_70;
          bgColor = props.theme.color.WHITE;
          hoverBgColor = props.disabled
            ? props.theme.color.WHITE
            : props.theme.color.BLUE_30;
        } else {
          color = props.theme.color.WHITE;
          borderColor = props.theme.color.COOL_GRAY_40;
          bgColor = props.disabled
            ? props.theme.color.BLUE_50
            : props.theme.color.BLUE_70;
          hoverBgColor = props.disabled
            ? props.theme.color.BLUE_50
            : props.theme.color.BLUE_80;
        }
        break;
    }

    if (props.outline) {
      // Outline Style
      return `
        font-size: ${fontSize}px;
        font-weight: ${fontWeight};
        background-color: ${bgColor || props.theme.color.WHITE};
        :hover:not([disabled]) {
            background-color: ${
              hoverBgColor ||
              props.hoverBackgroundColor ||
              props.theme.color.BLUE_30
            };
        }
        border: 1px solid ${borderColor || color || props.theme.color.BLUE_70};
        color: ${color || props.theme.color.BLUE_70};
        & path {
          fill: ${
            props.disabled
              ? props.theme.color.COOL_GRAY_50
              : props.theme.color[props.iconColor] ||
                color ||
                borderColor ||
                props.theme.color.BLUE_70
          };
        }
      `;
    } else {
      // Contain Style
      return `
        font-size: ${fontSize}px;
        background-color: ${
          bgColor || props.backgroundColor || props.theme.color.BLUE_70
        };
        color: ${color || props.textColor || props.theme.color.WHITE};
        & path {
          fill: ${color || props.textColor || props.theme.color.WHITE};
        }

        :hover:not([disabled]) {
            background-color: ${
              hoverBgColor ||
              props.hoverBackgroundColor ||
              props.theme.color.BLUE_80
            };
            color: ${
              color || props.textColor || props.theme.color.COOL_GRAY_40
            };
            & path {
              fill: ${
                color || props.textColor || props.theme.color.COOL_GRAY_40
              };
            }
        }
      `;
    }
  }}
  // opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  transition: 
    background-color 0.2s ease-in-out, 
    border-color 0.2s ease-in-out;

  & svg {
    // Button 에 포함된 Icon 기본 사이즈
    height: 16px;
    width: 16px;
  }

  & .${SPINNER_CLASS} {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const rippleAnim = keyframes`
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(4);
    opacity: 0;
  }
`;

/**
 * 클릭시 보여주는 이펙트
 * XXX(준호?): 다운라이즈 시 보여주는 요소 개발에 응용??
 */
const Ripple = styled.span`
  width: ${(props) => props.diameter}px;
  height: ${(props) => props.diameter}px;
  position: absolute;
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  border-radius: 50%;
  transform: scale(0);
  animation: ${rippleAnim} 600ms linear;
  // background-color: rgba(255, 255, 255, 0.7);
  background-color: ${(props) => {
    if (props.outline) {
      return props.color
        ? props.theme.color.MEDIUM_DARK
        : props.theme.color.BLUE_40;
    }
    return props.theme.color.BLUE_90;
  }};
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => (props.pending ? 0 : 1)};
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
`;

/**
 * props.style.width 부여할때 padding: 12px 16px; 과 border: 1px 고려 필요
 * @param {*} props
 * @returns
 */
function Button(props, ref) {
  const {
    outline,
    color,
    style,
    disabled,
    pending,
    title,
    text,
    backgroundColor,
    hoverBackgroundColor,
    textColor,
    startIcon,
    endIcon,
    onClick,
    iconColor,
    dataTestId,
  } = props;
  const hasIcons = Boolean(startIcon && endIcon);

  const [rippleAttributes, setRippleAttributes] = useState({
    diameter: 0,
    left: -1,
    top: -1,
  });
  const [showRipple, setShowRipple] = useState(false);

  useEffect(() => {
    let timerId;

    if (showRipple) {
      timerId = setTimeout(() => {
        setShowRipple(false);
      }, 600);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [showRipple]);

  return (
    <Wrapper
      {...props}
      data-testid={dataTestId}
      ref={ref}
      hasIcons={hasIcons}
      outline={!!outline}
      color={color}
      style={style}
      iconColor={iconColor}
      disabled={disabled}
      pending={pending}
      onClick={(event) => {
        const rect = event.target.getBoundingClientRect();
        const diameter = Math.max(
          event.target.clientWidth,
          event.target.clientHeight
        );
        const radius = diameter / 2;
        if (!(disabled || pending) && typeof onClick === 'function') {
          setRippleAttributes({
            diameter: diameter,
            left: event.clientX - rect.left - radius,
            top: event.clientY - rect.top - radius,
          });
          setShowRipple(true);
          onClick(event);
        }
      }}
      backgroundColor={backgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      textColor={textColor}>
      {showRipple && (
        <Ripple outline={!!outline} color={color} {...rippleAttributes} />
      )}

      <ContentWrapper pending={pending}>
        {/* Start icon */}
        {startIcon && (
          <IconContainer style={{ marginRight: 4 }}>{startIcon}</IconContainer>
        )}

        {/* Title */}
        <TextWrapper>{title || text}</TextWrapper>

        {/* End icon */}
        {endIcon && (
          <IconContainer style={{ marginLeft: 4 }}>{endIcon}</IconContainer>
        )}
      </ContentWrapper>

      {pending && (
        <ButtonSpinner
          reverse={
            (color && !['error', 'primary'].includes(color)) || !!outline
          }
        />
      )}
    </Wrapper>
  );
}

// export default Button;
export default React.forwardRef(Button);
