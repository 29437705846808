import { useIntl } from 'react-intl';
import styled from 'styled-components';

import Const from 'constant/Const';
import { HEIGHT_HEADER } from 'constant/StyleConst';
import { DATA_TEST_ID } from 'constant/DataTestId';

import { getLocale } from 'util/Utility';

import TestNotificationDropdownContainer from 'redux/container/fragment/main/TestNotificationDropdownContainer';

import INTL_MAP from 'component/fragment/statistics/INTL';

import Avatar from 'component/ui/image/Avatar';
import Menu from 'component/ui/menu/Menu';
import MenuItem from 'component/ui/menu/MenuItem';

import { ReactComponent as LogoMemoSmall } from 'static/image/logo-memo-small.svg';

function Header(props) {
  const {
    history,
    // Redux state
    authState,
    // Redux dispatch
    handleLogout,
    handleHideSidePanel,
    memoDataloaderMfdFiles,
  } = props;
  const intl = useIntl();
  const locale = getLocale();

  return (
    <Wrapper>
      <LogoImage
        onClick={() => {
          if (history.location.pathname === '/') {
            history.go(0);
          } else {
            history.push('/');
          }
        }}
      />
      <Filler />

      {authState.user && (
        <>
          <TestNotificationDropdownContainer />
          <Avatar imgSrc={null} />
          <Menu
            dataTestId={DATA_TEST_ID.HEADER.PROFILE_MENU}
            style={{ marginLeft: 8, cursor: 'pointer' }}
            title={`${authState.user?.hospital?.hospitalName ?? ''} ${
              authState.user?.lastName
            }${authState.user?.firstName}${intl.locale === 'ko' ? '님' : ''}`}
            handleHideSidePanel={handleHideSidePanel}>
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-01',
                description: '내 계정',
                defaultMessage: '내 계정',
              })}
              onClick={() => {
                history.push('/my-account');
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-02',
                description: '비밀번호 변경',
                defaultMessage: '비밀번호 변경',
              })}
              onClick={() => {
                history.push('/change-password');
              }}
            />
            {authState.isAdmin && (
              <MenuItem
                title={intl.formatMessage({
                  id: '02-Header-MenuItem-title-03',
                  description: '사용자 관리',
                  defaultMessage: '사용자 관리',
                })}
                onClick={() => {
                  history.push('/user-management');
                }}
              />
            )}
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-09',
                description: '알림 목록 / Notifications',
                defaultMessage: '알림 목록',
              })}
              onClick={() => {
                history.push('/test-notification-history');
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-04',
                description: '약관 및 개인정보 보호',
                defaultMessage: '약관 및 개인정보 보호',
              })}
              onClick={() => {
                history.push('/privacy');
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-10',
                description: '소프트웨어 정보',
                defaultMessage: '소프트웨어 정보',
              })}
              onClick={() => {
                history.push('/udi-information');
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-05',
                description: '데이터로더 다운로드',
                defaultMessage: '데이터로더 다운로드',
              })}
              onClick={() => {
                window.open(memoDataloaderMfdFiles.dataLoader.filePath);
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-11',
                description: '데스크탑 앱 다운로드',
                defaultMessage: '데스크탑 앱 다운로드',
              })}
              onClick={() => {
                if (memoDataloaderMfdFiles.MEMOForDesktopApp.filePath)
                  window.open(
                    memoDataloaderMfdFiles.MEMOForDesktopApp.filePath
                  );
              }}
            />
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-08',
                description: '사용자 가이드',
                defaultMessage: '사용자 가이드',
              })}
              onClick={() => {
                history.push('/user-guide');
              }}
            />
            {locale === Const.LANGUAGE.KO && (
              <MenuItem
                title={intl.formatMessage(INTL_MAP.ANALYTICS_TITLE)}
                onClick={() => {
                  history.push('/stats');
                }}
              />
            )}
            {locale === Const.LANGUAGE.KO && (
              <MenuItem
                title={'청구내역 조회'}
                onClick={() => {
                  history.push('/payment');
                }}
              />
            )}
            {locale === Const.LANGUAGE.KO && (
              <MenuItem
                title={'1:1 문의'}
                onClick={() => {
                  window.open(process.env.REACT_APP_KAKAO_TALK_LINK);
                }}
              />
            )}
            <MenuItem
              title={intl.formatMessage({
                id: '02-Header-MenuItem-title-07',
                description: '로그아웃',
                defaultMessage: '로그아웃',
              })}
              onClick={() => {
                handleLogout();
              }}
            />
            <VersionWrapper>
              <Divider />
              <VersionText>{`Version ${process.env.REACT_APP_VERSION}`}</VersionText>
            </VersionWrapper>
          </Menu>
        </>
      )}
    </Wrapper>
  );
}

export default Header;

const Wrapper = styled.div`
  width: calc(100% - 44px);
  height: ${HEIGHT_HEADER}px;
  padding: 0 24px 0 20px;
  position: fixed;
  background-color: ${(props) => props.theme.color.WHITE};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 10px rgba(36, 38, 38, 0.05);
  z-index: 10;
`;

const Filler = styled.div`
  flex: 1;
`;

const LogoImage = styled(LogoMemoSmall)`
  cursor: pointer;
`;

const VersionWrapper = styled.div`
  gap: 1px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.color.COOL_GRAY_30};
`;

const VersionText = styled.div`
  padding: 6px 12px 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 130%;
  color: ${(props) => props.theme.color.COOL_GRAY_70};
`;
