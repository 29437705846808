import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from 'react-intl';

import { SortableColumn } from 'component/ui/table/SortableTableHeader';
import { FilterOption } from 'component/fragment/statistics/FilterDropdown';

import Const from 'constant/Const';
import { PAGE_SIZE, PATCH_LIST_SORT_ORDER } from 'constant/StatisticsConst';

import {
  getAnalyticsStatisticsRequested,
  getPatchStatisticsRequested,
  //
  selectStatsAnalyticsData,
  selectStatsPatchData,
  selectStatsPatchMetaData,
  selectStatsPatchPending,
} from 'redux/duck/hospital/hospitalDuck';
import { selectUserHospital } from 'redux/duck/authDuck';

import INTL_MAP from 'component/fragment/statistics/INTL';

const { ORDER_TYPE } = Const;
const PATCH_LIST_PAGE_SIZE = PAGE_SIZE;

function useHospitalStats() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { hid: hospitalId } = useSelector(selectUserHospital, shallowEqual) as {
    hid: number | undefined;
  };
  const statsAnalyticsData = useSelector(
    selectStatsAnalyticsData,
    shallowEqual
  );
  const statsPatchData = useSelector(selectStatsPatchData, shallowEqual);
  const StatsPatchPending = useSelector(selectStatsPatchPending, shallowEqual);
  const { fetchType, currentPage, totalCount } = useSelector(
    selectStatsPatchMetaData,
    shallowEqual
  );

  const handelGetAnalyticsStatistics = () => {
    if (hospitalId === undefined) return;
    dispatch(getAnalyticsStatisticsRequested());
  };
  const handelGetPatchStatistics = (arg0: { page?: number } = {}) => {
    const { page } = arg0;
    if (hospitalId === undefined) return;

    // Make Request Payload
    const patchStatus = selectedStatusFilterList
      .map((value) => value.value)
      .join(',');
    const sortOrder = Object.entries(sortOrderTypeMap).find(
      ([key, value]) => value !== ORDER_TYPE.NONE
    );
    // Default Sort Order
    let ordering = `${PATCH_LIST_SORT_ORDER.PATCH_STATUS},${PATCH_LIST_SORT_ORDER.SERIAL_NUMBER}`;
    if (sortOrder) {
      const [fieldName, orderType] = sortOrder;
      const orderPrefix = orderType === ORDER_TYPE.DESCENDING ? '-' : '';
      switch (fieldName) {
        case PATCH_LIST_SORT_ORDER.SERIAL_NUMBER:
          ordering = `${orderPrefix}${PATCH_LIST_SORT_ORDER.SERIAL_NUMBER}`;
          break;
        case PATCH_LIST_SORT_ORDER.USED:
          ordering = `${orderPrefix}${PATCH_LIST_SORT_ORDER.USED},${PATCH_LIST_SORT_ORDER.SERIAL_NUMBER}`;
          break;
        case PATCH_LIST_SORT_ORDER.REMAINING:
          ordering = `${orderPrefix}${PATCH_LIST_SORT_ORDER.REMAINING},${PATCH_LIST_SORT_ORDER.SERIAL_NUMBER}`;
          break;
        default:
          break;
      }
    }

    dispatch(
      getPatchStatisticsRequested({
        patchStatus,
        q: serialNumberQuery,
        ordering,
        page: page ?? 1,
        pageSize: PATCH_LIST_PAGE_SIZE,
      })
    );
  };

  const [selectedStatusFilterList, setSelectedStatusFilterList] = useState(
    Array<FilterOption>()
  );
  const handleOnStatusFilterChange = (selectOption?: FilterOption) => {
    // 선택사항 초기화
    if (!selectOption) {
      setSelectedStatusFilterList([]);
      return;
    }

    // 선택 사항 토글
    const isSelected = selectedStatusFilterList.some(
      (selectedOption) => selectedOption.value === selectOption.value
    );
    let newSelectedStatusFilterList;
    if (isSelected) {
      newSelectedStatusFilterList = [...selectedStatusFilterList].filter(
        (value) => value.value !== selectOption.value
      );
    } else {
      newSelectedStatusFilterList = [
        ...selectedStatusFilterList,
        selectOption,
      ].sort((a, b) => a.value.localeCompare(b.value, 'en', { numeric: true }));
    }
    setSelectedStatusFilterList(newSelectedStatusFilterList);
  };

  const [serialNumberQuery, setSerialNumberQuery] = useState('');
  const handleSerialNumberQueryChange = (newQuery: string) => {
    //
    setSerialNumberQuery(newQuery);
  };

  const [sortOrderTypeMap, setSortOrderTypeMap] = useState({
    [PATCH_LIST_SORT_ORDER.SERIAL_NUMBER]: ORDER_TYPE.NONE,
    [PATCH_LIST_SORT_ORDER.USED]: ORDER_TYPE.NONE,
    [PATCH_LIST_SORT_ORDER.REMAINING]: ORDER_TYPE.NONE,
  });
  const handleOnSortOrderChange = (
    newValue: (prevValue: typeof sortOrderTypeMap) => typeof sortOrderTypeMap
  ) => {
    if (typeof newValue === 'function') {
      const _newValue = newValue(sortOrderTypeMap);
      setSortOrderTypeMap(_newValue);
      return;
    }
    setSortOrderTypeMap(newValue);
  };
  const tableColumnList = useMemo<Array<SortableColumn>>(
    () => [
      {
        label: intl.formatMessage(
          INTL_MAP[PATCH_LIST_SORT_ORDER.SERIAL_NUMBER]
        ),
        sortOption: {
          isSortable: true,
          orderColumn: PATCH_LIST_SORT_ORDER.SERIAL_NUMBER,
          defaultSortType:
            sortOrderTypeMap[PATCH_LIST_SORT_ORDER.SERIAL_NUMBER],
          setOrderByType: handleOnSortOrderChange,
        },
      },
      {
        label: intl.formatMessage(INTL_MAP[PATCH_LIST_SORT_ORDER.PATCH_STATUS]),
        sortOption: {
          isSortable: false,
        },
      },
      {
        label: intl.formatMessage(INTL_MAP[PATCH_LIST_SORT_ORDER.USED]),
        sortOption: {
          isSortable: true,
          orderColumn: PATCH_LIST_SORT_ORDER.USED,
          defaultSortType: sortOrderTypeMap[PATCH_LIST_SORT_ORDER.USED],
          setOrderByType: handleOnSortOrderChange,
          isDescFirst: true,
        },
      },
      {
        label: intl.formatMessage(INTL_MAP[PATCH_LIST_SORT_ORDER.REMAINING]),
        sortOption: {
          isSortable: true,
          orderColumn: PATCH_LIST_SORT_ORDER.REMAINING,
          defaultSortType: sortOrderTypeMap[PATCH_LIST_SORT_ORDER.REMAINING],
          setOrderByType: handleOnSortOrderChange,
          isDescFirst: true,
        },
      },
    ],
    [sortOrderTypeMap]
  );

  useEffect(() => {
    handelGetPatchStatistics();
  }, [selectedStatusFilterList, sortOrderTypeMap, serialNumberQuery]);

  return {
    /** 분석 통계 데이터 */
    statsAnalyticsData,
    /** Patch 사용 통계 데이터 */
    statsPatchData,
    /** Patch 사용 통계 Fetching Pending */
    StatsPatchPending,
    /** Patch 사용 통계 Filter 값 */
    patchFilterValues: {
      /** 선택된 Patch 상태 필터 목록 */
      selectedStatusFilterList,
      /** 입력된 Patch 일련번호 검색어 */
      serialNumberQuery: serialNumberQuery,
      /** Patch 상태 필터 목록 변경 함수 */
      handleOnStatusFilterChange,
      /** Patch 일련번호 검색어 변경 함수 */
      handleSerialNumberQueryChange,
    },
    /** Patch 사용 통계 테이블 메타 데이터 */
    patchTableMetaValues: {
      /** 정렬이 가능한 Patch 목록 필드 정보 */
      tableColumnList,
      /** 조회 방식; 단순 조회 | 필터링 조회 */
      fetchType,
      /** 현재 조회 중 페이지 번호 */
      currentPage,
      /** 전체 항목 수 */
      totalCount,
    },
    //
    /** 분석 통계 데이터 Fetch 요청 함수 */
    handelGetAnalyticsStatistics,
    /** Patch 사용 통계 데이터 Fetch 요청 함수 */
    handelGetPatchStatistics,
  };
}

export default useHospitalStats;
