import Const from 'constant/Const';

import {
  EcgTestStatus,
  EcgTest,
  TimeStampType,
  PageType,
  DateType,
} from 'type/apiDataType';
import { Nullable } from 'type/commonType';

// types
export type FetchType =
  | typeof Const.FETCH_TYPE.SEARCH_MODE
  | typeof Const.FETCH_TYPE.FETCH_MODE;

// interfaces
export interface AsyncStatus<T> {
  pending: boolean;
  error: any;
  data: T;
}

export interface AsyncStatusWithNoData extends Omit<AsyncStatus<any>, 'data'> {}

export interface IFetchState extends AsyncStatus<EcgTest[]> {
  tableFilterOptions: TableFilterOptions;
}

interface ISidePanelState {
  isOpen: boolean;
  ecgTest: Nullable<EcgTest>;
}

export interface TableFilterOptions {
  fetchType: FetchType;
  firstPage: PageType;
  page: PageType;
  lastPage: PageType;
  totalCount: PageType;
  searchKeyword: string;
  ecgTestStatus: EcgTestStatus[];
  prescriptionDuration: number[];
  selectedStartEndDateTime: SelectedStartEndDateTime;
  orderBy: string; // ordering 목록을 타입 혹은 enum으로 작성해야함
  isManuallyUpdated: boolean;
}
type SelectedStartEndDateTime = {
  type: 'startTimestampMsRange' | 'internalConfirmTimestampMsRange' | string;
  startTimestampMsRange: DateType[];
  internalConfirmTimestampMsRange: DateType[];
};

export interface EcgTestListInitialState {
  create: AsyncStatus<any>;
  fetch: IFetchState;
  patch: AsyncStatus<any>;
  revertStatus: AsyncStatusWithNoData;
  delete: AsyncStatus<any>;
  returnDevice: AsyncStatus<any>;
  confirm: AsyncStatus<any>;
  editDone: AsyncStatus<any>;
  validReport: AsyncStatus<any>;
  sidePanel: ISidePanelState;
  download: AsyncStatus<null>;
}

export const SELECTED_START_END_DATE_TIME = 'selectedStartEndDateTime';
