import { FormattedMessage } from 'react-intl';

import Const from 'constant/Const';

const { PATIENT_SEX_LABEL_DICT, PATIENT_PACEMAKER_LABEL_DICT } = Const;

export const genderInfo = [
  {
    value: 'M',
    label: <FormattedMessage {...PATIENT_SEX_LABEL_DICT[`patientSex_M`]} />,
  },
  {
    value: 'F',
    label: <FormattedMessage {...PATIENT_SEX_LABEL_DICT[`patientSex_F`]} />,
  },
];

export const pacemakerInfo = [
  {
    value: null,
    label: (
      <FormattedMessage {...PATIENT_PACEMAKER_LABEL_DICT[`pacemaker_Null`]} />
    ),
  },
  {
    value: false,
    label: (
      <FormattedMessage {...PATIENT_PACEMAKER_LABEL_DICT[`pacemaker_False`]} />
    ),
  },
  {
    value: true,
    label: (
      <FormattedMessage {...PATIENT_PACEMAKER_LABEL_DICT[`pacemaker_True`]} />
    ),
  },
];
