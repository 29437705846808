import {
  SELECTED_START_END_DATE_TIME,
  TableFilterOptions,
} from 'redux/duck/ecgTestList/ecgTestListDuckType';

// Common Types
type Params = { [x: string]: any };

type BuildBaseParamsPram = {
  ecgTestStatus: TableFilterOptions['ecgTestStatus'];
  page: TableFilterOptions['page'];
};
export function buildBaseParams({
  ecgTestStatus,
  page,
}: BuildBaseParamsPram): Params {
  return {
    version: 2,
    testStatus: ecgTestStatus,
    page,
    pageSize: 100,
  };
}

type AddPrescriptionDurationParam = {
  params: Params;
  prescriptionDuration: TableFilterOptions['prescriptionDuration'];
};
export function addPrescriptionDuration({
  params,
  prescriptionDuration,
}: AddPrescriptionDurationParam): Params {
  return prescriptionDuration ? { ...params, prescriptionDuration } : params;
}

type AddOrderByParam = {
  params: Params;
  orderBy: TableFilterOptions['orderBy'];
};
export function addOrderBy({ params, orderBy }: AddOrderByParam) {
  return orderBy ? { ...params, orderBy } : params;
}

type AddSearchKeywordParam = {
  params: Params;
  searchKeyword: TableFilterOptions['searchKeyword'];
};
export function addSearchKeyword({
  params,
  searchKeyword,
}: AddSearchKeywordParam): Params {
  return searchKeyword ? { ...params, q: searchKeyword } : params;
}

type AddSelectedStartEndDateTimeParam = {
  params: Params;
  selectedStartEndDateTime: TableFilterOptions['selectedStartEndDateTime'];
};
export function addSelectedStartEndDateTime({
  params,
  selectedStartEndDateTime,
}: AddSelectedStartEndDateTimeParam): Params {
  if (!selectedStartEndDateTime) {
    return { ...params };
  }

  const dateTimeType = selectedStartEndDateTime.type;
  const msTimeRange = selectedStartEndDateTime[dateTimeType];
  if (
    msTimeRange.length !== 2 ||
    msTimeRange[0] === null ||
    msTimeRange[1] === null
  ) {
    return { ...params };
  }

  const dateTimeValue = [
    Date.parse(msTimeRange[0]),
    Date.parse(msTimeRange[1]),
  ];

  return { ...params, [dateTimeType]: dateTimeValue };
}

export function combineValuesByKeys({ statusArray, targetStatusArray }) {
  return statusArray
    .filter((item) => targetStatusArray.includes(item.key))
    .map((item) => item.value)
    .flat();
}

export function updateUrlWithParams({
  searchKeyword,
  ecgTestStatus,
  prescriptionDuration,
  orderBy,
  page,
  selectedStartEndDateTime,
}) {
  const queryParams = convertToQueryParams({
    searchKeyword,
    ecgTestStatus,
    prescriptionDuration,
    orderBy,
    page,
    selectedStartEndDateTime,
  });

  window.history.pushState('', '', `?${queryParams}`);
}
function convertToQueryParams(obj) {
  const queryParams = new URLSearchParams();

  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    let value = obj[key];

    if (key === SELECTED_START_END_DATE_TIME) {
      value = obj[key][obj[key].type].map((dateTime) => Date.parse(dateTime));
      key = obj[key].type;
    }

    // 값이 없는 경우 무시
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      value.length === 0
    ) {
      continue;
    }

    // 값이 배열인 경우, 콤마로 구분된 문자열로 변환
    const stringValue = Array.isArray(value) ? value.join(',') : value;
    queryParams.set(key, stringValue);
  }

  return queryParams;
}

export function formatBirthDate(birthDate: string) {
  // 정규표현식을 사용하여 yyyy, yyyy-mm, yyyy-mm-dd 형태를 확인
  const yearOnlyRegex = /^\d{4}$/;
  const yearMonthRegex = /^\d{4}-\d{1,2}$/;

  if (yearOnlyRegex.test(birthDate)) return `${birthDate}-**-**`;
  if (yearMonthRegex.test(birthDate)) return `${birthDate}-**`;
  return birthDate;
}
export function formatPatientName(patientName: string) {
  if (patientName !== '') return patientName;
  return '***';
}
