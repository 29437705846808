import { call, debounce, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager/ApiManager';
import { DIAGNOSIS_LABEL_POSITION_IN_STRIP } from 'util/ClassLabelUtil';

// Actions
// Fetch daily heart rate
const FETCH_DAILY_HEART_RATE_REQUESTED =
  'memo-web/patch-ecgs/FETCH_DAILY_HEART_RATE_REQUESTED';
const FETCH_DAILY_HEART_RATE_SUCCEED =
  'memo-web/patch-ecgs/FETCH_DAILY_HEART_RATE_SUCCEED';
const FETCH_DAILY_HEART_RATE_FAILED =
  'memo-web/patch-ecgs/FETCH_DAILY_HEART_RATE_FAILED';
// Fetch explorer data
const FETCH_EXPLORER_DATA_REQUESTED =
  'memo-web/patch-ecgs/FETCH_EXPLORER_DATA_REQUESTED';
const FETCH_EXPLORER_DATA_SUCCEED =
  'memo-web/patch-ecgs/FETCH_EXPLORER_DATA_SUCCEED';
const FETCH_EXPLORER_DATA_FAILED =
  'memo-web/patch-ecgs/FETCH_EXPLORER_DATA_FAILED';
// Get Event Counts
const GET_EVENT_COUNTS_REQUESTED =
  'memo-web/patch-ecgs/GET_EVENT_COUNTS_REQUESTED';
const GET_EVENT_COUNTS_SUCCEED = 'memo-web/patch-ecgs/GET_EVENT_COUNTS_SUCCEED';
const GET_EVENT_COUNTS_FAILED = 'memo-web/patch-ecgs/GET_EVENT_COUNTS_FAILED';

// bulk confirm
const BULK_CONFIRM_REQUESTED = 'memo-web/patch-ecgs/BULK_CONFIRM_REQUESTED';
const BULK_CONFIRM_SUCCEED = 'memo-web/patch-ecgs/BULK_CONFIRM_SUCCEED';
const BULK_CONFIRM_FAILED = 'memo-web/patch-ecgs/BULK_CONFIRM_FAILED';

//
const SET_BLOCK_SECTIONLIST = 'memo-web/events/SET_BLOCK_SECTIONLIST';
// Reducer
const initialState = {
  // [{onset: timeStamp, termination: timeStamp}, ...]
  blockSectionList: [],
  dailyHeartRate: {
    pending: false,
    data: null,
    error: null,
  },
  explore: {
    backwardPending: false,
    forwardPending: false,
    pending: false,
    startTimestamp: null,
    endTimestamp: null,
    data: [], // ecgRawList
    entireEcgDataList: [],
    isJumpToTime: null, // boolean
    scrollType: null, // up, down, ''
    navigatorTime: null,
    caretTime: null,
    error: null,
  },
  counts: {
    pending: false,
    data: null,
    error: null,
  },
};

const generateEntireEcgDataList = (ecgRawList, navigatorTime) => {
  if (ecgRawList.length === 0) return;
  /**
   * @type {{
   *   timestamp: timestamp,
   *   diagnosis: Array<{type: number, diagnosisLabelPositionInStrip: string}>,
   *   raws: [], - ecgData의 raw 데이터
   *   caret: boolean
   * }}
   */
  let ecgDataChartItem = {
    timestamp: [],
    diagnosis: [
      // {
      //   type: '', // DIAGNOSIS_TYPE
      //   diagnosisLabelPositionInStrip: '', // VALUE: start, middle, end
      //
      // },
    ],
    isPatientTriggered: [], // [boolean, boolean, boolean]
    raws: [],
    caret: false,
  };
  let result = [];

  console.time('--- make Object');
  // ecgRawList 1200개 - 0.221ms
  var obj = {};
  for (let i of ecgRawList) {
    obj[i.startTimestamp] = i;
  }
  console.timeEnd('--- make Object');

  // ecgRawList 60개 => 24.105224609375ms (diagnosis 확장 이전, 이후가 같음)
  // ecgRawList 1200개 => 327ms
  console.time('--- Make chartList Data');
  for (let i = 0; i < ecgRawList.length; i++) {
    let ecgRaw = ecgRawList[i];
    let currDiagnosis = ecgRaw.diagnosis;
    let prevDiagnosis = i != 0 ? ecgRawList[i - 1].diagnosis : '';
    let nextDiagnosis =
      i != ecgRawList.length - 1 ? ecgRawList[i + 1].diagnosis : '';
    let diagnosisLabelPositionInStrip;

    // [0, 0, 0, 1, 2, 2, 3, 4, 4, 4, 4]
    if (prevDiagnosis != currDiagnosis) {
      diagnosisLabelPositionInStrip = DIAGNOSIS_LABEL_POSITION_IN_STRIP.START;
    } else if (nextDiagnosis != currDiagnosis) {
      diagnosisLabelPositionInStrip = DIAGNOSIS_LABEL_POSITION_IN_STRIP.END;
    } else {
      diagnosisLabelPositionInStrip = DIAGNOSIS_LABEL_POSITION_IN_STRIP.MIDDLE;
    }

    ecgDataChartItem.timestamp = [
      ...ecgDataChartItem.timestamp,
      ecgRaw.startTimestamp,
    ];
    ecgDataChartItem.raws = [...ecgDataChartItem.raws, ...ecgRaw.ecgData];
    ecgDataChartItem.diagnosis = [
      ...ecgDataChartItem.diagnosis,
      { type: ecgRaw.diagnosis, diagnosisLabelPositionInStrip },
    ];
    ecgDataChartItem.isPatientTriggered = [
      ...ecgDataChartItem.isPatientTriggered,
      ecgRaw.isPatientTriggered,
    ];

    if (navigatorTime && ecgRaw.startTimestamp === navigatorTime) {
      ecgDataChartItem.caret = true;
    }
    if (i % 3 === 2) {
      result.push(ecgDataChartItem);
      ecgDataChartItem = {
        timestamp: [],
        diagnosis: [],
        isPatientTriggered: [],
        raws: [],
        caret: false,
      };
    }
  }
  console.timeEnd('--- Make chartList Data');
  return result;
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Fetch daily heart rate
    case FETCH_DAILY_HEART_RATE_REQUESTED:
      return {
        ...state,
        dailyHeartRate: {
          ...state.dailyHeartRate,
          pending: true,
          error: null,
        },
      };
    case FETCH_DAILY_HEART_RATE_SUCCEED:
      return {
        ...state,
        dailyHeartRate: {
          ...state.dailyHeartRate,
          pending: false,
          data: action.data,
        },
      };
    case FETCH_DAILY_HEART_RATE_FAILED:
      return {
        ...state,
        dailyHeartRate: {
          ...state.dailyHeartRate,
          pending: false,
          error: action.error,
        },
      };
    // Fetch explore data
    case FETCH_EXPLORER_DATA_REQUESTED:
      return {
        ...state,
        explore: {
          ...state.explore,
          backwardPending: action.isInit ? false : action.isBackward,
          forwardPending: action.isInit ? false : action.isForward,
          pending: action.isInit && true,
          isBackward: action.isBackward,
          isForward: action.isForward,
          error: null,
        },
      };
    case FETCH_EXPLORER_DATA_SUCCEED:
      const { data } = action;
      const { atTime, isBackward, isForward, isInit, isJumpToTime, isScroll } =
        action.action;
      let newData, scrollType, ecgRawList, navigatorTime;

      navigatorTime = isInit && atTime;

      // 위로 스크롤시
      if (isScroll && isBackward) {
        newData = [...data, ...state.explore.data];
        scrollType = 'up';
      }
      // 아래로 스크롤시
      if (isScroll && isForward) {
        newData = [...state.explore.data, ...data];
        scrollType = 'down';
      }

      ecgRawList = newData || data;

      let caretTime =
        isJumpToTime && isInit
          ? navigatorTime || state.explore.caretTime
          : null;

      return {
        ...state,
        explore: {
          ...state.explore,
          backwardPending: isInit ? false : action.backward,
          forwardPending: isInit ? false : action.forward,
          pending: false,
          startTimestamp: action.startTimestamp,
          endTimestamp: action.endTimestamp,
          data: ecgRawList,
          entireEcgDataList: generateEntireEcgDataList(
            ecgRawList,
            isJumpToTime && caretTime
          ),
          isJumpToTime: isJumpToTime,
          navigatorTime: navigatorTime,
          caretTime: caretTime,
          scrollType,
        },
      };
    case FETCH_EXPLORER_DATA_FAILED:
      return {
        ...state,
        explore: {
          ...state.explore,
          backwardPending: false,
          forwardPending: false,
          pending: false,
          error: action.error,
        },
      };
    // Get Event Counts
    case GET_EVENT_COUNTS_REQUESTED:
      return {
        ...state,
        counts: {
          ...state.counts,
          pending: true,
          data: null,
          error: null,
        },
      };
    case GET_EVENT_COUNTS_SUCCEED:
      return {
        ...state,
        counts: {
          ...state.counts,
          pending: false,
          data: action.data,
        },
      };
    case GET_EVENT_COUNTS_FAILED:
      return {
        ...state,
        counts: {
          ...state.counts,
          pending: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}

// Action Creators
// Fetch daily heart rate
export function fetchDailyHeartRateRequested(ecgTestId) {
  return {
    type: FETCH_DAILY_HEART_RATE_REQUESTED,
    ecgTestId: ecgTestId,
  };
}
export function fetchDailyHeartRateSucceed(data) {
  return {
    type: FETCH_DAILY_HEART_RATE_SUCCEED,
    data: data,
  };
}
export function fetchDailyHeartRateFailed(error) {
  return { type: FETCH_DAILY_HEART_RATE_FAILED, error: error };
}
// Fetch explorer data
export function fetchExplorerDataRequested(
  ecgTestId,
  atTime,
  secStep,
  isBackward,
  isForward,
  isInit,
  isJumpToTime,
  isScroll
) {
  return {
    type: FETCH_EXPLORER_DATA_REQUESTED,
    ecgTestId,
    atTime,
    secStep,
    isBackward,
    isForward,
    isInit,
    isJumpToTime,
    isScroll,
  };
}
export function fetchExplorerDataSucceed(
  startTimestamp,
  endTimestamp,
  data,
  action
) {
  return {
    type: FETCH_EXPLORER_DATA_SUCCEED,
    startTimestamp,
    endTimestamp,
    data,
    action,
  };
}
export function fetchExplorerDataFailed(error) {
  return { type: FETCH_EXPLORER_DATA_FAILED, error: error };
}
// Get Event Counts
export function getEventCountsRequested(tid, callback) {
  return {
    type: GET_EVENT_COUNTS_REQUESTED,
    tid,
    callback,
  };
}
function getEventCountsSucceed(data) {
  return {
    type: GET_EVENT_COUNTS_SUCCEED,
    data,
  };
}
function getEventCountsFailed(error) {
  return { type: GET_EVENT_COUNTS_FAILED, error };
}

// Sagas
function* readDailyHeartRate(action) {
  try {
    const { status, data } = yield call(
      ApiManager.readDailyHeartRate,
      action.ecgTestId
    );

    const { minStep, results } = data;
    const heartRateData = [];
    results.forEach((result) => {
      result.aggHrs.forEach((hr) => {
        heartRateData.push([hr.timestamp, hr.hrsAvgAvg]);
      });
    });

    yield put(
      fetchDailyHeartRateSucceed({ heartRateData, days: results.length })
    );
  } catch (error) {
    yield put(fetchDailyHeartRateFailed(error));
  }
}

function* readExplorerData(action) {
  try {
    const {
      ecgTestId,
      atTime,
      secStep,
      isBackward,
      isForward,
      isInit,
      isJumpToTime,
      isScroll,
    } = action;
    const { status, data } = yield call(
      ApiManager.readExplorerData,
      ecgTestId,
      atTime,
      secStep,
      isBackward,
      isForward
    );

    const { ecgMeta, inputMeta, results } = data;

    // TODO - JYOON: 다음 정보를 duckstate로 관리하면서 유효성 검사 필요
    // startTimestamp, endTimestamp } = ecgMeta;
    const { startTimestamp, endTimestamp } = ecgMeta;
    // const { atTime } = inputMeta;

    yield put(
      fetchExplorerDataSucceed(startTimestamp, endTimestamp, results, action)
    );

    // TODO: JYOON - EXTRA FETCHING TEST
    // for (let i = 0; i < 3; i++) {
    //   const secondCall = yield call(
    //     ApiManager.readExplorerData,
    //     action.ecgTestId,
    //     action.atTime,
    //     action.secStep,
    //     action.backward,
    //     action.forward
    //   );
    //   console.log(
    //     '%cpatchEcgsDuck.js > readExplorerData> EXTRA FETCHING',
    //     'color: #007acc;',
    //     i
    //   );
    //   yield put(
    //     fetchExplorerDataSucceed(startTimestamp, endTimestamp, results, action)
    //   );
    // }
  } catch (error) {
    yield put(fetchExplorerDataFailed(error));
  }
}

function* getEventCounts(action) {
  try {
    const {
      data: { result },
    } = yield call(
      ApiManager.getEventCounts,
      { tid: action.tid },
      action.callback
    );

    yield put(getEventCountsSucceed(result));
  } catch (error) {
    yield put(getEventCountsFailed(error));
  }
}
export function* saga() {
  yield takeLatest(FETCH_DAILY_HEART_RATE_REQUESTED, readDailyHeartRate);
  yield debounce(500, FETCH_EXPLORER_DATA_REQUESTED, readExplorerData);
  yield takeLatest(GET_EVENT_COUNTS_REQUESTED, getEventCounts);
}
