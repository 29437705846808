import { combineReducers } from 'redux';

// Reducers
import dialogReducer from './duck/dialogDuck';
import authReducer from './duck/authDuck';
import passwordReducer from './duck/passwordDuck';
import userConsentReducer from './duck/userConsentDuck';
import ecgTestListReducer from './duck/ecgTestList/ecgTestListDuck';
import patchEcgsReducer from './duck/patchEcgsDuck';
import medicalStaffsReducer from './duck/medicalStaffsDuck';
import reportsReducer from './duck/reportsDuck';
import reportReducer from './duck/reportDuck';
import deviceSummaryReducer from './duck/deviceSummaryDuck';
import deviceEcgTotalReducer from './duck/deviceEcgDuck';
import patientEventsReducer from './duck/patientEventsDuck';
import testResultReducer from './duck/testResultDuck';
import beatReviewReducer from './duck/beatReviewDuck';
import hrReviewReducer from './duck/hrReviewDuck';
import feedbackReducer from './duck/feedback/feedbackDuck';
import hospitalReducer from './duck/hospital/hospitalDuck';
import taskQueueReducer from './duck/taskQueueDuck';
import testNotificationReducer from './duck/testNotificationDuck';
import paymentReducer from './duck/paymentDuck';

const rootReducer = combineReducers({
  dialogReducer,
  authReducer,
  passwordReducer,
  userConsentReducer,
  ecgTestListReducer,
  patchEcgsReducer,
  medicalStaffsReducer,
  reportsReducer,
  reportReducer,
  deviceSummaryReducer,
  deviceEcgTotalReducer,
  patientEventsReducer,
  testResultReducer,
  beatReviewReducer,
  hrReviewReducer,
  feedbackReducer,
  hospitalReducer,
  taskQueueReducer,
  testNotificationReducer,
  paymentReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
