import Const from 'constant/Const';

import { haveDifferentWordsUtil } from 'util/Utility';

export { haveDifferentWordsUtil as haveDifferentWords };

export const validationList = {
  birthDate: 'birthDate',
  phoneNumber: 'phoneNumber',
  referredBy: 'referredBy',
  note: 'note',
};
export const validationFunctionMap = {
  [validationList.birthDate]: validationBirthDay,
  [validationList.phoneNumber]: validationPhoneNumber,
  [validationList.referredBy]: validationPrescribedBy,
};

export function validateOfTestInfo(validationList) {
  /* 
    # validationReturnType
      * result: '', // boolean
      * type: '', // string
      * msg: '', // string
  */
  let validationResult = [];

  for (let validation of validationList) {
    validationResult.push(validation);
  }

  return validationList;
}

export function validationBirthDay(birthDate, { intl }) {
  const regex =
    /^(19|20)\d{2}(-(\*\*-?\*\*|(0[1-9]|1[0-2])(-?(\*\*|([0-2][0-9]|3[01]))?)?)?)?$/; // YYYY-**-**, YYYY-MM-**, YYYY, YYYY-MM, YYYY-MM-DD, 형식인지 검증

  if (!regex.test(birthDate)) {
    return {
      result: true,
      type: 'birthDay',
      msg: intl.formatMessage({
        id: '99-AddNewTestDialog-AlertDialog-message-01',
        description: '3.4 검사 추가 팝업의 생년월일 검증 실패',
        defaultMessage: '생년월일을 다시 확인하세요.',
      }),
    };
  }

  return {
    result: false,
  };
}

export function validationPhoneNumber(phoneNumber, { intl }) {
  // 전화번호값은 더 이상 필수 요소가 아님, 하지만 값이 있는경우 Validation 은 진행
  if (phoneNumber.length === 0) {
    return {
      result: false,
    };
  }

  const replacedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

  // 각 분기별로 다른 메세지가 들어갈 것을 대비
  if (replacedPhoneNumber.length !== 11) {
    return {
      result: true,
      type: 'birthDay',
      msg: intl.formatMessage({
        id: '99-AddNewTestDialog-AlertDialog-message-02',
        description: '3.4 검사 추가 팝업의 전화번호 검증 실패',
        defaultMessage: '전화번호 형식이 올바르지 않습니다.',
      }),
    };
  }

  if (!!replacedPhoneNumber.match(/[^\d]/g, '')) {
    return {
      result: true,
      type: 'birthDay',
      msg: intl.formatMessage({
        id: '99-AddNewTestDialog-AlertDialog-message-02',
        description: '3.4 검사 추가 팝업의 전화번호 검증 실패',
        defaultMessage: '전화번호 형식이 올바르지 않습니다.',
      }),
    };
  }
  return {
    result: false,
  };
}

export function validationPrescribedBy(referredBy, { intl }) {
  // 각 분기별로 다른 메세지가 들어갈 것을 대비
  if (referredBy === null) {
    return {
      result: true,
      type: 'referredBy',
      msg: intl.formatMessage({
        id: '99-AddNewTestDialog-AlertDialog-message-03',
        description: '3.4 검사 추가 팝업의 처방의 검증 실패',
        defaultMessage: '처방의를 선택하세요.',
      }),
    };
  }

  return {
    result: false,
  };
}

export const phoneNumberCondition = [Const.ECG_TEST_STATUS.BEFORE_TEST];
export const buttonConditionByEcgTestStatus = {
  allowDeleteButton: [Const.ECG_TEST_STATUS.BEFORE_TEST],
  allowEditButton: [
    Const.ECG_TEST_STATUS.BEFORE_TEST,
    Const.ECG_TEST_STATUS.PAIRING_DEVICE,
    Const.ECG_TEST_STATUS.ON_GOING_TEST,
    Const.ECG_TEST_STATUS.WAITING_RETURN,
  ],
};

export const isEditablePhoneNumber = (state) => {
  return !phoneNumberCondition.includes(state);
};

/**
 * patch를 부착 중일때(ECG_TEST_STATUS === PAIRING_DEVICE) 전화번호, 처방의 정보를 수정하면 안되기 때문에 test 정보 업데이트시 alert 문구 보여주는 조건
 *
 * @param {number} ecgTestStatus {@link Const.ECG_TEST_STATUS}
 * @returns
 */
export const isPairingDeviceStatus = (ecgTestStatus) => {
  return ecgTestStatus == Const.ECG_TEST_STATUS.PAIRING_DEVICE;
};
export const isPreventUpdateTestInfo = (ecgTestStatus) => {
  return ecgTestStatus >= Const.ECG_TEST_STATUS.PAIRING_DEVICE;
};
