import { FormattedMessage, defineMessages } from 'react-intl';

const Const = {
  CLIENT_NAME: {
    SYSTEM: 'system',
    WEB_APP: 'memo-web',
    PARTNER_WEB_APP: 'memo-partner-web',
  },

  DEVICE: {
    MOBILE: 'mobile',
    WEB: 'web',
    MFD: 'memoForDesktop',
  },

  // 단위 시간: 8 -> 8시간
  SESSION_DURATION_TIME: 8,

  PATIENT_SEX_LABEL_DICT: defineMessages({
    patientSex_M: {
      id: 'code_patientSex_M',
      description: '남자(성별)',
      defaultMessage: '남',
    },
    patientSex_F: {
      id: 'code_patientSex_F',
      description: '여자(성별)',
      defaultMessage: '여',
    },
  }),

  PATIENT_PACEMAKER_LABEL_DICT: defineMessages({
    pacemaker_Null: {
      id: 'code_pacemaker_Null',
      description: '페이스메이커 선택안함 / Unknown',
      defaultMessage: '선택안함',
    },
    pacemaker_True: {
      id: 'code_pacemaker_True',
      description: '페이스메이커 있음 / Present',
      defaultMessage: '있음',
    },
    pacemaker_False: {
      id: 'code_pacemaker_False',
      description: '페이스메이커 없음 / Absent',
      defaultMessage: '없음',
    },
  }),

  // Table header order type
  ORDER_TYPE: {
    NONE: 0,
    ASCENDING: 1,
    DESCENDING: 2,
    getNextOrderType: function (currentOrder, reverse) {
      if (reverse) {
        switch (currentOrder) {
          case this.DESCENDING:
            return this.ASCENDING;
          case this.NONE:
            return this.DESCENDING;
          case this.ASCENDING:
          default:
            return this.NONE;
        }
      } else {
        switch (currentOrder) {
          case this.ASCENDING:
            return this.DESCENDING;
          case this.NONE:
            return this.ASCENDING;
          case this.DESCENDING:
          default:
            return this.NONE;
        }
      }
    },
  },

  FETCH_TYPE: {
    SEARCH_MODE: 'searchMode',
    FETCH_MODE: 'fetchMode',
  },

  ECG_TEST_STATUS: {
    BEFORE_TEST: 10, // Before Test  # 시작 전
    PAIRING_DEVICE: 20, // Paring Device # 앱페어링
    ON_GOING_TEST: 30, // On Going Test # 시작하기 클릭 후 "측정중"
    WAITING_RETURN: 40, // Wait for Return # 반답 대기
    WAITING_UPLOAD: 50, // Wait for Uploading # 업로드 대기
    ECG_UPLOADED: 60, // ECG Data Uploaded # 업로드 완료
    UNDER_CLOUD: 70, // Under Cloud Analysis # 클라우드 분석 중
    CLOUD_CONFIRMED: 80, // Cloud Confirmed # 보기 / 검토완료하기 / 클라우드 분석 완료
    CUSTOMER_CONFIRMED: 200, // Customer Confirmed # 검토완료하 (80에서 넘어옴)
    ERROR: 990, // Error'
    // DEPRECATED
    CALCULATING_TEST: 90,
    COMPUTING_FAILED: 91,
    COMPUTED: 100,
    REPORT_RENDERING: 110,
    REPORT_RENDERED: 120,
  },

  ECG_TEST_STATUS_LABEL_DICT: defineMessages({
    // status 10, 20 둘 다 테이블에는 동일하게 [시작 전]으로 표기
    ecgTestStatus_10: {
      id: 'code_ecgTestStatus_10',
      description: '시작 전',
      defaultMessage: '시작 전',
    },
    ecgTestStatus_20: {
      id: 'code_ecgTestStatus_20',
      description: '시작 전',
      defaultMessage: '시작 전',
    },
    ecgTestStatus_30: {
      id: 'code_ecgTestStatus_30',
      description: '측정 중',
      defaultMessage: '측정 중',
    },
    ecgTestStatus_40: {
      id: 'code_ecgTestStatus_40',
      description: '반납 대기',
      defaultMessage: '반납 대기',
    },
    ecgTestStatus_50: {
      id: 'code_ecgTestStatus_50',
      description: '업로드 대기',
      defaultMessage: '업로드 대기',
    },
    ecgTestStatus_60: {
      id: 'code_ecgTestStatus_60',
      description: '업로드 완료',
      defaultMessage: '업로드 완료',
    },
    ecgTestStatus_70: {
      id: 'code_ecgTestStatus_70',
      description: '클라우드 분석 중',
      defaultMessage: '클라우드 분석 중',
    },
    ecgTestStatus_80: {
      id: 'code_ecgTestStatus_80',
      description: '클라우드 분석 완료',
      defaultMessage: '클라우드 분석 완료',
    },
    ecgTestStatus_90: {
      id: 'code_ecgTestStatus_90',
      description: '검사 계산 중',
      defaultMessage: '검사 계산 중',
    },
    ecgTestStatus_100: {
      id: 'code_ecgTestStatus_100',
      description: '검사 계산 완료',
      defaultMessage: '검사 계산 완료',
    },
    ecgTestStatus_110: {
      id: 'code_ecgTestStatus_110',
      description: '리포트 생성 중',
      defaultMessage: '리포트 생성 중',
    },
    ecgTestStatus_120: {
      id: 'code_ecgTestStatus_120',
      description: '리포트 생성 완료',
      defaultMessage: '리포트 생성 완료',
    },
    ecgTestStatus_200: {
      id: 'code_ecgTestStatus_200',
      description: '고객 확인 완료',
      defaultMessage: '고객 확인 완료',
    },
    ecgTestStatus_990: {
      id: 'code_ecgTestStatus_990',
      description: '오류',
      defaultMessage: '오류',
    },
  }),

  CLOUD_ANALYSIS_STATUS: {
    INIT: 10, // 'Init'  # '클라우드 분석 대기' 상태
    UNDER_AI: 20, // 'Under AI Analysis'  # '클라우드 분석 중' 상태
    UNDER_PARTNER: 30, // 'Under Partner Analysis'  # 편집 중' 상태
    PARTNER_EDIT_DONE: 60, //  # '편집 완료' 상태
    INTERNAL_EDIT_DONE: 200, // 'Confirmed Internal'  # 휴이노 내부 편집 완료
    ERROR: 990, // 'Error'
    // DEPRECATED
    REPORT_RENDERING: 40, //
    REPORT_RENDERED: 50, //
    UNDER_INTERNAL: 70, // 'Under Internal Analysis'  # 휴이노 내부 판독 중
  },

  // 리포트 생성 status
  REPORT_STATUS: {
    NO_REPORT: 10, // '생성 전'
    COMPUTING: 20, // '계산 중'
    COMPUTING_FAILED: 21, // '계산 실패'
    COMPUTED: 30, //
    REPORT_RENDERING: 40, // 'Rendering'
    REPORT_RENDERING_FAILED: 41, // 'Rendering Failed'
    REPORT_RENDERED: 50, // 'Rendered'
    REPORT_ERROR: 990, //, 'Error'
    isFailGeneratingReport: function (status) {
      return this.getFailGenerateingStatus().includes(status);
    },
    getFailGenerateingStatus: function () {
      return [
        this.COMPUTING_FAILED,
        this.REPORT_RENDERING_FAILED,
        this.REPORT_ERROR,
      ];
    },
  },

  DIAGNOSIS_TYPE: {
    NORMAL: 0,
    APC: 1,
    VPC: 2,
    AF: 3,
    OTHERS: 4,
    NOISE: 5,
    SVT: 6,
    VT: 7,
    AV_BLOCK: 8,
    PAUSE: 9,
    LEAD_OFF: -1,
  },

  CLASSIFICATION: {
    NORMAL: { value: 0, label: 'Normal', shortcut: 'Q' },
    APC: { value: 1, label: 'APC', shortcut: 'W' },
    VPC: { value: 2, label: 'VPC', shortcut: 'E' },
    NOISE: { value: 5, label: 'Noise', shortcut: 'R' },
    AV_BLOCK: { value: 8, label: 'AV Block', shortcut: 'T' },
    AF: { value: 3, label: 'AF', shortcut: 'A' },
    PAUSE: { value: 9, label: 'Pause', shortcut: 'S' },
    OTHERS: { value: 4, label: 'Others', shortcut: 'D' },
    SVT: { value: 6, label: 'SVT', shortcut: 'F' },
    VT: { value: 7, label: 'VT', shortcut: 'G' },
    ALL: { value: -1, label: 'All', shortcut: '' },
    LEAD_OFF: { value: -2, label: 'Lead Off', shortcut: '' },
  },

  PASSWORD_CHANGE_TYPE: {
    DEFAULT: 'default',
    FORGOT: 'forgot',
    DORMANT: 'release-dormant',
    NEW: 'new',
    RECOMMENDED: 'recommended',
  },

  ACCESS_LEVEL: defineMessages({
    READ_ONLY: {
      id: 'code_accessLevel_readOnly',
      description: '열람 전용',
      defaultMessage: '열람 전용',
      value: 1,
    },
    READ_WRITE: {
      id: 'code_accessLevel_readWrite',
      description: '수정 가능',
      defaultMessage: '수정 가능',
      value: 2,
    },
  }),

  STAFF_TYPE: defineMessages({
    DOCTOR: {
      id: 'code_staffType_doctor',
      description: '의사',
      defaultMessage: '의사',
      value: 1,
    },
    NURSE: {
      id: 'code_staffType_nurse',
      description: '간호사',
      defaultMessage: '간호사',
      value: 2,
    },
    MEDICAL_TECHNOLOGIST: {
      id: 'code_staffType_medicalTechnologist',
      description: '임상병리사',
      defaultMessage: '임상병리사',
      value: 3,
    },
    MEDICAL_STAFF: {
      id: 'code_staffType_medicalStaff',
      description: '직원',
      defaultMessage: '직원',
      value: 4,
    },
  }),

  /**
   * 병원 구분
   */
  USER_RELATION_TYPE: {
    INTERNAL: 10,
    PARTNER: 20,
    CUSTOMER: 30,
  },

  LANGUAGE: {
    EN: 'en',
    KO: 'ko',
  },

  EVENT_ITEM_FRAGMENT_CLASSNAME: 'event-item-wrap',

  PATIENT_EVENT_TYPE: {
    PALPITATION: 1, // '두근거림'
    NAUSEA: 2, // '울렁거림'
    HEADACHE: 3, // '두통'
    PLEURODYNIA: 4, // '흉통'
    DYSPNEA: 5, // '호흡 곤란'
    SLEEP: 6, // '취침'
    WAKEUP: 7, // '기상'
    PRESS_BUTTON: 1, //, '패치 버튼'
    ETC: -1, //, 'etc.'
  },
  PATIENT_EVENT_BY: {
    BUTTON: 1, // 'button'
    CHATBOT: 2, // 'chatbot'
  },
  PATIENT_EVENT_ENTRY_TYPE: {
    NOW: 1, // 'now'
    PAST: 2, // 'past'
  },

  /* Key Map */
  KEY_MAP: {
    ARROW_DOWN: 'ArrowDown',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_UP: 'ArrowUp',
    BACKSPACE: 'Backspace',
    BRACKET_LEFT: '[',
    BRACKET_RIGHT: ']',
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    SLASH: '/',
    QUESTION_MARK: '?',
    F1: 'F1',
    COMMA: ',',
    PERIOD: '.',
    //
    KEY_A: 'KeyA',
    KEY_B: 'KeyB',
    KEY_C: 'KeyC',
    KEY_D: 'KeyD',
    KEY_E: 'KeyE',
    KEY_F: 'KeyF',
    KEY_G: 'KeyG',
    KEY_M: 'KeyM',
    KEY_N: 'KeyN',
    KEY_Q: 'KeyQ',
    KEY_R: 'KeyR',
    KEY_S: 'KeyS',
    KEY_T: 'KeyT',
    KEY_V: 'KeyV',
    KEY_W: 'KeyW',
    KEY_X: 'KeyX',
    KEY_Z: 'KeyZ',
  },

  EVENT_TYPE: {
    KEYUP: 'keyup',
    KEYDOWN: 'keydown',
  },

  SIDE_PANEL: {
    TYPE: {
      SIDE_PANEL_SELECTED_TYPE: 'SIDE_PANEL_SELECTED_TYPE', // SidePanel 이벤트그룹에서 이벤트를 선택했을 경우
      CHART_SELECTED_TYPE: 'CHART_SELECTED_TYPE', // ECG차트에서 클릭했을 경우
      CHART_SECTION_TYPE: 'CHART_SECTION_TYPE', // ECG차트에서 구간을 선택했을경우
    },
  },
  CHARACTERS_MAX_LENGTH: {
    NOTE: 2000,
    FINDINGS: 700,
    INTERPRETATION: 200,
  },

  // 신규 검사 추가시 자주 사용하는 검사 일자
  PRESCRIPTION_DURATION_FAVORITES: [2, 3, 7, 8, 14],

  // 최대 검사 가능일
  MAX_PRESCRIPTION_DURATION_DAY: 14,

  // 최소 검사 가능일
  MIN_PRESCRIPTION_DURATION_DAY: 1,
};
export default Const;

export const TAB_VALUE_OBJECT = {
  GENERAL: 'general',
  HR_REVIEW: 'hr-review',
  EVENT_REVIEW: 'event-review',
  ECG_PREVIEW: 'ecg-preview',
  BEAT_REVIEW: 'beat-review',
};

export const TAB_VALUES = [
  TAB_VALUE_OBJECT.GENERAL,
  TAB_VALUE_OBJECT.HR_REVIEW,
  TAB_VALUE_OBJECT.EVENT_REVIEW,
  TAB_VALUE_OBJECT.ECG_PREVIEW,
  TAB_VALUE_OBJECT.BEAT_REVIEW,
];

export const TEST_VERSION = {
  UNKNOWN: null,
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
};

export const NOTHING_REDUX_ACTION = 'memo-web/nothing/NOTHING';

export const WRITING_TYPE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
};

export const START_END_DATE_TIME = {
  TYPE: 'type',
  START_TIMESTAMP_MS_RANGE: 'startTimestampMsRange',
  INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE: 'internalConfirmTimestampMsRange',
};

export const INPUT_TAGNAME_LIST = ['TEXTAREA', 'INPUT'];

export const TEST_LIST_STATUS = {
  // ⬇️ 시작전 ⬇️
  READY_FOR_RECORD: [
    Const.ECG_TEST_STATUS.BEFORE_TEST, // 10
    Const.ECG_TEST_STATUS.PAIRING_DEVICE, // 20
  ],
  // ⬇️ 측정 중 ⬇️
  RECORDING: [
    Const.ECG_TEST_STATUS.ON_GOING_TEST, // 30
  ],
  // ⬇️ 업로드 대기 ⬇️
  WAITING_TO_UPLOAD: [
    Const.ECG_TEST_STATUS.WAITING_RETURN, // 40
    Const.ECG_TEST_STATUS.WAITING_UPLOAD, // 50
  ],
  // ⬇️ 클라우드 분석중 ⬇️
  ANALYZING: [
    Const.ECG_TEST_STATUS.ECG_UPLOADED, // 60
    Const.ECG_TEST_STATUS.UNDER_CLOUD, // 70
  ],
  // ⬇️ 검토 중 ⬇️
  REVIEW: [
    Const.ECG_TEST_STATUS.CLOUD_CONFIRMED, // 80
    Const.ECG_TEST_STATUS.CALCULATING_TEST, // 90
    Const.ECG_TEST_STATUS.COMPUTING_FAILED, // 91
    Const.ECG_TEST_STATUS.COMPUTED, // 100
    Const.ECG_TEST_STATUS.REPORT_RENDERING, // 110
    Const.ECG_TEST_STATUS.REPORT_RENDERED, // 120
  ],
  // ⬇️ 검토 완료 ⬇️
  DONE: [Const.ECG_TEST_STATUS.CUSTOMER_CONFIRMED], // 200
};

export const TEST_STATUS_LABEL_DICT = defineMessages({
  // ⬇️ 시작전 ⬇️
  READY_FOR_RECORD: {
    id: 'code_ecgTestStatus_ready_for_record',
    description: '시작전',
    defaultMessage: '시작전',
  },
  // ⬇️ 측정 중 ⬇️
  RECORDING: {
    id: 'code_ecgTestStatus_recording',
    description: '측정중',
    defaultMessage: '측정중',
  },
  // ⬇️ 업로드 대기 ⬇️
  WAITING_TO_UPLOAD: {
    id: 'code_ecgTestStatus_waiting_to_upload',
    description: '업로드 대기',
    defaultMessage: '업로드 대기',
  },
  // ⬇️ 클라우드 분석중 ⬇️
  ANALYZING: {
    id: 'code_ecgTestStatus_analyzing',
    description: '클라우드 분석중',
    defaultMessage: '클라우드 분석중',
  },
  // ⬇️ 검토 중 ⬇️
  REVIEW: {
    id: 'code_ecgTestStatus_review',
    description: '검토 중',
    defaultMessage: '검토 중',
  },
  // ⬇️ 검토 완료 ⬇️
  DONE: {
    id: 'code_ecgTestStatus_done',
    description: '검토 완료',
    defaultMessage: '검토 완료',
  },
});

export const TEST_STATUS_ARRAY = [
  {
    // ⬇️ 시작전 ⬇️
    key: 'READY_FOR_RECORD',
    label: <FormattedMessage {...TEST_STATUS_LABEL_DICT.READY_FOR_RECORD} />,
    value: TEST_LIST_STATUS.READY_FOR_RECORD,
    color: 'COOL_GRAY_80',
    background: 'COOL_GRAY_40',
  },
  {
    // ⬇️ 측정 중 ⬇️
    key: 'RECORDING',
    label: <FormattedMessage {...TEST_STATUS_LABEL_DICT.RECORDING} />,
    value: TEST_LIST_STATUS.RECORDING,
    color: 'BLUE_80',
    background: 'BLUE_40',
  },
  {
    // ⬇️ 업로드 대기 ⬇️
    key: 'WAITING_TO_UPLOAD',
    label: <FormattedMessage {...TEST_STATUS_LABEL_DICT.WAITING_TO_UPLOAD} />,
    value: TEST_LIST_STATUS.WAITING_TO_UPLOAD,
    color: 'PAUSE_70',
    background: 'PAUSE_40',
  },
  {
    // ⬇️ 클라우드 분석중 ⬇️
    key: 'ANALYZING',
    label: <FormattedMessage {...TEST_STATUS_LABEL_DICT.ANALYZING} />,
    value: TEST_LIST_STATUS.ANALYZING,
    color: 'AVB_90',
    background: 'AVB_40',
  },
  {
    // ⬇️ 검토 중 ⬇️
    key: 'REVIEW',
    label: <FormattedMessage {...TEST_STATUS_LABEL_DICT.REVIEW} />,
    value: TEST_LIST_STATUS.REVIEW,
    color: 'SVE_80',
    background: 'SVE_40',
  },
  {
    // ⬇️ 검토 완료 ⬇️
    key: 'DONE',
    label: <FormattedMessage {...TEST_STATUS_LABEL_DICT.DONE} />,
    value: TEST_LIST_STATUS.DONE,
    color: 'GREEN_90',
    background: 'GREEN_40',
  },
];

export const TIME_ZONE = {
  SEOUL: 'Asia/Seoul',
  HO_CHI_MINH: 'Asia/Ho_Chi_Minh',
};

var arr1 = [
  {
    onset: 0,
    termination: 100,
    event: 'avb2',
  },
  // {
  //   onset: 50,
  //   termination: 150,
  //   event: 'avb2',
  // },
];

function push(arr1, input) {
  const result = [];

  arr1.forEach((item) => {
    if (input.termination < item.onset || input.onset > item.termination) {
      // No overlap
      result.push(item);
    } else if (
      input.onset <= item.onset &&
      input.termination >= item.termination
    ) {
      // Input fully covers item
      return;
    } else {
      if (input.onset > item.onset) {
        result.push({
          onset: item.onset,
          termination: input.onset - 1,
          event: item.event,
        });
      }
      if (input.termination < item.termination) {
        result.push({
          onset: input.termination + 1,
          termination: item.termination,
          event: item.event,
        });
      }
    }
  });

  result.push(input);
  result.sort((a, b) => a.onset - b.onset);
  return result;
}

push(arr1, {
  onset: 50,
  termination: 150,
  event: 'avb3',
});

const arr2 = [
  {
    onset: 0,
    termination: 49,
    event: 'avb2',
  },
  {
    onset: 50,
    termination: 150,
    event: 'avb3',
  },
];
