import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import { useIntl, defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { HEIGHT_CREDIT_ALERT } from 'constant/StyleConst';
import { ALERT_REMAINING_CREDIT } from 'constant/StatisticsConst';
import useCreditAlertManagement from 'component/hook/useCreditAlertManagement';
import { AnalyticsIcon, ErrorIcon, CloseIcon } from 'component/ui/icons';

function CreditAlert() {
  const intl = useIntl();
  const history = useHistory();

  const { isShow, closeAlert } = useCreditAlertManagement();
  return (
    <Wrapper>
      <Collapse in={isShow}>
        <AlertWrapper>
          <AlertSubWrapper>
            <HighlightErrorIcon />
            <MessageText>
              <Highlight>
                {intl.formatMessage(INTL_MAP.HIGHLIGHT_MESSAGE, {
                  alertLimit: ALERT_REMAINING_CREDIT,
                })}
              </Highlight>
              {intl.formatMessage(INTL_MAP.MESSAGE)}
            </MessageText>
          </AlertSubWrapper>
          <AlertSubWrapper>
            <MediumButton
              onClick={() => {
                history.push('/stats');
              }}>
              <AnalyticsIcon />
              {intl.formatMessage(INTL_MAP.BUTTON_TEXT)}
            </MediumButton>
            <StyledCloseIcon onClick={() => closeAlert()} />
          </AlertSubWrapper>
        </AlertWrapper>
      </Collapse>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-height: ${HEIGHT_CREDIT_ALERT}px;

  position: sticky;
  top: 0;

  z-index: 2;
`;

const AlertWrapper = styled.div`
  padding: 18px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => props.theme.color.TOP_ALERT_BACKGROUND};
`;

const AlertSubWrapper = styled.div`
  display: flex;
  align-items: center;

  gap: 18px;
`;

const MessageText = styled.span`
  display: flex;

  font-weight: 500;
  font-size: 14px;
  line-height: 100%;

  letter-spacing: -0.096px;

  color: ${(props) => props.theme.color.COOL_GRAY_90};
`;

const Highlight = styled.div`
  margin-right: 3px;

  font-weight: 700;
  color: ${(props) => props.theme.color.SVE_70};
`;

const HighlightErrorIcon = styled(ErrorIcon)`
  & path {
    fill: ${(props) => props.theme.color.SVE_70};
  }
`;

const MediumButton = styled.div`
  padding: 6px 14px;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;

  background-color: transparent;

  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_80};
  border-radius: 6px;

  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${(props) => props.theme.color.COOL_GRAY_80};

  & svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${(props) => props.theme.color.COOL_GRAY_80};
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 18px;
  height: 18px;
  cursor: pointer;

  & path: {
    fill: ${(props) => props.theme.color.COOL_GRAY_80};
  }
`;

const INTL_MAP = defineMessages({
  HIGHLIGHT_MESSAGE: {
    id: '99-RemainingCreditAlert-message-highlightText',
    description: '분석을 위한 잔여 크레딧이 20개 이하 입니다.',
    defaultMessage: '분석을 위한 잔여 크레딧이 {alertLimit}개 이하 입니다.',
  },
  MESSAGE: {
    id: '99-RemainingCreditAlert-message-text',
    description:
      '추가 구매가 필요한 경우, 유한양행 영업 담당자에게 문의해주세요.',
    defaultMessage:
      '추가 구매가 필요한 경우, 유한양행 영업 담당자에게 문의해주세요.',
  },
  BUTTON_TEXT: {
    id: '99-RemainingCreditAlert-button-text',
    description: '분석 통계 보기',
    defaultMessage: '분석 통계 보기',
  },
});

export default CreditAlert;
