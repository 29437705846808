import {
  call,
  put,
  takeLatest,
  select,
  take,
  debounce,
} from 'redux-saga/effects';

import Const, {
  START_END_DATE_TIME,
  TEST_STATUS_ARRAY as _TEST_STATUS_ARRAY,
} from 'constant/Const';

import {
  buildBaseParams,
  addPrescriptionDuration,
  addOrderBy,
  addSearchKeyword,
  addSelectedStartEndDateTime,
  combineValuesByKeys,
  updateUrlWithParams,
  formatPatientName,
  formatBirthDate,
} from 'util/EcgTestListUtil';

import ApiManager from 'network/ApiManager/ApiManager';

import { EcgTest } from 'type/apiDataType';
import { Nullable } from 'type/commonType';

import { getEcgTestSucceed } from 'redux/duck/testResultDuck';
import { selectUserHospital } from 'redux/duck/authDuck';

import {
  EcgTestListInitialState,
  SELECTED_START_END_DATE_TIME,
} from './ecgTestListDuckType';

const {
  FETCH_TYPE: { SEARCH_MODE, FETCH_MODE },
} = Const;
const TEST_STATUS_ARRAY = _TEST_STATUS_ARRAY;

// Selector
// Actions
// InitialState
// Reducer
// Action Creators
// Saga functions
// Saga

// Selector
export const selectSidePanel = ({ ecgTestListReducer }) =>
  ecgTestListReducer.sidePanel;
export const selectPatchState = ({ ecgTestListReducer }) =>
  ecgTestListReducer.patch;
export const selectTableFilterOptions = ({ ecgTestListReducer }) =>
  ecgTestListReducer.fetch.tableFilterOptions;
export const selectEcgTestDownloadState = ({ ecgTestListReducer }) =>
  ecgTestListReducer.download;
export const selectFetchData = ({ ecgTestListReducer }) =>
  ecgTestListReducer.fetch;
// Actions
// Create
const CREATE_ECG_TEST_REQUESTED = 'ecg-test-list/CREATE_ECG_TEST_REQUESTED';
const CREATE_ECG_TEST_SUCCEED = 'ecg-test-list/CREATE_ECG_TEST_SUCCEED';
const CREATE_ECG_TEST_FAILED = 'ecg-test-list/CREATE_ECG_TEST_FAILED';
// Fetch ECG Test List
const FETCH_ECG_TEST_LIST_REQUESTED = 'ecg-tests/FETCH_ECG_TEST_LIST_REQUESTED';
const FETCH_ECG_TEST_LIST_SUCCEED = 'ecg-tests/FETCH_ECG_TEST_LIST_SUCCEED';
const FETCH_ECG_TEST_LIST_FAILED = 'ecg-tests/FETCH_ECG_TEST_LIST_FAILED';
// Patch
const PATCH_ECG_TEST_REQUESTED = 'ecg-test-list/PATCH_ECG_TEST_REQUESTED';
const PATCH_ECG_TEST_SUCCEED = 'ecg-test-list/PATCH_ECG_TEST_SUCCEED';
const PATCH_ECG_TEST_FAILED = 'ecg-test-list/PATCH_ECG_TEST_FAILED';
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
const REVERT_STATUS_REQUESTED = 'ecg-test-list/REVERT_STATUS_REQUESTED';
const REVERT_STATUS_SUCCEED = 'ecg-test-list/REVERT_STATUS_SUCCEED';
const REVERT_STATUS_FAILED = 'ecg-test-list/REVERT_STATUS_FAILED';
// Delete
const DELETE_ECG_TEST_REQUESTED = 'ecg-test-list/DELETE_ECG_TEST_REQUESTED';
const DELETE_ECG_TEST_SUCCEED = 'ecg-test-list/DELETE_ECG_TEST_SUCCEED';
const DELETE_ECG_TEST_FAILED = 'ecg-test-list/DELETE_ECG_TEST_FAILED';
// Return Device
const ECG_TEST_RETURN_DEVICE_REQUESTED =
  'ecg-test-list/ECG_TEST_RETURN_DEVICE_REQUESTED';
const ECG_TEST_RETURN_DEVICE_SUCCEED =
  'ecg-test-list/ECG_TEST_RETURN_DEVICE_SUCCEED';
const ECG_TEST_RETURN_DEVICE_FAILED =
  'ecg-test-list/ECG_TEST_RETURN_DEVICE_FAILED';
// Confirm Review
const ECG_TEST_CONFIRM_REVIEW_REQUESTED =
  'ecg-test-list/ECG_TEST_CONFIRM_REVIEW_REQUESTED';
const ECG_TEST_CONFIRM_REVIEW_SUCCEED =
  'ecg-test-list/ECG_TEST_CONFIRM_REVIEW_SUCCEED';
const ECG_TEST_CONFIRM_REVIEW_FAILED =
  'ecg-test-list/ECG_TEST_CONFIRM_REVIEW_FAILED';
// Edit Done Review
const ECG_TEST_EDIT_DONE_REVIEW_REQUESTED =
  'ecgTest/ECG_TEST_EDIT_DONE_REVIEW_REQUESTED';
const ECG_TEST_EDIT_DONE_REVIEW_SUCCEED =
  'ecgTest/ECG_TEST_EDIT_DONE_REVIEW_SUCCEED';
const ECG_TEST_EDIT_DONE_REVIEW_FAILED =
  'ecgTest/ECG_TEST_EDIT_DONE_REVIEW_FAILED';
// get validReport latest value from ECG Test API
const GET_VALID_REPORT_REQUESTED = 'ecg-test-list/GET_VALID_REPORT_REQUESTED';
const GET_VALID_REPORT_SUCCEED = 'ecg-test-list/GET_VALID_REPORT_SUCCEED';
const GET_VALID_REPORT_FAILED = 'ecg-test-list/GET_VALID_REPORT_FAILED';
// reset table filter options
const RESET_TABLE_FILTER_OPTIONS = 'ecg-test-list/RESET_TABLE_FILTER_OPTIONS';
// show sidePanel
const SHOW_SIDE_PANEL = 'ecg-test-list/SHOW_SIDE_PANEL';
const HIDE_SIDE_PANEL = 'ecg-test-list/HIDE_SIDE_PANEL';
const SET_SIDE_PANEL_DATA = 'ecg-test-list/SET_SIDE_PANEL_DATA';
// Get ECG Test Medical Fee Download
const GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_REQUESTED =
  'ecg-test-list/GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_REQUESTED';
const GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_SUCCEED =
  'ecg-test-list/GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_SUCCEED';
const GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_FAILED =
  'ecg-test-list/GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_FAILED';
// Parcel
const POST_PARCEL_REQUESTED = 'ecg-test-list/POST_PARCEL_REQUESTED';
const POST_PARCEL_SUCCEED = 'ecg-test-list/POST_PARCEL_SUCCEED';
const POST_PARCEL_FAILED = 'ecg-test-list/POST_PARCEL_FAILED';
const PATCH_PARCEL_REQUESTED = 'ecg-test-list/PATCH_PARCEL_REQUESTED';
const PATCH_PARCEL_SUCCEED = 'ecg-test-list/PATCH_PARCEL_SUCCEED';
const PATCH_PARCEL_FAILED = 'ecg-test-list/PATCH_PARCEL_FAILED';

// Report Download Status Check
const SET_REPORT_DOWNLOAD_STATUS_REQUEST =
  'ecg-test-list/SET_REPORT_DOWNLOAD_STATUS_REQUEST';
const SET_REPORT_DOWNLOAD_STATUS_SUCCEED =
  'ecg-test-list/SET_REPORT_DOWNLOAD_STATUS_SUCCEED';
const SET_REPORT_DOWNLOAD_STATUS_FAILED =
  'ecg-test-list/SET_REPORT_DOWNLOAD_STATUS_FAILED';

// InitialState
const initialState: EcgTestListInitialState = {
  create: {
    pending: false,
    data: null,
    error: null,
  },
  fetch: {
    pending: false,
    data: [],
    error: null,
    // 검색, 필터링, 페이지 등의 옵션 값
    tableFilterOptions: {
      fetchType: FETCH_MODE, // 'fetchMode' | 'searchMode',
      firstPage: 1,
      page: 1,
      lastPage: null,
      totalCount: null,
      searchKeyword: '',
      ecgTestStatus: [],
      prescriptionDuration: [],
      selectedStartEndDateTime: {
        type: START_END_DATE_TIME.START_TIMESTAMP_MS_RANGE, //"startTimestampMsRange" || "internalConfirmTimestampMsRange"
        startTimestampMsRange: [],
        internalConfirmTimestampMsRange: [],
      },
      orderBy: '',
      isManuallyUpdated: false,
    },
  },
  patch: {
    pending: false,
    data: null,
    error: null,
  },
  revertStatus: {
    pending: false,
    error: null,
  },
  delete: {
    pending: false,
    data: null,
    error: null,
  },
  returnDevice: {
    pending: false,
    data: null,
    error: null,
  },
  confirm: {
    pending: false,
    data: null,
    error: null,
  },
  editDone: {
    pending: false,
    data: null,
    error: null,
  },
  validReport: {
    pending: false,
    data: false,
    error: null,
  },
  sidePanel: {
    isOpen: false,
    ecgTest: null,
  },
  download: {
    pending: false,
    data: null,
    error: null,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Create
    case CREATE_ECG_TEST_REQUESTED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: true,
          error: null,
        },
      };
    case CREATE_ECG_TEST_SUCCEED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          data: action.data,
        },
      };
    case CREATE_ECG_TEST_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          error: action.error,
        },
      };
    // Fetch Ecg Test List
    case FETCH_ECG_TEST_LIST_REQUESTED:
      const { type, ...actionPayload } = action;

      const tableFilterOptions = state.fetch.tableFilterOptions;
      const fetchDataLength = state.fetch.data.length;
      const newFetchType = fetchDataLength > 0 ? SEARCH_MODE : FETCH_MODE;

      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true,
          error: null,
          tableFilterOptions: {
            ...tableFilterOptions,
            ...actionPayload,
            fetchType: newFetchType,
          },
        },
      };
    case FETCH_ECG_TEST_LIST_SUCCEED:
      return {
        ...state,
        fetch: {
          pending: false,
          data: action.data,
          error: null,
          tableFilterOptions: {
            ...state.fetch.tableFilterOptions,
            page: action.page,
            lastPage: action.lastPage,
            totalCount: action.totalCount,
          },
        },
      };
    case FETCH_ECG_TEST_LIST_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    // Patch
    case PATCH_ECG_TEST_REQUESTED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: true,
          error: null,
        },
      };
    case PATCH_ECG_TEST_SUCCEED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: false,
          data: {
            ...state.patch.data,
            ...action.data,
          },
          error: null,
        },
      };
    case PATCH_ECG_TEST_FAILED:
      return {
        ...state,
        patch: {
          pending: false,
          error: action.error,
        },
      };
    // Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
    case REVERT_STATUS_REQUESTED:
      return {
        ...state,
        revertStatus: {
          pending: true,
          error: null,
        },
      };
    case REVERT_STATUS_SUCCEED:
      return {
        ...state,
        patch: {
          ...state.patch,
          data: action.data,
        },
        fetch: {
          ...state.fetch,
          isUploadedToEmr: action.isUploadedToEmr,
        },
        revertStatus: {
          pending: false,
          error: null,
        },
      };
    case REVERT_STATUS_FAILED:
      return {
        ...state,
        revertStatus: {
          pending: false,
          error: action.error,
        },
      };
    // Delete
    case DELETE_ECG_TEST_REQUESTED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: true,
          error: null,
        },
      };
    case DELETE_ECG_TEST_SUCCEED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: false,
          data: action.data,
        },
      };
    case DELETE_ECG_TEST_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: false,
          error: action.error,
        },
      };
    // Return Device
    case ECG_TEST_RETURN_DEVICE_REQUESTED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_RETURN_DEVICE_SUCCEED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_RETURN_DEVICE_FAILED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: false,
          error: action.error,
        },
      };
    // Confirm Review
    case ECG_TEST_CONFIRM_REVIEW_REQUESTED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_SUCCEED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_FAILED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          error: action.error,
        },
      };
    // Edit Done Review
    case ECG_TEST_EDIT_DONE_REVIEW_REQUESTED:
      return {
        ...state,
        editDone: {
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_SUCCEED:
      return {
        ...state,
        editDone: {
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_FAILED:
      return {
        ...state,
        editDone: {
          pending: false,
          error: action.error,
        },
      };
    // get validReport latest value from ECG Test API
    case GET_VALID_REPORT_REQUESTED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: true,
          data: false,
          error: null,
        },
      };
    case GET_VALID_REPORT_SUCCEED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: action.data.latestReport.validReport,
        },
      };
    case GET_VALID_REPORT_FAILED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: false,
          error: action.error,
        },
      };
    case RESET_TABLE_FILTER_OPTIONS:
      return {
        ...state,
        fetch: {
          pending: true,
          data: [],
          error: null,
          tableFilterOptions: initialState.fetch.tableFilterOptions,
        },
      };
    case SHOW_SIDE_PANEL:
      const { ecgTestId } = action;
      let ecgTest: Nullable<EcgTest> = null;
      if (ecgTestId) {
        // 찾지 못했을 경우 undefined가 반환되는데, ecgTest의 타입은 Nullable이므로 null을 넣어준다.
        const foundEcgTest = state.fetch.data.find(
          (item) => item.tid === ecgTestId
        );
        ecgTest = foundEcgTest || null;
      }
      return {
        ...state,
        sidePanel: {
          isOpen: true,
          ecgTest: ecgTest,
        },
      };
    case HIDE_SIDE_PANEL:
      return {
        ...state,
        sidePanel: {
          isOpen: false,
          ecgTest: null,
        },
      };
    case SET_SIDE_PANEL_DATA:
      return {
        ...state,
        sidePanel: {
          ...state.sidePanel,
          ecgTest: action.ecgTest,
        },
      };
    // Get ECG Test Medical Fee Excel Download
    case GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_REQUESTED:
      return {
        ...state,
        download: {
          ...state.download,
          pending: true,
          error: null,
        },
      };
    case GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_SUCCEED:
      return {
        ...state,
        download: {
          ...state.download,
          pending: false,
        },
      };
    case GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_FAILED:
      return {
        ...state,
        download: {
          ...state.download,
          pending: false,
          error: action.payload.error,
        },
      };
    case SET_REPORT_DOWNLOAD_STATUS_REQUEST:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true,
        },
      };
    case SET_REPORT_DOWNLOAD_STATUS_SUCCEED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          data: action.data,
          pending: false,
          error: null,
        },
      };
    case SET_REPORT_DOWNLOAD_STATUS_FAILED:
      return {
        ...state,
        fetch: {
          ...action.data,
          pending: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}

// Action Creators
// Create
export function createEcgTestRequested({
  patientName,
  patientNumber,
  patientSex,
  patientPhoneNumber,
  patientBirth,
  prescriptionDuration,
  pacemaker,
  referredBy,
  note,
}) {
  return {
    type: CREATE_ECG_TEST_REQUESTED,
    patientName,
    patientNumber,
    patientSex,
    patientPhoneNumber,
    patientBirth,
    prescriptionDuration,
    pacemaker,
    referredBy,
    note,
  };
}
function createEcgTestSucceed(data) {
  return {
    type: CREATE_ECG_TEST_SUCCEED,
    data: data,
  };
}
function createEcgTestFailed(error) {
  return {
    type: CREATE_ECG_TEST_FAILED,
    error: error,
  };
}
// Fetch Ecg Test List
export function fetchEcgTestsRequested({
  ecgTestStatus,
  prescriptionDuration,
  page,
  orderBy,
  searchKeyword,
  selectedStartEndDateTime,
}: {
  ecgTestStatus?: number[];
  prescriptionDuration?: number[];
  page?: number;
  orderBy?: string;
  searchKeyword?: string;
  selectedStartEndDateTime?: {
    type: string;
    startTimestampMsRange: number[];
    internalConfirmTimestampMsRange: number[];
  };
} = {}) {
  return {
    type: FETCH_ECG_TEST_LIST_REQUESTED,
    ..._removeUndefinedProperties({
      ecgTestStatus,
      prescriptionDuration,
      page,
      orderBy,
      searchKeyword,
      selectedStartEndDateTime,
    }),
  };
  // inner utility function
  function _removeUndefinedProperties(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      // Ignore undefined values
      if (value === undefined) return acc;

      // Handle the special case for SELECTED_START_END_DATE_TIME
      if (key === SELECTED_START_END_DATE_TIME) {
        acc[key] = {
          type: value?.[START_END_DATE_TIME.TYPE],
          startTimestampMsRange:
            value?.[START_END_DATE_TIME.START_TIMESTAMP_MS_RANGE] || [],
          internalConfirmTimestampMsRange:
            value?.[START_END_DATE_TIME.INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE] ||
            [],
        };
        return acc;
      }

      // For all other cases, just assign the value
      acc[key] = value;
      return acc;
    }, {});
  }
}
export function fetchEcgTestsSucceed({ page, lastPage, totalCount, data }) {
  return {
    type: FETCH_ECG_TEST_LIST_SUCCEED,
    page,
    lastPage,
    totalCount,
    data,
  };
}
export function fetchEcgTestsFailed(error) {
  return { type: FETCH_ECG_TEST_LIST_FAILED, error };
}
// Patch
export function patchEcgTestRequested({ ecgTestId, form, callback }) {
  return {
    type: PATCH_ECG_TEST_REQUESTED,
    ecgTestId,
    form,
    callback,
  };
}
function patchEcgTestSucceed(data) {
  return {
    type: PATCH_ECG_TEST_SUCCEED,
    data,
  };
}
function patchEcgTestFailed(error) {
  return {
    type: PATCH_ECG_TEST_FAILED,
    error,
  };
}
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
export function revertStatusRequested({ ecgTestId, callback }) {
  return {
    type: REVERT_STATUS_REQUESTED,
    ecgTestId,
    callback,
  };
}
function revertStatusSucceed(data) {
  return {
    type: REVERT_STATUS_SUCCEED,
    data,
  };
}
function revertStatusFailed(error) {
  return {
    type: REVERT_STATUS_FAILED,
    error: error,
  };
}
// Delete
export function deleteEcgTestRequested({ ecgTestId }) {
  return {
    type: DELETE_ECG_TEST_REQUESTED,
    ecgTestId,
  };
}
export function deleteEcgTestSucceed(data) {
  return {
    type: DELETE_ECG_TEST_SUCCEED,
    data,
  };
}
export function deleteEcgTestFailed(error) {
  return {
    type: DELETE_ECG_TEST_FAILED,
    error,
  };
}
// Return Device
export function ecgTestReturnDeviceRequested({ ecgTestId }) {
  return {
    type: ECG_TEST_RETURN_DEVICE_REQUESTED,
    ecgTestId,
  };
}
export function ecgTestReturnDeviceSucceed(data) {
  return {
    type: ECG_TEST_RETURN_DEVICE_SUCCEED,
    data,
  };
}
export function ecgTestReturnDeviceFailed(error) {
  return {
    type: ECG_TEST_RETURN_DEVICE_FAILED,
    error,
  };
}
// Confirm Review
export function confirmEcgTestReviewRequested({
  ecgTestId,
  reGenerateReport,
  callback,
}) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_REQUESTED,
    ecgTestId,
    reGenerateReport,
    callback,
  };
}
function confirmEcgTestReviewSucceed(data) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_SUCCEED,
    data,
  };
}
function confirmEcgTestReviewFailed(error) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_FAILED,
    error,
  };
}
// Edit Done Review
export function editDoneEcgTestReviewRequested({ ecgTestId }) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_REQUESTED, ecgTestId };
}
function editDoneEcgTestReviewSucceed(data) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_SUCCEED, data };
}
function editDoneEcgTestReviewFailed(error) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_FAILED, error };
}
// get validReport latest value from ECG Test API
export function getValidReportRequested({ ecgTestId }) {
  return {
    type: GET_VALID_REPORT_REQUESTED,
    ecgTestId,
  };
}
function getValidReportSucceed(data) {
  return {
    type: GET_VALID_REPORT_SUCCEED,
    data,
  };
}
function getValidReportFailed(error) {
  return { type: GET_VALID_REPORT_FAILED, error: error };
}

// reset table filter options
export function resetTableFilterOptions() {
  return {
    type: RESET_TABLE_FILTER_OPTIONS,
  };
}

// sidePanel actions
export function showSidePanel({ ecgTestId }) {
  return { type: SHOW_SIDE_PANEL, ecgTestId };
}
export function hideSidePanel() {
  return { type: HIDE_SIDE_PANEL };
}
function _setSidePanelData({ ecgTest }) {
  return { type: SET_SIDE_PANEL_DATA, ecgTest };
}

// Get ECG Test Medical Fee Excel Download
export function getEcgTestMedicalFeeExcelDownloadRequested(
  progressCallback: (percentCompleted: number) => void,
  callback: (status: number, data: any, headers: any) => void
) {
  return {
    type: GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_REQUESTED,
    payload: {
      progressCallback,
      callback,
    },
  };
}
function getEcgTestMedicalFeeExcelDownloadSucceed() {
  return {
    type: GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_SUCCEED,
    payload: {},
  };
}
function getEcgTestListMedicalFeeExcelDownloadFailed(error) {
  return {
    type: GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_FAILED,
    payload: { error },
  };
}

// Post Parcel
export function postParcelRequested({ body, callback }) {
  return {
    type: POST_PARCEL_REQUESTED,
    body,
    callback,
  };
}
function postParcelSucceed() {
  return {
    type: POST_PARCEL_SUCCEED,
  };
}
function postParcelFailed(error) {
  return {
    type: POST_PARCEL_FAILED,
    error,
  };
}
// Patch Parcel
export function patchParcelRequested({ parcelId, body, callback }) {
  return {
    type: PATCH_PARCEL_REQUESTED,
    parcelId,
    body,
    callback,
  };
}
function patchParcelSucceed() {
  return {
    type: PATCH_PARCEL_SUCCEED,
  };
}
function patchParcelFailed(error) {
  return {
    type: PATCH_PARCEL_FAILED,
    error,
  };
}

export function setReportDownloadStatusCheckRequested(ecgTestId, body) {
  return { type: SET_REPORT_DOWNLOAD_STATUS_REQUEST, ecgTestId, body };
}
export function setReportDownloadStatusCheckSucceed(data) {
  return { type: SET_REPORT_DOWNLOAD_STATUS_SUCCEED, data };
}
export function setReportDownloadStatusCheckFailed(error) {
  return { type: SET_REPORT_DOWNLOAD_STATUS_FAILED, error };
}

// Saga functions
function* _createEcgTest(action) {
  try {
    const {
      patientName,
      patientNumber,
      patientSex,
      patientPhoneNumber,
      patientBirth,
      prescriptionDuration,
      referredBy,
      confirmedBy,
      note,
      pacemaker,
      callback,
    } = action;

    const { data } = yield call(ApiManager.createEcgTest, {
      patientName: formatPatientName(patientName),
      patientNumber,
      patientSex,
      patientPhoneNumber,
      patientBirth: formatBirthDate(patientBirth),
      prescriptionDuration,
      referredBy,
      confirmedBy,
      note,
      pacemaker,
      callback,
    });

    yield put(createEcgTestSucceed(data));
  } catch (error) {
    console.error(error);
    yield put(createEcgTestFailed(error));
  }
}

function* _fetchEcgTestList(action) {
  try {
    const {
      ecgTestStatus,
      prescriptionDuration,
      page,
      orderBy,
      searchKeyword,
      selectedStartEndDateTime,
    } = yield select(selectTableFilterOptions);

    let params = buildBaseParams({
      ecgTestStatus: combineValuesByKeys({
        statusArray: TEST_STATUS_ARRAY,
        targetStatusArray: ecgTestStatus,
      }),
      page,
    });
    params = addPrescriptionDuration({ params, prescriptionDuration });
    params = addOrderBy({ params, orderBy });
    params = addSearchKeyword({ params, searchKeyword });
    params = addSelectedStartEndDateTime({ params, selectedStartEndDateTime });
    const { data } = yield call(ApiManager.readEcgTests, {
      params,
      callback: undefined,
    });

    const { count, results } = data;
    const pageSize = 100;
    const lastPage = Math.ceil(count / pageSize);
    yield put(
      fetchEcgTestsSucceed({
        page,
        lastPage,
        totalCount: count,
        data: results,
      })
    );

    // fetchEcgTests 성공시 url 업데이트
    updateUrlWithParams({
      searchKeyword,
      ecgTestStatus: combineValuesByKeys({
        statusArray: TEST_STATUS_ARRAY,
        targetStatusArray: ecgTestStatus,
      }),
      prescriptionDuration,
      orderBy,
      page,
      selectedStartEndDateTime,
    });
  } catch (error) {
    console.error(error);
    yield put(fetchEcgTestsFailed(error));
  }
}

function* _patchEcgTest(action) {
  try {
    const { isOpen } = yield select(selectSidePanel);

    const { ecgTestId, form, callback } = action;

    const formattedForm = {
      ...form,
      patientName: formatPatientName(form.patientName),
      patientBirth: formatBirthDate(form.patientBirth),
    };

    const { data } = yield call(ApiManager.patchEcgTest, {
      ecgTestId,
      body: formattedForm,
      callback,
    });

    if (isOpen) yield put(_setSidePanelData({ ecgTest: data.result }));
    yield put(patchEcgTestSucceed(data.result));
    yield put(
      getEcgTestSucceed({
        ...data.result,
        latestReport: data.result.latestReport,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(patchEcgTestFailed(error));
  }
}

function* _revertStatus(action) {
  try {
    const { ecgTestId, callback } = action;
    const { data } = yield call(ApiManager.patchRevertStatus, {
      ecgTestId,
      callback,
    });
    yield put(getEcgTestSucceed({ ...data.result, isUploadedToEmr: false }));
    yield put(revertStatusSucceed({ ...data.result, isUploadedToEmr: false }));
  } catch (error) {
    console.error(error);
    yield put(revertStatusFailed(error));
  }
}

function* _deleteEcgTest(action) {
  try {
    const { ecgTestId } = action;
    const { data } = yield call(ApiManager.deleteEcgTest, {
      ecgTestId,
    });

    yield put(deleteEcgTestSucceed(data));
  } catch (error) {
    console.error(error);
    yield put(deleteEcgTestFailed(error));
  }
}

function* _ecgTestReturnDevice(action) {
  try {
    const { ecgTestId } = action;
    const { data } = yield call(ApiManager.ecgTestReturnDevice, { ecgTestId });

    yield put(ecgTestReturnDeviceSucceed(data));
  } catch (error) {
    console.error(error);
    yield put(ecgTestReturnDeviceFailed(error));
  }
}

function* _ecgTestConfirmReview(action) {
  try {
    const { ecgTestId } = action;
    const { data } = yield call(ApiManager.ecgTestConfirmReview, { ecgTestId });

    yield put(confirmEcgTestReviewSucceed(data));
  } catch (error) {
    console.error(error);
    yield put(confirmEcgTestReviewFailed(error));
  }
}

function* _ecgTestEditDoneReview(action) {
  try {
    const { ecgTestId } = action;
    const { data: editDoneData } = yield call(
      ApiManager.ecgTestEditDoneReview,
      { ecgTestId }
    );
    const { result } = editDoneData;

    yield put(editDoneEcgTestReviewSucceed(result));
  } catch (error) {
    console.error(error);
    yield put(editDoneEcgTestReviewFailed(error));
  }
}

function* _getValidReport(action) {
  try {
    const { ecgTestId } = action;
    const { data } = yield call(ApiManager.readEcgTest, {
      ecgTestId,
    });

    yield put(getValidReportSucceed(data.result));
  } catch (error) {
    console.error(error);
    yield put(getValidReportFailed(error));
  }
}

function* _getEcgTestMedicalFeeExcelDownload(action) {
  try {
    const {
      payload: { progressCallback, callback },
    } = action;

    const { hid } = yield select(selectUserHospital);
    const {
      ecgTestStatus,
      prescriptionDuration,
      searchKeyword,
      selectedStartEndDateTime,
    } = yield select(selectTableFilterOptions);

    const params = {
      version: 2,
      hid,
      prescriptionDuration,
      testStatus: combineValuesByKeys({
        statusArray: TEST_STATUS_ARRAY,
        targetStatusArray: ecgTestStatus,
      }),
      ...addSearchKeyword({ params: {}, searchKeyword }),
      ...addSelectedStartEndDateTime({ params: {}, selectedStartEndDateTime }),
    };

    yield call(
      ApiManager.getEcgTestsMedicalFeeExcelDownloadUrl,
      params,
      progressCallback,
      callback
    );

    yield put(getEcgTestMedicalFeeExcelDownloadSucceed());
  } catch (error) {
    yield put(getEcgTestListMedicalFeeExcelDownloadFailed(error));
  }
}

// Parcel
function* _postParcel(action) {
  try {
    const { body, callback } = action;
    yield call(ApiManager.postParcel, body);

    yield put(postParcelSucceed());
    yield put(fetchEcgTestsRequested());

    yield take(FETCH_ECG_TEST_LIST_SUCCEED);
    typeof callback === 'function' && callback();
  } catch (error) {
    console.error(error);
    yield put(postParcelFailed(error));
  }
}
function* _patchParcel(action) {
  try {
    const { parcelId, body, callback } = action;
    yield call(ApiManager.patchParcelById, parcelId, body);

    yield put(patchParcelSucceed());
    yield put(fetchEcgTestsRequested());

    yield take(FETCH_ECG_TEST_LIST_SUCCEED);
    typeof callback === 'function' && callback();
  } catch (error) {
    console.error(error);
    yield put(patchParcelFailed(error));
  }
}

function* _setReportDownloadStatusCheckRequested(action) {
  try {
    const { ecgTestId, body } = action ?? {};
    const { data } = yield call(ApiManager.patchReportUploadStatusById, {
      ecgTestId,
      body,
    });
    // 검사목록의 emr upload status는 ecgTest.fetch 데이터에서 확인
    const { isUploadedToEmr } = data.result;
    const targetFetchData = yield select(selectFetchData);
    if (targetFetchData) {
      const updatedData = targetFetchData.data.map((item) => {
        if (item.tid === ecgTestId) {
          return {
            ...item,
            isUploadedToEmr: isUploadedToEmr,
            emrUploadedBy: data.result.emrUploadedBy,
            emrUploadedDatetime: data.result.emrUploadedDatetime,
          };
        }
        return item;
      });
      yield put(setReportDownloadStatusCheckSucceed(updatedData));
    }
    yield put(getEcgTestSucceed({ ...data.result, isUploadedToEmr }));
  } catch (error) {
    console.error(error);
    yield put(setReportDownloadStatusCheckFailed(error));
  }
}

// Saga
export function* saga() {
  // Ecg Test List Read
  yield debounce(200, FETCH_ECG_TEST_LIST_REQUESTED, _fetchEcgTestList);
  yield takeLatest(
    GET_ECG_TEST_MEDICAL_FEE_EXCEL_DOWNLOAD_REQUESTED,
    _getEcgTestMedicalFeeExcelDownload
  );
  // Ecg Test Create, Patch, Delete
  yield takeLatest(CREATE_ECG_TEST_REQUESTED, _createEcgTest);
  yield takeLatest(PATCH_ECG_TEST_REQUESTED, _patchEcgTest);
  yield takeLatest(DELETE_ECG_TEST_REQUESTED, _deleteEcgTest);
  // ecgTestStatus, cloudStatus
  yield takeLatest(REVERT_STATUS_REQUESTED, _revertStatus);
  yield takeLatest(ECG_TEST_CONFIRM_REVIEW_REQUESTED, _ecgTestConfirmReview);
  yield takeLatest(ECG_TEST_EDIT_DONE_REVIEW_REQUESTED, _ecgTestEditDoneReview);
  // fetch tableList when set tableFilterOptions
  yield takeLatest(RESET_TABLE_FILTER_OPTIONS, _fetchEcgTestList);
  // parcel
  yield takeLatest(POST_PARCEL_REQUESTED, _postParcel);
  yield takeLatest(PATCH_PARCEL_REQUESTED, _patchParcel);
  // etc
  yield takeLatest(GET_VALID_REPORT_REQUESTED, _getValidReport);
  yield takeLatest(ECG_TEST_RETURN_DEVICE_REQUESTED, _ecgTestReturnDevice);
  yield takeLatest(
    SET_REPORT_DOWNLOAD_STATUS_REQUEST,
    _setReportDownloadStatusCheckRequested
  );
}
