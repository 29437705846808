import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';

import DateUtil from 'util/DateUtil';
import NumberUtil from 'util/NumberUtil';

import DialogRowItem from 'component/ui/dialog/DialogRowItem';
import TextInput from 'component/ui/input/TextInput';

import { PARCEL_STATUS } from './ParcelConst';

const { formatDateOnly, getUserLocationTime } = DateUtil;
const { getPhoneNumberFormatByPaste, getPhoneNumberFormat } = NumberUtil;

function Content({
  ecgTest,
  formData: { senderName, senderPhoneNumber, address1, address2, pickUpDate },
  isEditable,
  onChangeInput,
}) {
  function onChangePhoneNumber(text, { nativeEvent: { inputType } }) {
    const regex = /^[0-9\b -]{0,13}$/;
    if (!regex.test(text)) return;

    const resultText =
      inputType === 'insertFromPaste'
        ? getPhoneNumberFormatByPaste(text)
        : getPhoneNumberFormat(text);

    onChangeInput(resultText, 'senderPhoneNumber');
  }

  return (
    <DialogContentContainer>
      <FormInput
        label="보내는분"
        placeholder="이름"
        isEditable={isEditable}
        value={senderName}
        onChange={(value) => onChangeInput(value, 'senderName')}
      />

      <FormInput
        label="전화번호"
        placeholder={"'-' 없이 전화번호 입력"}
        isEditable={isEditable}
        value={senderPhoneNumber}
        onChange={onChangePhoneNumber}
      />

      <AddressFormInput
        address1={address1}
        address2={address2}
        isEditable={isEditable}
        onChangeInput={onChangeInput}
      />

      <DatePickerInput
        isEditable={isEditable}
        pickUpDate={pickUpDate}
        ecgTest={ecgTest}
        onChangeInput={onChangeInput}
      />

      <StyledDialogRowItem label={'검사 기간'}>
        <LabelText>
          {ecgTest.startDatetime && ecgTest.estimatedEndDatetime
            ? `${formatDateOnly(ecgTest.startDatetime)} ~ ${formatDateOnly(
                ecgTest.estimatedEndDatetime
              )}`
            : '-'}
        </LabelText>
      </StyledDialogRowItem>
    </DialogContentContainer>
  );
}

function FormInput({ label, value, placeholder, isEditable, onChange }) {
  if (!isEditable) {
    return <Label label={label} value={value} />;
  }

  return (
    <StyledDialogRowItem label={label} required>
      <StyledTextInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputType="text"
      />
    </StyledDialogRowItem>
  );
}

function AddressFormInput({ address1, address2, isEditable, onChangeInput }) {
  if (!isEditable) {
    return (
      <Label label="주소" height={'auto'} value={`${address1},${address2}`} />
    );
  }

  return (
    <StyledDialogRowItem height={70} labelAlign={'start'} label="주소" required>
      <AddressInputWrapper>
        <StyledTextInput
          placeholder="주소"
          value={address1}
          onChange={(value) => onChangeInput(value, 'address1')}
        />
        <StyledTextInput
          placeholder="상세 주소"
          value={address2}
          onChange={(value) => onChangeInput(value, 'address2')}
        />
      </AddressInputWrapper>
    </StyledDialogRowItem>
  );
}

function DatePickerInput({ isEditable, pickUpDate, ecgTest, onChangeInput }) {
  if (!isEditable) {
    const isCanceledStatus =
      ecgTest.parcel.status === PARCEL_STATUS.CANCELED.value;
    if (isCanceledStatus) return null;
    return <Label label={'택배 수거일'} value={pickUpDate} />;
  }

  return (
    <StyledDialogRowItem label={'택배 수거일'} required>
      <DatePickerWrapper>
        <ReactDatePicker
          // readOnly={!isEditable}
          dateFormat="yyyy-MM-dd"
          dateFormatCalendar="yyyy년 L월"
          formatWeekDay={formatWeekDay}
          popperPlacement="bottom"
          customInput={<StyledTextInput value={pickUpDate} />}
          minDate={
            ecgTest.estimatedEndDatetime
              ? new Date(ecgTest.estimatedEndDatetime)
              : getUserLocationTime()
          }
          selected={new Date(pickUpDate)}
          onChange={(value) => {
            onChangeInput(formatDateOnly(value), 'pickUpDate');
          }}
          disabledKeyboardNavigation
        />
      </DatePickerWrapper>
    </StyledDialogRowItem>
  );
}

function Label({ label, height, value }) {
  return (
    <StyledDialogRowItem label={label} height={height}>
      <LabelText>{value}</LabelText>
    </StyledDialogRowItem>
  );
}

function formatWeekDay(dayOfWeekLabel) {
  return dayOfWeekLabel.toString().charAt(0);
}

const DialogContentContainer = styled.div`
  margin: 8px 0px 0px;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.color.COOL_GRAY_40}`};
  input {
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
`;

const StyledDialogRowItem = styled(DialogRowItem)`
  margin: 0 !important;
  padding: 4px 0px;
  box-sizing: border-box;
  height: ${({ height }) => height || '40px'};
  > div {
    color: ${({ theme }) => theme.color.COOL_GRAY_70};
  }
`;

const LabelText = styled.div`
  min-height: 32px;
  word-break: break-all;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const AddressInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const DatePickerWrapper = styled.div`
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 16px;
    color: ${({ theme }) => theme.color.WHITE};
    background-color: ${({ theme }) => theme.color.BLUE_70};
  }
  .react-datepicker__day-name {
    font-weight: 500;
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.color.COOL_GRAY_80};
  }
  .react-datepicker__current-month {
    font-weight: 500;
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
`;

export default Content;
