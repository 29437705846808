// REFERENCE :  https://devtrium.com/posts/how-keyboard-shortcut
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export const keyboardEventInterfaceMap = {
  key: 'key',
  code: 'code',
};

/**
 *
 * @param {string} eventType  keydown, keyup, ...etc
 * @param {string[]} keys
 * @param {func} callback  callbackFunction
 * @param {string} keyboardEventInterface key, keyCode, code
 * @param {*} node
 */

const useKeyEventListener = (
  eventType,
  keys,
  callback,
  keyboardEventInterface = keyboardEventInterfaceMap.key,
  node = null
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event) => {
      // check if one of the key is part of the ones we want
      if (keys.some((key) => event[keyboardEventInterface] === key)) {
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode && targetNode.addEventListener(eventType, handleKeyPress);

    // remove the event listener
    return () =>
      targetNode && targetNode.removeEventListener(eventType, handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useKeyEventListener;
