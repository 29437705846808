import { call, put, takeLatest, select, debounce } from 'redux-saga/effects';

import Const, {
  START_END_DATE_TIME,
  TEST_STATUS_ARRAY as _TEST_STATUS_ARRAY,
} from 'constant/Const';

import {
  addOrderBy,
  addPrescriptionDuration,
  addSearchKeyword,
  addSelectedStartEndDateTime,
  buildBaseParams,
  combineValuesByKeys,
} from 'util/EcgTestListUtil';

import ApiManager from 'network/ApiManager/ApiManager';

import { SELECTED_START_END_DATE_TIME } from './ecgTestList/ecgTestListDuckType';
import { selectUserHospital } from './authDuck';

const {
  FETCH_TYPE: { SEARCH_MODE, FETCH_MODE },
} = Const;
const TEST_STATUS_ARRAY = _TEST_STATUS_ARRAY;

// Selector
// Actions
// InitialState
// Reducer
// Action Creators
// Saga functions
// Saga

// Selector
export const selectPaymentTableFilterOptions = ({ paymentReducer }) =>
  paymentReducer.fetch.tableFilterOptions;
export const selectPaymentDownloadState = ({ paymentReducer }) =>
  paymentReducer.download;

// Actions
// Fetch ECG Test List
const FETCH_ECG_TEST_LIST_REQUESTED = 'payment/FETCH_ECG_TEST_LIST_REQUESTED';
const FETCH_ECG_TEST_LIST_SUCCEED = 'payment/FETCH_ECG_TEST_LIST_SUCCEED';
const FETCH_ECG_TEST_LIST_FAILED = 'payment/FETCH_ECG_TEST_LIST_FAILED';
// Get ECG Test List Excel Download
const GET_PAYMENT_DOWNLOAD_REQUESTED = 'payment/GET_PAYMENT_DOWNLOAD_REQUESTED';
const GET_PAYMENT_DOWNLOAD_SUCCEED = 'payment/GET_PAYMENT_DOWNLOAD_SUCCEED';
const GET_PAYMENT_DOWNLOAD_FAILED = 'payment/GET_PAYMENT_DOWNLOAD_FAILED';

// InitialState
const initialState = {
  fetch: {
    pending: false,
    data: [],
    error: null,
    // 검색, 필터링, 페이지 등의 옵션 값
    tableFilterOptions: {
      fetchType: FETCH_MODE, // 'fetchMode' | 'searchMode',
      firstPage: 1,
      page: 1,
      lastPage: null,
      totalCount: null,
      searchKeyword: '',
      ecgTestStatus: [],
      prescriptionDuration: [],
      selectedStartEndDateTime: {
        type: START_END_DATE_TIME.START_TIMESTAMP_MS_RANGE, //"startTimestampMsRange" || "internalConfirmTimestampMsRange"
        startTimestampMsRange: [],
        internalConfirmTimestampMsRange: [],
      },
      orderBy: '',
      isManuallyUpdated: false,
    },
  },
  download: {
    pending: false,
    data: null,
    error: null,
  },
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Fetch Ecg Test List
    case FETCH_ECG_TEST_LIST_REQUESTED:
      const { type, ...actionPayload } = action;

      const tableFilterOptions = state.fetch.tableFilterOptions;
      const fetchDataLength = state.fetch.data.length;
      const newFetchType = fetchDataLength > 0 ? SEARCH_MODE : FETCH_MODE;

      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: true,
          error: null,
          tableFilterOptions: {
            ...tableFilterOptions,
            ...actionPayload,
            fetchType: newFetchType,
          },
        },
      };
    case FETCH_ECG_TEST_LIST_SUCCEED:
      return {
        ...state,
        fetch: {
          pending: false,
          data: action.data,
          error: null,
          tableFilterOptions: {
            ...state.fetch.tableFilterOptions,
            page: action.page,
            lastPage: action.lastPage,
            totalCount: action.totalCount,
          },
        },
      };
    case FETCH_ECG_TEST_LIST_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };

    // Get Payment Excel Download
    case GET_PAYMENT_DOWNLOAD_REQUESTED:
      return {
        ...state,
        download: {
          ...state.download,
          pending: true,
          error: null,
        },
      };
    case GET_PAYMENT_DOWNLOAD_SUCCEED:
      return {
        ...state,
        download: {
          ...state.download,
          pending: false,
        },
      };
    case GET_PAYMENT_DOWNLOAD_FAILED:
      return {
        ...state,
        download: {
          ...state.download,
          pending: false,
          error: action.payload.error,
        },
      };

    default:
      return state;
  }
}

// Action Creators
// Fetch Ecg Test List
export function fetchEcgTestsRequested({
  ecgTestStatus,
  prescriptionDuration,
  page,
  orderBy,
  searchKeyword,
  selectedStartEndDateTime,
}) {
  return {
    type: FETCH_ECG_TEST_LIST_REQUESTED,
    ..._removeUndefinedProperties({
      ecgTestStatus,
      prescriptionDuration,
      page,
      orderBy,
      searchKeyword,
      selectedStartEndDateTime,
    }),
  };
  // inner utility function
  function _removeUndefinedProperties(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      // Ignore undefined values
      if (value === undefined) return acc;

      // Handle the special case for SELECTED_START_END_DATE_TIME
      if (key === SELECTED_START_END_DATE_TIME) {
        acc[key] = {
          type: value?.[START_END_DATE_TIME.TYPE],
          startTimestampMsRange:
            value?.[START_END_DATE_TIME.START_TIMESTAMP_MS_RANGE] || [],
          internalConfirmTimestampMsRange:
            value?.[START_END_DATE_TIME.INTERNAL_CONFIRM_TIMESTAMP_MS_RANGE] ||
            [],
        };
        return acc;
      }

      // For all other cases, just assign the value
      acc[key] = value;
      return acc;
    }, {});
  }
}
export function fetchEcgTestsSucceed({ page, lastPage, totalCount, data }) {
  return {
    type: FETCH_ECG_TEST_LIST_SUCCEED,
    page,
    lastPage,
    totalCount,
    data,
  };
}
export function fetchEcgTestsFailed(error) {
  return { type: FETCH_ECG_TEST_LIST_FAILED, error };
}

// Get Payment Excel Download
export function getPaymentDownloadRequested(progressCallback, callback) {
  return {
    type: GET_PAYMENT_DOWNLOAD_REQUESTED,
    payload: {
      progressCallback,
      callback,
    },
  };
}
function getPaymentDownloadSucceed() {
  return {
    type: GET_PAYMENT_DOWNLOAD_SUCCEED,
    payload: {},
  };
}
function getPaymentDownloadFailed(error) {
  return {
    type: GET_PAYMENT_DOWNLOAD_FAILED,
    payload: { error },
  };
}

// Saga functions
function* _fetchEcgTestList() {
  try {
    const {
      ecgTestStatus,
      prescriptionDuration,
      page,
      orderBy,
      searchKeyword,
      selectedStartEndDateTime,
    } = yield select(selectPaymentTableFilterOptions);

    let params = buildBaseParams({
      ecgTestStatus: combineValuesByKeys({
        statusArray: TEST_STATUS_ARRAY,
        targetStatusArray: ecgTestStatus,
      }),
      page,
    });
    params = addPrescriptionDuration({ params, prescriptionDuration });
    params = addOrderBy({ params, orderBy });
    params = addSearchKeyword({ params, searchKeyword });
    params = addSelectedStartEndDateTime({ params, selectedStartEndDateTime });

    const { data } = yield call(ApiManager.readEcgTests, {
      params,
      callback: undefined,
    });

    const { count, results } = data;
    const pageSize = 100;
    const lastPage = Math.ceil(count / pageSize);

    yield put(
      fetchEcgTestsSucceed({
        page,
        lastPage,
        totalCount: count,
        data: results,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(fetchEcgTestsFailed(error));
  }
}

function* _getPaymentExcelDownload(action) {
  try {
    const {
      payload: { progressCallback, callback },
    } = action;

    const { hid } = yield select(selectUserHospital);
    const {
      ecgTestStatus,
      prescriptionDuration,
      searchKeyword,
      selectedStartEndDateTime,
    } = yield select(selectPaymentTableFilterOptions);

    const params = {
      version: 2,
      hid,
      prescriptionDuration,
      testStatus: combineValuesByKeys({
        statusArray: TEST_STATUS_ARRAY,
        targetStatusArray: ecgTestStatus,
      }),
      ...addSearchKeyword({ params: {}, searchKeyword }),
      ...addSelectedStartEndDateTime({ params: {}, selectedStartEndDateTime }),
    };

    yield call(
      ApiManager.getEcgTestsPaymentExcelDownloadUrl,
      params,
      progressCallback,
      callback
    );

    yield put(getPaymentDownloadSucceed());
  } catch (error) {
    console.error(error);
    action.payload.callback();
    yield put(getPaymentDownloadFailed(error));
  }
}

// Saga
export function* saga() {
  // Ecg Test List Read
  yield debounce(200, FETCH_ECG_TEST_LIST_REQUESTED, _fetchEcgTestList);
  yield takeLatest(GET_PAYMENT_DOWNLOAD_REQUESTED, _getPaymentExcelDownload);
}
