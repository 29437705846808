import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { getReportSectionValidationMap } from 'util/EventConstUtil';

import usePrevious from 'component/hook/usePrevious';

import {
  getAllStatisticsRequest,
  requestPrintReportRequested,
} from 'redux/duck/testResultDuck';
import {
  hideDialog,
  showDialog as showDialogRequested,
} from 'redux/duck/dialogDuck';

import useShallowEqualSelector from './useShallowEqualSelector';

/**
 * 서버로부터 ecgStatistics와 reportStatistics를 불러와 두개를 비교하여 validation하거나 리포트를 생성하는데 사용하는 커스텀 훅입니다.
 *
 * @param {string} ecgTestId report 생성시 필요한 tid
 * @param {{isMainEcgTest: boolean, isMedicalCheckUpTest: boolean}} ecgTestInfo
 *        isMainEcgTest: 병원에서 patch를 크레들로 올린 경우, pa임상용 데이터를 크레들로 올린경우 -> true/ pa임상용 daily 데이터 -> false
 *        isMedicalCheckUpTest: 건강검진 여부(수검자)
 * @param {} callback hook이 끝나고 나서 실행할 callback function
 *
 * validateStt는 현재 훅의 trigger입니다.
 * validateStt의 param에 boolean값이 들어갑니다. param(isShowDialog)이 true인 경우만 dialog를 보여줍니다.(리포트생성을 위해 검증하는 경우)
 * result에 report그룹탭에 있는 항목들의 validation 결과값이 들어갑니다.
 * isAllValidated는 createReport나 validateReport를 하기 위해 검증할 때 필요한 boolean값이 들어갑니다.
 * @returns {validateStt: function, result : {ADDITIONAL: boolean, AF: boolean, PAUSE:boolean, PTE: boolean, SUMMARY: boolean, SVE: boolean, SVT: boolean, VE: boolean, VT: boolean}, isAllValidated: boolean}
 */
function useValidateReportAndStatistics({
  ecgTestId,
  ecgTestInfo: { isMainEcgTest, isMedicalCheckUpTest },
  callback = () => {},
}) {
  const intl = useIntl();
  const dispatch = useDispatch();

  // Store State
  const allStatistics = useShallowEqualSelector(
    (state) => state.testResultReducer.allStatistics
  );

  // Dispatches
  const getAllStatistics = (_isShowDialog) => {
    setIsShowDialog(_isShowDialog);
    dispatch(getAllStatisticsRequest(ecgTestId));
  };
  const showDialog = (dialogKey, params, callback) => {
    dispatch(showDialogRequested(dialogKey, params, callback));
  };
  const handleHideDialog = (dialogKey) => {
    dispatch(hideDialog(dialogKey));
  };
  const requestPrintReport = (tid, request) => {
    dispatch(requestPrintReportRequested(tid, request));
  };

  const [isAllValidated, setIsAllValidated] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const prevAllStatisticsPending = usePrevious(allStatistics.pending);
  useEffect(() => {
    if (
      prevAllStatisticsPending &&
      !allStatistics.pending &&
      allStatistics.data
    ) {
      const { ecgStatistics, reportStatistics, afMinInfoList } =
        allStatistics.data;
      const reportSectionValidationMap = getReportSectionValidationMap(
        ecgStatistics,
        reportStatistics,
        afMinInfoList
      );

      if (isShowDialog) {
        let validationReportSection = Object.values(
          // # validationReportSection에 따른 동작
          //  1. validationReportSection 통과 시 리포트 생성
          //  2. validationReportSection 미통과 시 리포트 담지 않은 페이지가 있음 dialog 띄우기
          reportSectionValidationMap
        ).every(Boolean);

        // [#PA임상] sub Test(PA daily report = not main ecg test)를 생성할 때는 리포트 생성을 위한 validation을 하지 않고 리포트 생성
        const isPAClinicalSubTest = isMainEcgTest === false;
        const validationPassCase = isPAClinicalSubTest || isMedicalCheckUpTest;
        if (validationPassCase) {
          validationReportSection = true;
        }
        setIsAllValidated(validationReportSection);

        // 1. validationReportSection 통과 시 리포트 생성
        if (validationReportSection) {
          showDialog('ConfirmDialog', {
            title: intl.formatMessage({
              id: '99-AlertDialog-ReportAskGenerate-title',
              description: '리포트를 생성하시겠습니까?',
              defaultMessage: '리포트를 생성하시겠습니까?',
            }),
            message: intl.formatMessage(
              {
                id: '99-AlertDialog-ReportAskGenerate-message',
                description: '리포트 생성에 {minute}분 정도 소요됩니다.',
                defaultMessage: '리포트 생성에 {minute}분 정도 소요됩니다.',
              },
              { minute: '1~2' }
            ),
            cancelButtonText: intl.formatMessage({
              id: '99-AlertDialog-ReportAskGenerate-cancelButtonText-01',
              description: '닫기',
              defaultMessage: '닫기',
            }),
            confirmButtonText: intl.formatMessage({
              id: '99-AlertDialog-ReportAskGenerate-confirmButtonText-01',
              description: '리포트 생성하기',
              defaultMessage: '리포트 생성하기',
            }),
            onSubmit: () => {
              handleHideDialog('FinalConfirmReportDialog');
              requestPrintReport(ecgTestId);
            },
          });
          callback();
          setIsShowDialog(false);
          return;
        }

        // 2. validationReportSection 미통과 시 리포트 담지 않은 페이지가 있음 dialog 띄우기
        showDialog('AlertDialog', {
          title: intl.formatMessage({
            id: '99-AlertDialog-ReportValidError-title',
            description: '이벤트를 담지 않은 리포트 페이지가 있습니다.',
            defaultMessage: '이벤트를 담지 않은 리포트 페이지가 있습니다.',
          }),
          message: intl.formatMessage({
            id: '99-AlertDialog-ReportValidError-message',
            description:
              '각 클래스 마다 최소 1개 이상의 이벤트를 담아야 리포트를 생성할 수 있습니다.',
            defaultMessage:
              '각 클래스 마다 최소 1개 이상의 이벤트를 담아야 리포트를 생성할 수 있습니다.',
          }),
          confirmButtonText: intl.formatMessage({
            id: '99-AlertDialog-ReportValidError-confirmButtonText-01',
            description: '확인',
            defaultMessage: '확인',
          }),
        });
        callback();
        setIsShowDialog(false);
        return;
      } else {
        callback();
      }
    }
  }, [allStatistics.pending]);

  return {
    validateStt: getAllStatistics,
    isAllValidated,
    validateSttPending: allStatistics.pending,
  };
}

export default useValidateReportAndStatistics;
