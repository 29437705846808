import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import DropdownItem from 'component/ui/dropdown/DropdownItem';
import { showMenuAnim, hideMenuAnim } from 'component/ui/animation/Animation';
import { ReactComponent as DropdownArrowIcon } from 'static/icon/icon-dropdown-arrow.svg';

export const CLASS_NAME_OLD_DROPDOWN_ANCHOR = 'old-dropdown-anchor';
export const CLASS_NAME_OLD_DROPDOWN_ANCHOR_HOVER = 'old-dropdown-hover';
export const CLASS_NAME_OLD_DROPDOWN_ANCHOR_ACTIVE = 'old-dropdown-active';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  :hover {
    cursor: pointer;
  }
  z-index: 2;
  border-radius: 16px;
`;

const AnchorContainer = styled.div`
  min-width: 56px;
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.WHITE};
  color: ${(props) =>
    props.disabled
      ? props.theme.color.COOL_GRAY_50
      : props.theme.color.LIGHT_GREY_BB};
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.MEDIUM_LIGHT};
  :hover {
    ${(props) =>
      !props.disabled && `border-color: ${props.theme.color.PRIMARY_BLUE}`};
  }
  transition: border-color 0.2s ease-in-out;
  & > * {
    color: ${(props) =>
      props.disabled && `${props.theme.color.COOL_GRAY_50} !important`};
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`;

const PlaceholderText = styled.div`
  flex: 1;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.MEDIUM_DARK};
`;

const AnchorValueText = styled.div`
  flex: 1;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.theme.color.DARK};
`;

const ItemContainer = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  background-color: ${(props) => props.theme.color.WHITE};
  box-shadow: 0 8px 16px 0 rgba(48, 48, 48, 0.1);
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.visible ? showMenuAnim : hideMenuAnim)} 0.2s
    ease-in-out;
  -webkit-transition: -webkit-visibility 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
  ${(props) =>
    props.dropToTop
      ? `
                transform-origin: bottom;
                bottom: 40px;`
      : `
                transform-origin: top;
                top: 40px;
    `};
  padding: 8px 4px;
`;

function Dropdown(props) {
  const {
    placeholder,
    items,
    selectedItem,
    dropToTop,
    onChange,
    anchorContainerStyle,
    itemContainerStyle,
    itemComponent,
    disabled = false,
  } = props;

  const anchorRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [show, _setShow] = useState(false);
  const showStateRef = useRef(show);
  const setShow = (data) => {
    showStateRef.current = data;
    _setShow(data);
  };

  useEffect(() => {
    document.addEventListener('click', onMouseClick);

    return () => {
      document.removeEventListener('click', onMouseClick);
    };
  }, []);

  const onMouseClick = (event) => {
    if (
      showStateRef.current &&
      anchorRef.current &&
      anchorRef.current != event.target
    ) {
      setShow(false);
    }
  };

  return (
    <Wrapper>
      {/* Dropdown anchor */}
      <AnchorContainer
        disabled={disabled}
        ref={anchorRef}
        style={anchorContainerStyle}
        onClick={(event) => {
          event.stopPropagation();
          !disabled && setShow(!show);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
          setIsPressed(false);
        }}
        onMouseDown={() => !disabled && setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        className={`${CLASS_NAME_OLD_DROPDOWN_ANCHOR} ${
          isHovered ? CLASS_NAME_OLD_DROPDOWN_ANCHOR_HOVER : ''
        } ${
          isPressed || show ? CLASS_NAME_OLD_DROPDOWN_ANCHOR_ACTIVE : ''
        }`.trim()}>
        {selectedItem ? (
          <AnchorValueText>{selectedItem.label}</AnchorValueText>
        ) : (
          <PlaceholderText>{placeholder ?? ''}</PlaceholderText>
        )}
        <DropdownArrowIcon />
      </AnchorContainer>

      {/* Dropdown list */}
      <ItemContainer
        style={itemContainerStyle}
        visible={show}
        dropToTop={dropToTop}>
        {items.map((item, index) => {
          if (itemComponent) {
            return React.createElement(
              itemComponent,
              {
                key: item.key || item.label,
                style: item.style,
                textColor: item.textColor,
                title: item.label,
                disabled: item.disabled,
                onClick: () => {
                  onChange(item, index);
                },
              },
              null
            );
          }

          return (
            <DropdownItem
              key={item.key || item.label}
              style={item.style}
              textColor={item.textColor}
              title={item.label}
              disabled={item.disabled}
              onClick={() => {
                onChange(item, index);
              }}
            />
          );
        })}
      </ItemContainer>
    </Wrapper>
  );
}

export default Dropdown;
