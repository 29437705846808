import {
  useRef,
  useState,
  useEffect,
  CSSProperties,
  KeyboardEvent,
} from 'react';
import styled from 'styled-components';

import Const from 'constant/Const';

import DropdownItem from 'component/ui/dropdown/DropdownItem';
import { DropdownArrowIcon, CancelIcon } from 'component/ui/icons';

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;
const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: inherit;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  &::placeholder {
    font-size: 12px;
    line-height: 130%;
    color: ${(props) => props.theme.color.COOL_GRAY_70};
  }
`;

const AnchorContainer = styled.div<{
  disabled: boolean;
}>`
  min-width: 56px;
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.WHITE};
  color: ${(props) =>
    props.disabled
      ? props.theme.color.COOL_GRAY_50
      : props.theme.color.COOL_GRAY_40};
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.COOL_GRAY_40};
  :hover {
    ${(props) =>
      !props.disabled && `border-color: ${props.theme.color.BLUE_70}`};
    cursor: pointer;
  }

  & > * {
    color: ${(props) =>
      props.disabled && `${props.theme.color.COOL_GRAY_50} !important`};
  }

  & svg {
    width: 16px;
    height: 16px;
  }
`;

const ItemContainer = styled.div<{
  visible: boolean;
  dropToTop: boolean;
}>`
  position: absolute;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  background-color: ${(props) => props.theme.color.WHITE};
  box-shadow: 0 8px 16px 0 ${(props) => props.theme.color.COOL_GRAY_50};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }

  ${(props) =>
    props.dropToTop
      ? `
        transform-origin: bottom;
        bottom: 40px;`
      : `
        transform-origin: top;
        top: 40px;
    `};
  padding: 8px 4px;
`;

const IconContainer = styled.span`
  display: flex;
  margin-right: 10px;

  :hover {
    cursor: pointer;
  }
`;

interface Item {
  key?: string;
  label: string;
  value: string;
  textColor?: string;
  style?: CSSProperties;
  disabled?: boolean;
}

export interface SearchableDropdownProps {
  placeholder: string;
  items: Array<Item>;
  fixedItem: Item;
  onChange: (item, index: number) => void;
  dropToTop: boolean;
  disabled: boolean;
  itemContainerStyle?: CSSProperties;
  anchorContainerStyle?: CSSProperties;
  queryKey: string;
  selectedItem?: Item | null;
  style?: CSSProperties;
  dataTestId: string;
}

const SearchableDropdown = (props: SearchableDropdownProps) => {
  const {
    placeholder,
    items,
    fixedItem,
    onChange,
    dropToTop,
    disabled,
    itemContainerStyle,
    anchorContainerStyle,
    queryKey,
    selectedItem,
    dataTestId,
  } = props;

  const [query, setQuery] = useState(selectedItem?.label ?? '');
  const [show, _setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [filteredItems, setFilteredItems] = useState<Array<Item>>([]);

  const showStateRef = useRef(show);
  const anchorRef = useRef<HTMLInputElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const itemContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedItem) {
      setQuery(selectedItem.label);
    }
  }, [selectedItem]);

  useEffect(() => {
    const filterItems = () => {
      if (query === '') {
        setFilteredItems([...items, fixedItem]);
        onChange(null, 0);
        return;
      }

      const searchResult = items.filter((item) => {
        return item[queryKey].includes(query);
      });

      setFilteredItems([...searchResult, fixedItem]);
    };

    filterItems();
  }, [query]);

  useEffect(() => {
    if (show) {
      inputRef.current?.focus();
    }
  }, [show]);

  useEffect(() => {
    const onMouseClickCallback = (event) => {
      const isClickedOutSide =
        showStateRef.current &&
        anchorRef.current &&
        anchorRef.current !== event.target;

      if (isClickedOutSide) {
        setShow(false);
        setCurrentIndex(0);
      }
    };

    document.addEventListener('click', onMouseClickCallback);

    return () => {
      document.removeEventListener('click', onMouseClickCallback);
    };
  }, []);

  const setShow = (data) => {
    showStateRef.current = data;
    _setShow(data);
  };

  const onKeyDownCallback = (event: KeyboardEvent<HTMLDivElement>) => {
    const itemLength = filteredItems.length;
    const clamp = (num: number, min: number, max: number) =>
      Math.min(Math.max(num, min), max);

    switch (event.key) {
      case Const.KEY_MAP.ARROW_UP:
        setCurrentIndex((prevState) => clamp(prevState - 1, 0, itemLength));
        break;
      case Const.KEY_MAP.ARROW_DOWN:
        setCurrentIndex((prevState) => clamp(prevState + 1, 0, itemLength - 1));
        break;
      case Const.KEY_MAP.ENTER:
        onChange(filteredItems[currentIndex], currentIndex);
        break;
      default:
        break;
    }

    if (itemContainerRef.current) {
      itemContainerRef.current.children[currentIndex].scrollIntoView(true);
    }
  };

  return (
    <Wrapper data-testid={dataTestId}>
      <AnchorContainer
        onKeyDown={onKeyDownCallback}
        ref={anchorRef}
        style={anchorContainerStyle}
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          !disabled && setShow(!show);
        }}>
        <Input
          ref={inputRef}
          disabled={disabled}
          value={query}
          placeholder={placeholder}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
        />

        {query !== '' && !disabled && (
          <IconContainer
            onClick={(event) => {
              event.stopPropagation();
              setQuery('');
            }}>
            <CancelIcon />
          </IconContainer>
        )}

        <DropdownArrowIcon />
      </AnchorContainer>

      <ItemContainer
        ref={itemContainerRef}
        style={itemContainerStyle}
        visible={show}
        dropToTop={dropToTop}>
        {filteredItems.map((item, index) => {
          return (
            <DropdownItem
              currentIndex={currentIndex}
              index={index}
              key={item.key || item.label}
              style={item.style}
              textColor={item.textColor}
              title={item.label}
              disabled={item.disabled}
              onClick={() => {
                onChange(item, index);
              }}
            />
          );
        })}
      </ItemContainer>
    </Wrapper>
  );
};

export default SearchableDropdown;
