import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { authLogoutRequested } from 'redux/duck/authDuck';
import AlertExpireDialog from 'component/dialog/AlertExpireDialog';

function AlertExpireDialogContainer(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.open) {
      dispatch(authLogoutRequested());
    }
  }, []);

  return <AlertExpireDialog {...props} />;
}
export default AlertExpireDialogContainer;
