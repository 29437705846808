import { defineMessages, MessageDescriptor } from 'react-intl';

import { EVENT_CONST_TYPES } from './EventConst';

export type SortOption = {
  valueText: string;
  optionText: string;
  tooltipTitle: MessageDescriptor;
  queryOrderBy: string;
  sortFunctions?: { [key: string]: Function };
  ascending: boolean;
  isDefault: boolean;
};
export type SortOptionList = Array<SortOption>;

const BE_FIELD_HR = 'hr';
const BE_FIELD_R_R_INTERVAL = 'rr_interval';
const BE_FIELD_ONSET_MS = 'onset_ms';
const BE_FIELD_DURATION_MS = 'duration_ms';
const BE_FIELD_ONSET_WAVEFORM_INDEX = 'onset_waveform_index';
const BE_FIELD_LEN = 'len';
const BE_FIELD_HR_AVG = 'hr_avg';
const BE_FIELD_EVENT_TIME_MS = 'event_time_ms';
const BE_FIELD_CYCLE = 'cycle';
export const BE_FIELD_SIMILARITY = 'similarity';

export const SORT_FUNCTIONS = {
  [BE_FIELD_R_R_INTERVAL]: (a, b) => a.durationMs - b.durationMs,
  [`-${BE_FIELD_R_R_INTERVAL}`]: (a, b) => b.durationMs - a.durationMs,
  [BE_FIELD_ONSET_WAVEFORM_INDEX]: (a, b) => a.onsetMs - b.onsetMs,
  [`-${BE_FIELD_ONSET_WAVEFORM_INDEX}`]: (a, b) => b.onsetMs - a.onsetMs,
};

const HR_VALUE_TEXT = 'HR';
const RR_VALUE_TEXT = 'R-R';

export const TIME_VALUE_TEXT = 'Time';
const DURATION_VALUE_TEXT = 'Duration';
export const AVG_HR_VALUE_TEXT = 'Avg HR';
export const BEAT_VALUE_TEXT = 'Beat';
export const CYCLE_VALUE_TEXT = 'Cycle';
//
const LABEL_HR_FASTEST = `${HR_VALUE_TEXT} (Fastest)`; // HR (Fastest)
const LABEL_HR_SLOWEST = `${HR_VALUE_TEXT} (Slowest)`; // HR (Slowest)
const LABEL_R_R_SHORTEST = `${RR_VALUE_TEXT} (Shortest)`; // R-R (Shortest)
const LABEL_R_R_LONGEST = `${RR_VALUE_TEXT} (Longest)`; // R-R (Longest)
//
//
export const LABEL_TIME_OLDEST = `${TIME_VALUE_TEXT} (Oldest)`; // Time (Oldest)
const LABEL_DURATION_LONGEST = `${DURATION_VALUE_TEXT} (Longest)`; // Duration (Longest)
export const LABEL_AVG_HR_FASTEST = `${AVG_HR_VALUE_TEXT} (Fastest)`; // Avg HR (Fastest)
export const LABEL_BEAT_COUNT = `${BEAT_VALUE_TEXT} (Count)`; // Beat (Count)
export const LABEL_CYCLE_COUNT = `${CYCLE_VALUE_TEXT} (Count)`; // Cycle (Count)

const TOOLTIP_TITLE_MAP = defineMessages({
  [LABEL_HR_FASTEST]: {
    id: 'sortOrder-tooltip-title-hrShortest',
    description: 'HR 빠른 순으로 정렬됨 / Sorted by Fastest HR',
    defaultMessage: 'HR 빠른 순으로 정렬됨',
  },
  [LABEL_HR_SLOWEST]: {
    id: 'sortOrder-tooltip-title-hrLongest',
    description: 'HR 느린 순으로 정렬됨 / Sorted by Slowest HR',
    defaultMessage: 'HR 느린 순으로 정렬됨',
  },
  [LABEL_R_R_SHORTEST]: {
    id: 'sortOrder-tooltip-title-rRShortest',
    description: 'R-R 간격 좁은 순으로 정렬됨 / Sorted shortest to longest',
    defaultMessage: 'R-R 간격 좁은 순으로 정렬됨',
  },
  [LABEL_R_R_LONGEST]: {
    id: 'sortOrder-tooltip-title-rRLongest',
    description: 'R-R 간격 넓은 순으로 정렬됨 / Sorted longest to shortest',
    defaultMessage: 'R-R 간격 넓은 순으로 정렬됨',
  },
  [LABEL_TIME_OLDEST]: {
    id: 'sortOrder-tooltip-title-timeOldest',
    description: '시간 흐름 순으로 정렬됨 / Sorted oldest to newest',
    defaultMessage: '시간 흐름 순으로 정렬됨',
  },
  [LABEL_DURATION_LONGEST]: {
    id: 'sortOrder-tooltip-title-durationLongest',
    description: 'Duration 긴 순으로 정렬됨 / Sorted longest to shortest',
    defaultMessage: 'Duration 긴 순으로 정렬됨',
  },
  [LABEL_AVG_HR_FASTEST]: {
    id: 'sortOrder-tooltip-title-avgHrFastest',
    description: 'Avg HR 빠른 순을 정렬됨 / Sorted Fastest to slowest',
    defaultMessage: 'Avg HR 빠른 순을 정렬됨',
  },
  [LABEL_BEAT_COUNT]: {
    id: 'sortOrder-tooltip-title-beatCount',
    description: 'Beat 개수 많은 순으로 정렬됨 / Sorted by number of beats',
    defaultMessage: 'Beat 개수 많은 순으로 정렬됨',
  },
  [LABEL_CYCLE_COUNT]: {
    id: 'sortOrder-tooltip-title-cycleCount',
    description: 'Cycle 개수 많은 순으로 정렬됨 / Sorted by number of cycles',
    defaultMessage: 'Cycle 개수 많은 순으로 정렬됨',
  },
});

// :: 정렬 - HR (Fastest) ::
export const HR_SORT_DEFAULT: SortOption = {
  valueText: HR_VALUE_TEXT, // 'HR'
  optionText: LABEL_HR_FASTEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_HR_FASTEST],
  queryOrderBy: `-${BE_FIELD_HR}`,
  ascending: false,
  isDefault: false,
};
// :: 정렬 - HR (Slowest) ::
export const HR_SORT_OPTION_LIST: SortOptionList = [
  HR_SORT_DEFAULT,
  {
    valueText: HR_VALUE_TEXT, // 'HR'
    optionText: LABEL_HR_SLOWEST,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_HR_SLOWEST],
    queryOrderBy: `${BE_FIELD_HR}`,
    ascending: true,
    isDefault: false,
  },
];
// :: 정렬 - R-R (Longest) ::
export const RR_SORT_DEFAULT: SortOption = {
  valueText: RR_VALUE_TEXT, // 'R-R'
  optionText: LABEL_R_R_LONGEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_R_R_LONGEST],
  queryOrderBy: `-${BE_FIELD_R_R_INTERVAL}`,
  ascending: false,
  isDefault: false,
};
// :: 정렬 - R-R (Shortest) ::
export const RR_SORT_OPTION_LIST: SortOptionList = [
  RR_SORT_DEFAULT,
  {
    valueText: RR_VALUE_TEXT, // 'R-R'
    optionText: LABEL_R_R_SHORTEST,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_R_R_SHORTEST],
    queryOrderBy: `${BE_FIELD_R_R_INTERVAL}`,
    ascending: true,
    isDefault: false,
  },
];

// :: Time Event ::
export const AF_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_ONSET_MS,
  ascending: true,
  isDefault: true,
};
export const AF_SORT_OPTION_LIST: SortOptionList = [
  AF_SORT_DEFAULT,
  {
    valueText: DURATION_VALUE_TEXT, // 'Duration'
    optionText: LABEL_DURATION_LONGEST,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_DURATION_LONGEST],
    queryOrderBy: `-${BE_FIELD_DURATION_MS},${BE_FIELD_ONSET_MS}`,
    ascending: false,
    isDefault: false,
  },
  {
    valueText: AVG_HR_VALUE_TEXT, // 'Avg HR'
    optionText: LABEL_AVG_HR_FASTEST,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_AVG_HR_FASTEST],
    queryOrderBy: `-${BE_FIELD_HR_AVG},${BE_FIELD_ONSET_MS}`,
    ascending: false,
    isDefault: false,
  },
];

export const PAUSE_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_ONSET_WAVEFORM_INDEX,
  sortFunctions: {
    [BE_FIELD_ONSET_WAVEFORM_INDEX]:
      SORT_FUNCTIONS[BE_FIELD_ONSET_WAVEFORM_INDEX],
    [`-${BE_FIELD_R_R_INTERVAL}`]: SORT_FUNCTIONS[`-${BE_FIELD_R_R_INTERVAL}`],
  },
  ascending: true,
  isDefault: true,
};
export const PAUSE_SORT_OPTION_LIST: SortOptionList = [
  PAUSE_SORT_DEFAULT,
  {
    ...RR_SORT_DEFAULT,
    queryOrderBy: `-${BE_FIELD_R_R_INTERVAL},${BE_FIELD_ONSET_WAVEFORM_INDEX}`,
    isDefault: false,
  },
];

export const OTHERS_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_ONSET_MS,
  ascending: true,
  isDefault: true,
};
export const OTHERS_SORT_OPTION_LIST: SortOptionList = [OTHERS_SORT_DEFAULT];

// :: Ectopic ::
export const SVT_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_ONSET_WAVEFORM_INDEX,
  ascending: true,
  isDefault: true,
};
export const SVT_SORT_OPTION_LIST: SortOptionList = [
  SVT_SORT_DEFAULT,
  {
    valueText: BEAT_VALUE_TEXT, // 'Beat'
    optionText: LABEL_BEAT_COUNT,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_BEAT_COUNT],
    queryOrderBy: `-${BE_FIELD_LEN},${BE_FIELD_ONSET_WAVEFORM_INDEX}`,
    ascending: false,
    isDefault: false,
  },
  {
    valueText: AVG_HR_VALUE_TEXT, // 'Avg HR'
    optionText: LABEL_AVG_HR_FASTEST,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_AVG_HR_FASTEST],
    queryOrderBy: `-${BE_FIELD_HR_AVG},${BE_FIELD_ONSET_WAVEFORM_INDEX}`,
    ascending: false,
    isDefault: false,
  },
];

export const VT_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_ONSET_WAVEFORM_INDEX,
  ascending: true,
  isDefault: true,
};
export const VT_SORT_OPTION_LIST: SortOptionList = [
  VT_SORT_DEFAULT,
  {
    valueText: BEAT_VALUE_TEXT, // 'Beat'
    optionText: LABEL_BEAT_COUNT,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_BEAT_COUNT],
    queryOrderBy: `-${BE_FIELD_LEN},${BE_FIELD_ONSET_WAVEFORM_INDEX}`,
    ascending: false,
    isDefault: false,
  },
  {
    valueText: AVG_HR_VALUE_TEXT, // 'Avg HR'
    optionText: LABEL_AVG_HR_FASTEST,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_AVG_HR_FASTEST],
    queryOrderBy: `-${BE_FIELD_HR_AVG},${BE_FIELD_ONSET_WAVEFORM_INDEX}`,
    ascending: false,
    isDefault: false,
  },
];

export const APC_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_ONSET_WAVEFORM_INDEX,
  ascending: true,
  isDefault: true,
};
export const APC_SORT_OPTION_LIST: SortOptionList = [APC_SORT_DEFAULT];

export const VPC_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_ONSET_WAVEFORM_INDEX,
  ascending: true,
  isDefault: true,
};
export const VPC_SORT_OPTION_LIST: SortOptionList = [VPC_SORT_DEFAULT];

export const GEMINY_SORT_OPTION_LIST: SortOptionList = [
  VT_SORT_DEFAULT,
  {
    valueText: CYCLE_VALUE_TEXT,
    optionText: LABEL_CYCLE_COUNT,
    tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_AVG_HR_FASTEST],
    queryOrderBy: `-${BE_FIELD_CYCLE},${BE_FIELD_ONSET_WAVEFORM_INDEX}`,
    ascending: false,
    isDefault: false,
  },
];

export const PTE_SORT_DEFAULT: SortOption = {
  valueText: TIME_VALUE_TEXT, // 'Time'
  optionText: LABEL_TIME_OLDEST,
  tooltipTitle: TOOLTIP_TITLE_MAP[LABEL_TIME_OLDEST],
  queryOrderBy: BE_FIELD_EVENT_TIME_MS,
  ascending: true,
  isDefault: true,
};
export const PTE_SORT_OPTION_LIST: SortOptionList = [PTE_SORT_DEFAULT];

export const SORT_ORDER = {
  [EVENT_CONST_TYPES.AF]: AF_SORT_DEFAULT,
  [EVENT_CONST_TYPES.PAUSE]: PAUSE_SORT_DEFAULT,
  [EVENT_CONST_TYPES.SVT]: SVT_SORT_DEFAULT,
  [EVENT_CONST_TYPES.ISO_APC]: APC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.COUPLET_APC]: APC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.BIGEMINY_APC]: APC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.TRIGEMINY_APC]: APC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.QUADRIGEMINY_APC]: APC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.VT]: VT_SORT_DEFAULT,
  [EVENT_CONST_TYPES.ISO_VPC]: VPC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.COUPLET_VPC]: VPC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.BIGEMINY_VPC]: VPC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.TRIGEMINY_VPC]: VPC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.QUADRIGEMINY_VPC]: VPC_SORT_DEFAULT,
  [EVENT_CONST_TYPES.OTHERS]: OTHERS_SORT_DEFAULT,
  [EVENT_CONST_TYPES.PATIENT]: PTE_SORT_DEFAULT,
};
