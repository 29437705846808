import { useDispatch } from 'react-redux';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import ResetPasswordDialog from 'component/dialog/ResetPasswordDialog';
import { authCheckEmailRequested } from 'redux/duck/authDuck';

function ResetPasswordDialogContainer(props) {
  const { open, onClose } = props;

  const dispatch = useDispatch();

  const passwordState = useShallowEqualSelector(
    (state) => state.passwordReducer
  );

  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };

  const handleHideDialog = (dialogKey) => {
    dispatch(hideDialog(dialogKey));
  };

  const handleCheckEmail = (email) => {
    const isForgot = true;
    dispatch(authCheckEmailRequested(email, isForgot));
  };

  return (
    <ResetPasswordDialog
      open={open}
      passwordState={passwordState}
      onClose={onClose}
      showDialog={handleShowDialog}
      hideDialog={handleHideDialog}
      checkEmail={handleCheckEmail}
      callback={() => {}}
    />
  );
}

export default ResetPasswordDialogContainer;
