import { useState, useEffect, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';

import Const from 'constant/Const';

import { type AuthState } from 'type/apiDataType';

import SearchableDropdown, {
  SearchableDropdownProps,
} from 'component/ui/searchableDropdown/SearchableDropdown';

import { showDialog } from 'redux/duck/dialogDuck';
import { fetchMedicalStaffsRequested } from 'redux/duck/medicalStaffsDuck';

import usePrevious from './usePrevious';
import useShallowEqualSelector from './useShallowEqualSelector';

interface DoctorItem {
  key?: string;
  label: string;
  value: string;
  referredCount: number;
}

type InitDoctorItem = Omit<DoctorItem, 'referredCount'>;

/**
 *
 * @param {string} placeholder
 * @param {DoctorItem | null} initDoctor
 * @param {{disabled: boolean}} useDoctorsDropdownOption
 * @returns {[DoctorItem, import('react').ForwardRefExoticComponent]}
 */
const useDoctorsDropdown = (
  placeholder: string,
  initDoctor: InitDoctorItem,
  { disabled } = { disabled: false }
) => {
  //custom hook
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const prevInitDoctor: unknown = usePrevious(initDoctor);

  //store
  const authState = useShallowEqualSelector((state) => state.authReducer);
  const medicalStaffFetchState: any = useShallowEqualSelector(
    (state) => state.medicalStaffsReducer.fetch
  );

  //dispatch
  const handleFetchDoctors = () =>
    dispatch(
      fetchMedicalStaffsRequested(
        (authState as AuthState).user.hospital?.hid,
        1,
        1000,
        '',
        Const.STAFF_TYPE.DOCTOR.value as unknown as string,
        '-referred_count,first_name'
      )
    );

  const callbackShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };

  //states
  const [selectedDoctor, setSelectedDoctor] = useState<InitDoctorItem | null>(
    initDoctor
  );

  useEffect(() => {
    if ((prevInitDoctor as DoctorItem)?.label !== initDoctor?.label) {
      handleFetchDoctors();
      setSelectedDoctor(initDoctor);
    }
  }, [initDoctor]);

  useEffect(() => {
    handleFetchDoctors();
  }, []);

  const onChangeCallback = (item: DoctorItem | null, index: number) => {
    if (item === null) {
      setSelectedDoctor(null);
      return;
    }

    if (item.value === 'addNewDoctor') {
      callbackShowDialog('NewDoctorDialog', {}, (newDoctor) => {
        setSelectedDoctor({
          label: newDoctor.firstName,
          value: newDoctor.username,
        });
        handleFetchDoctors();
      });
    } else {
      setSelectedDoctor(item);
    }
  };

  const doctorItems = [
    ...medicalStaffFetchState.data.map((doctor) => ({
      key: doctor.username,
      label: doctor.firstName,
      value: doctor.username,
      referredCount: doctor.referredCount,
    })),
  ];

  const DoctorsDropdown = forwardRef((props: SearchableDropdownProps, ref) => (
    <SearchableDropdown
      dataTestId={props.dataTestId}
      disabled={disabled}
      style={props.style}
      placeholder={placeholder}
      queryKey="label"
      items={doctorItems}
      fixedItem={{
        key: 'addNewDoctor',
        label: intl.formatMessage({
          id: '99-useDoctorsDropdown-Dropdown-DropdownItem-addNewDoctor',
          description: '의사 선택 Dropdown 의 의사 추가 옵션',
          defaultMessage: '의사 추가하기',
        }),
        value: 'addNewDoctor',
        textColor: (theme as any).color.BLUE_70,
      }}
      selectedItem={selectedDoctor}
      onChange={onChangeCallback}
      anchorContainerStyle={{
        padding: '8px 12px',
        minWidth: 172,
        width: '100%',
        ...props.anchorContainerStyle,
      }}
      itemContainerStyle={{
        borderColor: (theme as any).color.COOL_GRAY_50,
        bottom: props.dropToTop ? 35 : '',
        maxHeight: 190,
        overflowY: 'scroll',
        justifyContent: 'initial',
        ...props.itemContainerStyle,
      }}
      dropToTop={props.dropToTop}
    />
  ));

  return [selectedDoctor, DoctorsDropdown];
};

export default useDoctorsDropdown;
