import { call, put, retry, select, takeLatest } from 'redux-saga/effects';
import forge from 'node-forge';
import axios from 'axios';

import LocalStorageKey from 'constant/LocalStorageKey';
import Const, { TIME_ZONE } from 'constant/Const';
import { WebViewMessageConst, sendMessage } from 'constant/WebViewMessageConst';

import ApiManager from 'network/ApiManager/ApiManager';
import StatusCode from 'network/StatusCode';
import ResultCode from 'network/ResultCode';

import LocalStorageManager from 'manager/LocalStorageManager';
import { resetPasswordByEmailRequested } from 'redux/duck/passwordDuck';

import { initCloseLogCreditAlert } from './feedback/feedbackDuck';
// Select Functions
export const selectUsername = (state) => state.authReducer.user?.username;
export const selectUserHospital = (state) =>
  state.authReducer.user?.hospital ?? {};
export const selectAuthData = (state) => state.authReducer.data;

// Actions
// CheckEmail
const CHECK_EMAIL_REQUESTED = 'memo-web/auth/CHECK_EMAIL_REQUESTED';
const CHECK_EMAIL_SUCCEED = 'memo-web/auth/CHECK_EMAIL_SUCCEED';
const CHECK_EMAIL_FAILED = 'memo-web/auth/CHECK_EMAIL_FAILED';
// Login
const LOGIN_REQUESTED = 'memo-web/auth/LOGIN_REQUESTED';
const LOGIN_SUCCEED = 'memo-web/auth/LOGIN_SUCCEED';
const LOGIN_FAILED = 'memo-web/auth/LOGIN_FAILED';
const LOGIN_REQUESTED_BY_TOKEN = 'memo-web/auth/LOGIN_REQUESTED_BY_TOKEN';
// Logout
const LOGOUT_REQUESTED = 'memo-web/auth/LOGOUT_REQUESTED';
const LOGOUT_SUCCEED = 'memo-web/auth/LOGOUT_SUCCEED';
const LOGOUT_FAILED = 'memo-web/auth/LOGOUT_FAILED';
// RefreshAccessToken
const REFRESH_ACCESS_TOKEN_REQUESTED =
  'memo-web/auth/REFRESH_ACCESS_TOKEN_REQUESTED';
// const REFRESH_ACCESS_TOKEN_SUCCEED = 'memo-web/auth/REFRESH_ACCESS_TOKEN_SUCCEED';
// const REFRESH_ACCESS_TOKEN_FAILED = 'memo-web/auth/REFRESH_ACCESS_TOKEN_FAILED';
// ReadMyInformation
const READ_MY_INFORMATION_REQUESTED =
  'memo-web/auth/READ_MY_INFORMATION_REQUESTED';
const READ_MY_INFORMATION_SUCCEED = 'memo-web/auth/READ_MY_INFORMATION_SUCCEED';
const READ_MY_INFORMATION_FAILED = 'memo-web/auth/READ_MY_INFORMATION_FAILED';
// set normal status
const SET_NORMAL_STATUS = 'memo-web/auth/SET_NORMAL_STATUS';
// update user info
const UPDATE_USER_INFO = 'memo-web/auth/UPDATE_USER_INFO';

// Reducer
const initialState = {
  pending: false,
  data: null, // { ...loginDevice: null } - Const.DEVICE.WEB, Const.DEVICE.MFD
  user: null,
  email: {
    pending: false,
    data: null,
    error: null,
  },
  isLoggedIn: false,
  isAdmin: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // CheckEmail
    case CHECK_EMAIL_REQUESTED:
      return {
        ...state,
        email: {
          pending: true,
          error: null,
        },
      };
    case CHECK_EMAIL_SUCCEED:
      return {
        ...state,
        email: {
          pending: false,
          data: action.data,
        },
      };
    case CHECK_EMAIL_FAILED:
      return {
        ...state,
        email: {
          pending: false,
          error: action.error,
        },
      };
    // Login
    case LOGIN_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case LOGIN_SUCCEED:
      return {
        ...state,
        isLoggedIn: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    // ReadMyInformation
    case READ_MY_INFORMATION_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case READ_MY_INFORMATION_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
        user: action.user,
        // isLoggedIn 값에 따른 Routing 제어를 위해 Fa
        isLoggedIn:
          action.data.code === ResultCode.AUTH_STATUS.CHANGE_PASSWORD_REQUIRED
            ? true
            : false,
        isAdmin: action.user.isHospitalAdmin,
      };
    case READ_MY_INFORMATION_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    // Logout
    case LOGOUT_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case LOGOUT_SUCCEED:
      return {
        ...state,
        pending: false,
        data: null,
        user: null,
        isLoggedIn: false,
      };
    case LOGOUT_FAILED:
      return {
        ...state,
        pending: false,
        isLoggedIn: false,
        error: action.error,
      };
    case SET_NORMAL_STATUS:
      return {
        ...state,
        data: {
          ...(state.data ?? {}),
          code: ResultCode.AUTH_STATUS.NORMAL,
        },
      };

    case UPDATE_USER_INFO:
      return {
        ...state,
        user: action.newInfo,
      };
    default:
      return state;
  }
}

// Action Creators
// CheckEmail
export function authCheckEmailRequested(email, isForgot) {
  return { type: CHECK_EMAIL_REQUESTED, email, isForgot };
}
function authCheckEmailSucceed(data) {
  return { type: CHECK_EMAIL_SUCCEED, data };
}
function authCheckEmailFailed(error) {
  return { type: CHECK_EMAIL_FAILED, error: error };
}
// Login
export function authLoginRequested(email, password) {
  return {
    type: LOGIN_REQUESTED,
    email,
    password,
  };
}
// Login by Token(240717 기존 token으로 로그인 한 상태는 MFD에서 로그인 밖에 없음)
export function authLoginRequestedByToken(accessToken, refreshToken) {
  return {
    type: LOGIN_REQUESTED_BY_TOKEN,
    accessToken,
    refreshToken,
  };
}
export function authLoginSucceed() {
  return { type: LOGIN_SUCCEED };
}
function authLoginFailed(error) {
  return { type: LOGIN_FAILED, error: error };
}
// ReadMyInformation
export function authReadMyInformationRequested(data) {
  return { type: READ_MY_INFORMATION_REQUESTED, data };
}
function authReadMyInformationSucceed(user, data) {
  return { type: READ_MY_INFORMATION_SUCCEED, user, data };
}
function authReadMyInformationFailed(error) {
  return { type: READ_MY_INFORMATION_FAILED, error };
}
// Logout
export function authLogoutRequested() {
  return { type: LOGOUT_REQUESTED };
}
function authLogoutSucceed() {
  return { type: LOGOUT_SUCCEED };
}
function authLogoutFailed(error) {
  return { type: LOGOUT_FAILED, error: error };
}
// RefreshAccessToken
export function authRefreshAccessTokenRequested(
  resetActionCreator,
  failActionCreator,
  args
) {
  return {
    type: REFRESH_ACCESS_TOKEN_REQUESTED,
    resetActionCreator,
    failActionCreator,
    args,
  };
}
// set normal status
export function setNormalStatus() {
  return { type: SET_NORMAL_STATUS };
}
// update user info
export function updateUserInfo(newInfo) {
  return { type: UPDATE_USER_INFO, newInfo };
}

// Sagas
/**
 *
 * @param {*} action
 * @returns
 */
function* checkEmail(action) {
  const { email, isForgot } = action;
  try {
    const { status, data } = yield call(ApiManager.checkEmail, email);

    const { result, error } = data;

    // XXX: 만약 서브 계정 등록 시 중복 이메일 체크로 해당 API 활용 되면 로직 수정 필요!!!
    // 이어서 RESET_PASSWORD_BY_EMAIL_REQUESTED 액션 실행 후 리턴

    if (isForgot) {
      // eslint-disable-next-line default-case
      switch (result.code) {
        case ResultCode.AUTH_STATUS.NORMAL:
        case ResultCode.AUTH_STATUS.DORMANT:
        case ResultCode.AUTH_STATUS.INITIAL_PASSWORD_REQUIRED:
          yield put(resetPasswordByEmailRequested(action.email, result.code));
      }
    }

    // code 1004: 존재하지 않는 계정; 미등록 이메일
    yield put(authCheckEmailSucceed(result));
  } catch (error) {
    yield put(authCheckEmailFailed(error));
  }
}

function* getPublicKey() {
  try {
    const RETRY_COUNT = 2;
    const RETRY_DELAY = 500;

    const {
      data: {
        result: { publicKey, passwordToken },
      },
    } = yield retry(RETRY_COUNT, RETRY_DELAY, ApiManager.getPublicKey);

    return { publicKey, passwordToken };
  } catch (error) {
    console.error(error);
  }
}

function* login(action) {
  try {
    const { email, password } = action;

    const { publicKey, passwordToken } = yield call(getPublicKey);

    const encryptedPassword = encryptPassword({
      publicKey,
      password,
    });

    const { data } = yield call(
      ApiManager.login,
      email,
      encryptedPassword,
      passwordToken
    );

    const { result } = data;

    const {
      tokenType,
      accessToken,
      refreshToken,
      email: resultEmail,
      username,
      ...restData
    } = result;
    // restData: expiresIn, tokenType, scope, code
    LocalStorageManager.setItem(LocalStorageKey.TOKEN_TYPE, tokenType);
    LocalStorageManager.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
    LocalStorageManager.setItem(LocalStorageKey.REFRESH_TOKEN, refreshToken);
    // 웹에서 로그인시 device 설정
    restData.device = Const.DEVICE.WEB;
    // 로그인 성공 하면 사용자 정보 요청
    yield put(authReadMyInformationRequested(restData));
  } catch (error) {
    console.error(error);
    yield put(authLoginFailed(error));
  }

  // inner func
  function encryptPassword({ publicKey, password }) {
    if (!publicKey) return;
    // Base64 인코딩된 공개키를 디코딩
    const publicKeyPem = forge.util.decode64(publicKey);
    // PEM 포맷의 공개키를 forge가 사용할 수 있는 포맷으로 변환
    const formattedPublicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    // 패스워드를 RSA-OAEP 방식으로 암호화
    const encrypted = formattedPublicKey.encrypt(password, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });
    // 암호화된 데이터를 Base64로 인코딩하여 반환
    return forge.util.encode64(encrypted);
  }
}

/**
 * url query string으로 전달된 accessToken, refreshToken을 LocalStorage에 저장 및 사용자 정보 요청
 * url query string으로 로그인 시도 시 MFD(Desktop APP) 으로 간주
 * @param {*} action
 */
function* loginByToken(action) {
  try {
    LocalStorageManager.setItem(LocalStorageKey.TOKEN_TYPE, 'Bearer');
    LocalStorageManager.setItem(
      LocalStorageKey.ACCESS_TOKEN,
      action.accessToken
    );
    LocalStorageManager.setItem(
      LocalStorageKey.REFRESH_TOKEN,
      action.refreshToken
    );

    // 토큰으로 로그인 시도시 계정 상태를 NORMAL로 간주
    yield put(
      authReadMyInformationRequested({
        code: ResultCode.AUTH_STATUS.NORMAL,
        device: Const.DEVICE.MFD,
      })
    );
  } catch (error) {
    yield put(authLoginFailed(error));
  }
}

function* readMyInformation(action) {
  try {
    const { data, headers } = yield call(ApiManager.readMyInformation);

    const { result } = data;
    const serverTimestamp = new Date(headers.date).getTime();

    // 서버 시간과 클라이언트 시간을 비교하여 시간 차이 계산
    const isServerTimeAhead = serverTimestamp > new Date().getTime();
    const timeDiffBtwServer =
      (isServerTimeAhead ? 1 : -1) * (new Date().getTime() - serverTimestamp);

    // HospitalTimezone, serverTimestamp, timeDiffBtwServer 를 LocalStorage에 저장
    LocalStorageManager.setItem(
      LocalStorageKey.HOSPITAL_TIMEZONE,
      data.hospital?.timezone || TIME_ZONE.SEOUL
    );
    LocalStorageManager.setItem(
      LocalStorageKey.SERVER_TIME_STAMP,
      serverTimestamp
    );
    LocalStorageManager.setItem(
      LocalStorageKey.TIME_DIFF_BTW_SERVER,
      timeDiffBtwServer
    );

    yield put(authReadMyInformationSucceed(result, action.data));
  } catch (error) {
    yield put(authReadMyInformationFailed(error));
  }
}

function* logout() {
  try {
    const authData = yield select(selectAuthData);

    LocalStorageManager.clear({
      exceptList: [LocalStorageKey.USER_HID],
    });

    if (authData.device === Const.DEVICE.MFD) {
      sendMessage(WebViewMessageConst.LOGOUT());
    }

    yield put(authLogoutSucceed());
    yield put(initCloseLogCreditAlert());
  } catch (error) {
    yield put(authLogoutFailed(error));
  }
}

/**
 * accessToken을 사용하는 모든 API의 saga 함수에서 401응답(UNAUTHORIZED)시 Token 재발행 처리 시도
 * 성공 및 실패 처리는 직전 401 실패한 API의 Action으로 위임 처리
 * 다른 API 에서 401 응답 => refreshToken으로 갱신 시도
 * accessToken 갱신 실패 => 임의 로그아웃 처리!!
 * 화면에서 관련된 메시지 제공 등 후속 조치 필요 + Logout Action 호출도...
 * @param {*} action
 * @returns
 */
function* refreshAccessToken(action) {
  try {
    const oldRefreshToken = LocalStorageManager.getItem(
      LocalStorageKey.REFRESH_TOKEN
    );
    const { status, data } = yield call(
      ApiManager.refreshAccessToken,
      oldRefreshToken
    );
    const { result, error } = data;

    const { accessToken, refreshToken } = result;
    LocalStorageManager.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
    LocalStorageManager.setItem(LocalStorageKey.REFRESH_TOKEN, refreshToken);

    yield put(action.resetActionCreator(...action.args));
  } catch (error) {
    // 임의 로그아웃 처리 적용
    LocalStorageManager.clear({
      exceptList: [LocalStorageKey.USER_HID],
    });
    yield put(
      action.failActionCreator({ status: StatusCode.UNAUTHORIZED, error })
    );
  }
}

export function* saga() {
  yield takeLatest(CHECK_EMAIL_REQUESTED, checkEmail);
  yield takeLatest(LOGIN_REQUESTED, login);
  yield takeLatest(LOGIN_REQUESTED_BY_TOKEN, loginByToken);
  yield takeLatest(READ_MY_INFORMATION_REQUESTED, readMyInformation);
  yield takeLatest(LOGOUT_REQUESTED, logout);
  yield takeLatest(REFRESH_ACCESS_TOKEN_REQUESTED, refreshAccessToken);
}
