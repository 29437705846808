import { useIntl } from 'react-intl';
import AlertDialog from './AlertDialog';

function AlertExceedBpmLimitDialog(props) {
  const intl = useIntl();

  const { open, callback, onClose } = props;

  return (
    <AlertDialog
      open={open}
      params={{
        message: intl.formatMessage({
          id: '99-AlertExceedBpmLimitDialog-AlertDialog-message-01',
          description: '400bpm을 초과하는 beat는 추가할 수 없음 안내',
          defaultMessage:
            'Beat의 HR이 400bpm을 초과하는 경우 Beat를 추가할 수 없습니다.',
        }),
        confirmButtonText: intl.formatMessage({
          id: '99-Dialog-Button-title-confirm',
          description: '팝업 Dialog의 확인 버튼',
          defaultMessage: '확인',
        }),
      }}
      callback={callback}
      onClose={onClose}
      zIndex={13}
    />
  );
}

export default AlertExceedBpmLimitDialog;
