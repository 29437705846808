import { put, select, takeLatest } from 'redux-saga/effects';
import {
  // Actions
  SHOW_CREDIT_ALERT,
  HIDE_CREDIT_ALERT,
  INIT_ClOSE_LOG_CREDIT_ALERT,
  HANDLE_CREDIT_ALERT_STATE,
  // Reducer
  FeedbackReducerState,
  CreditAlertState,
  // Action Creators
  FeedbackReducerAction,
  ShowCreditAlertAction,
  HideCreditAlertAction,
  InitCloseLogCreditAlertAction,
  HandleCreditAlertStateAction,
} from './feedbackDuckType';

// Select Functions
const selectCreditAlertState = (state: {
  feedbackReducer: FeedbackReducerState;
  [x: string]: any;
}) => state.feedbackReducer.creditAlert;
export const selectIsCreditAlertShow = (state: {
  feedbackReducer: FeedbackReducerState;
  [x: string]: any;
}) => state.feedbackReducer.creditAlert.isShow;

// Reducer
const initialState: FeedbackReducerState = {
  creditAlert: { isShow: false, closeLog: null },
};

export default function reducer(
  state = initialState,
  action: FeedbackReducerAction
): FeedbackReducerState {
  switch (action.type) {
    case INIT_ClOSE_LOG_CREDIT_ALERT:
      return {
        ...state,
        creditAlert: initialState.creditAlert,
      };
    case HANDLE_CREDIT_ALERT_STATE:
      return {
        ...state,
        creditAlert: action.payload,
      };

    default:
      return state;
  }
}

// Action Creators
export function showCreditAlert(): ShowCreditAlertAction {
  return {
    type: SHOW_CREDIT_ALERT,
  };
}
export function hideCreditAlert(): HideCreditAlertAction {
  return {
    type: HIDE_CREDIT_ALERT,
  };
}
export function initCloseLogCreditAlert(): InitCloseLogCreditAlertAction {
  return {
    type: INIT_ClOSE_LOG_CREDIT_ALERT,
  };
}
function handleCreditAlertState(
  payload: CreditAlertState
): HandleCreditAlertStateAction {
  return {
    type: HANDLE_CREDIT_ALERT_STATE,
    payload,
  };
}

// Saga Functions
/**
 * Credit Alert 의 열림 요청 처리
 *
 * 분기문 순서
 * 1. 이미 열려져 있는 경우, 상태를 변경하지 않음
 * 2. 이미 닫은 사용자인 경우, 상태를 변경하지 않음
 * 3. 닫은 이력이 없거나 닫아두기 기한이 넘어간 사용자의 경우 Alert 을 제공하도록 상태를 변경
 * @param action
 * @returns
 */
function* _showCreditAlert(action: ShowCreditAlertAction) {
  const { isShow, closeLog }: CreditAlertState = yield select(
    selectCreditAlertState
  );

  // 이미 열려져 있는 경우, 상태를 변경하지 않음
  if (isShow) return;

  let payload: CreditAlertState = { isShow, closeLog };

  const todayTimestamp = new Date().setHours(0, 0, 0, 0);
  // 이미 닫은 사용자인 경우, 상태를 변경하지 않음
  if (closeLog && todayTimestamp <= closeLog) return;

  // 닫은 이력이 없거나 닫아두기 기한이 넘어간 사용자의 경우 Alert 을 제공하도록 상태를 변경
  payload.isShow = true;
  yield put(handleCreditAlertState(payload));
}

/**
 * Credit Alert 의 닫힘 요청 처리
 *
 * @param action
 * @returns
 */
function* _hideCreditAlert(action: HideCreditAlertAction) {
  const { isShow }: CreditAlertState = yield select(selectCreditAlertState);

  // 이미 닫혀 있는 경우, 상태를 변경하지 않음
  if (!isShow) return;

  const todayTimestamp = new Date().setHours(0, 0, 0, 0);
  const payload: CreditAlertState = {
    isShow: false,
    closeLog: todayTimestamp,
  };
  yield put(handleCreditAlertState(payload));
}

// Saga
export function* saga() {
  // Get Analytics Statistics
  yield takeLatest(SHOW_CREDIT_ALERT, _showCreditAlert);
  // Get Patch Statistics
  yield takeLatest(HIDE_CREDIT_ALERT, _hideCreditAlert);
}
