import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';

import Const from 'constant/Const';

import TextUtil from 'util/TextUtil';

import StatusCode from 'network/StatusCode';

import usePrevious from 'component/hook/usePrevious';

import {
  DialogWrapper,
  DialogContentContainer,
  DialogButtonWrapper,
} from 'component/ui/dialog/Dialog';
import TextInput from 'component/ui/input/TextInput';
import Dropdown from 'component/ui/dropdown/Dropdown';
import TextButton from 'component/ui/button/TextButton';
import { RadioContainer, RadioButton } from 'component/ui/radio/Radio';

const CONST_STAFF_TYPE = Const.STAFF_TYPE;
const CONST_ACCESS_LEVEL = Const.ACCESS_LEVEL;

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.32px;
  color: ${(props) => props.theme.color.BLACK};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const LabelText = styled.div`
  width: 72px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

const ValueText = styled.div`
  flex: 1;
  padding: 8px 0px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

const StaffDialogButtonWrapper = styled(DialogButtonWrapper)`
  display: flex;
  align-items: center;
`;
const StaffDialogButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    :not(:last-child) {
      margin-right: 6px;
    }
  }
`;

function UserManagementDialog(props) {
  const intl = useIntl();
  const theme = useTheme();

  const {
    // Dialog Props
    open,
    params: { medicalStaff, isMe },
    onClose,
    // Redux State
    passwordState,
    medicalStaffCUState,
    medicalStaffRemoveState,
    // Redux Dispatch
    showDialog,
    createMedicalStaff,
    updateMedicalStaff,
    removeMedicalStaff,
  } = props;

  const [name, setName] = useState(medicalStaff?.firstName || '');
  const [email, setEmail] = useState(medicalStaff?.email || '');
  const [staffType, setStaffType] = useState(() => {
    const staffType = Object.values(CONST_STAFF_TYPE).filter(
      (value) => value.value === medicalStaff?.staffType
    )[0];
    if (medicalStaff?.staffType && staffType) {
      return {
        label: intl.formatMessage(staffType),
        value: staffType.value,
      };
    } else {
      return {
        label: intl.formatMessage(CONST_STAFF_TYPE.MEDICAL_TECHNOLOGIST),
        value: CONST_STAFF_TYPE.MEDICAL_TECHNOLOGIST.value,
      };
    }
  });
  const [accessLevel, setAccessLevel] = useState(
    medicalStaff?.accessLevel || CONST_ACCESS_LEVEL.READ_ONLY.value
  );
  const [isDoctor, setIsDoctor] = useState(
    medicalStaff?.staffType === CONST_STAFF_TYPE.DOCTOR.value
  );
  const [isUpdateMode, setIsUpdateMode] = useState(!!medicalStaff);

  // FIXME(준호): 신규 생성 성공 이후 이메일 발송 처리 실패에 대한 예외처리 필요!!!
  // 생성, 수정 API 응답 처리
  const prevMedicalStaffCreateState = usePrevious(medicalStaffCUState);
  useEffect(() => {
    if (
      prevMedicalStaffCreateState &&
      prevMedicalStaffCreateState.pending &&
      !medicalStaffCUState.pending
    ) {
      if (medicalStaffCUState.error) {
        const { status, message } = medicalStaffCUState.error;
        switch (parseInt(status)) {
          case StatusCode.BAD_REQUEST:
            if (message.email)
              showDialog('AlertDialog', {
                message: intl.formatMessage({
                  id: '99-UserManagementDialog-AlertDialog-message-01',
                  description: '이미 등록된 이메일 주소입니다.',
                  defaultMessage: '이미 등록된 이메일 주소입니다.',
                }),
              });
            break;
          default:
          // showDialog('AlertNetworkDialog');
        }
      } else {
        onClose();
      }
    }
  }, [medicalStaffCUState.pending]);

  // 삭제 API 응답 처리
  const prevMedicalStaffRemoveState = usePrevious(medicalStaffRemoveState);
  useEffect(() => {
    if (
      prevMedicalStaffRemoveState &&
      prevMedicalStaffRemoveState.pending &&
      !medicalStaffRemoveState.pending
    ) {
      if (medicalStaffRemoveState.error) {
        const { status } = medicalStaffRemoveState.error;
        switch (parseInt(status)) {
          default:
          // showDialog('AlertNetworkDialog');
        }
      } else {
        onClose();
      }
    }
  }, [medicalStaffRemoveState.pending]);

  useEffect(() => {
    setAccessLevel(Math.max(CONST_ACCESS_LEVEL.READ_ONLY.value, accessLevel));
    if (staffType.value === CONST_STAFF_TYPE.DOCTOR.value) {
      setIsDoctor(true);
    } else {
      setIsDoctor(false);
    }
  }, [staffType]);

  return (
    <DialogWrapper open={open} maxWidth={360}>
      <TitleText>
        {isUpdateMode
          ? intl.formatMessage({
              id: '99-UserManagementDialog-TitleText-children-01',
              description: '사용자 수정',
              defaultMessage: '사용자 수정',
            })
          : intl.formatMessage({
              id: '99-UserManagementDialog-TitleText-children-02',
              description: '사용자 추가',
              defaultMessage: '사용자 추가',
            })}
      </TitleText>

      <DialogContentContainer>
        <InfoContainer>
          <LabelText>
            {intl.formatMessage({
              id: '99-UserManagementDialog-LabelText-children-firstName',
              description: '이름',
              defaultMessage: '이름',
            })}
          </LabelText>

          {isUpdateMode ? (
            <ValueText>{name}</ValueText>
          ) : (
            <TextInput
              style={{ flex: 1 }}
              value={name}
              onChange={(text) => {
                setName(text);
              }}
            />
          )}
        </InfoContainer>

        <InfoContainer>
          <LabelText>
            {intl.formatMessage({
              id: '99-UserManagementDialog-LabelText-children-email',
              description: '이메일',
              defaultMessage: '이메일',
            })}
          </LabelText>

          {isUpdateMode ? (
            <ValueText>{email}</ValueText>
          ) : (
            <TextInput
              style={{ flex: 1 }}
              inputType="email"
              value={email}
              onChange={(text) => {
                setEmail(text);
              }}
            />
          )}
        </InfoContainer>

        <InfoContainer>
          <LabelText>
            {intl.formatMessage({
              id: '99-UserManagementDialog-LabelText-children-staffType',
              description: '직군',
              defaultMessage: '직군',
            })}
          </LabelText>

          <Dropdown
            items={Object.values(CONST_STAFF_TYPE).map((value) => ({
              label: intl.formatMessage(value),
              value: value.value,
            }))}
            selectedItem={staffType}
            onChange={(item, index) => {
              setStaffType(item);
            }}
            anchorContainerStyle={{
              minWidth: 224 - 24 - 2,
            }}
            itemContainerStyle={{
              borderColor: theme.color.MEDIUM_LIGHT,
              top: 35,
              maxHeight: 74.4,
              overflowY: 'scroll',
              justifyContent: 'initial',
            }}
            dropToTop={false}
          />
        </InfoContainer>

        <InfoContainer>
          <LabelText>
            {intl.formatMessage({
              id: '99-UserManagementDialog-LabelText-children-accessLevel',
              description: 'ECG 데이터',
              defaultMessage: 'ECG 데이터',
            })}
          </LabelText>

          <RadioContainer style={{ flex: 1, gap: 10 }}>
            {Object.values(CONST_ACCESS_LEVEL).map((item) => {
              const label = <FormattedMessage {...item} />;
              return (
                <RadioButton
                  id={item.value}
                  key={item.value}
                  value={item.value}
                  label={label}
                  checked={accessLevel === item.value}
                  onChange={() => {
                    setAccessLevel(item.value);
                  }}
                />
              );
            })}
          </RadioContainer>
        </InfoContainer>
      </DialogContentContainer>

      <StaffDialogButtonWrapper>
        <StaffDialogButtonContainer
          style={{ justifyContent: 'flex-start', flex: 1 }}>
          {isUpdateMode && !isMe && (
            <TextButton
              textColor={theme.color.RED}
              title={intl.formatMessage({
                id: '99-UserManagementDialog-TextButton-title-01',
                description: '삭제',
                defaultMessage: '삭제',
              })}
              onClick={() => {
                showDialog('ConfirmDialog', {
                  title: intl.formatMessage(
                    {
                      id: '99-UserManagementDialog-ConfirmDialog-title-01',
                      description: '하위 사용자 삭제 여부 확인 문구',
                      defaultMessage:
                        '{name}님의 계정을 정말로 삭제하시겠습니까?',
                    },
                    {
                      name: intl.formatMessage(
                        {
                          id: '99-Name-Field-first-last',
                          description: '이름 표기 방법',
                          defaultMessage: '{lastName}{firstName}',
                        },
                        {
                          firstName: medicalStaff.firstName,
                          lastName: medicalStaff.lastName,
                        }
                      ),
                    }
                  ),
                  message: intl.formatMessage({
                    id: '99-UserManagementDialog-ConfirmDialog-message-01',
                    description: '삭제 후에는 복구할 수 없습니다.',
                    defaultMessage: '삭제 후에는 복구할 수 없습니다.',
                  }),
                  confirmButtonText: intl.formatMessage({
                    id: '99-UserManagementDialog-ConfirmDialog-confirmButtonText-01',
                    description: '삭제',
                    defaultMessage: '삭제',
                  }),
                  onSubmit: () => {
                    if (typeof removeMedicalStaff === 'function') {
                      removeMedicalStaff(medicalStaff.username);
                    }
                  },
                });
              }}
              pending={medicalStaffRemoveState.pending}
            />
          )}
        </StaffDialogButtonContainer>
        <StaffDialogButtonContainer>
          <TextButton
            textColor={theme.color.MEDIUM_DARK}
            title={intl.formatMessage({
              id: '99-UserManagementDialog-TextButton-title-02',
              description: '취소',
              defaultMessage: '취소',
            })}
            onClick={onClose}
          />
          <TextButton
            title={
              isUpdateMode
                ? intl.formatMessage({
                    id: '99-UserManagementDialog-TextButton-title-03',
                    description: '수정',
                    defaultMessage: '수정',
                  })
                : intl.formatMessage({
                    id: '99-UserManagementDialog-TextButton-title-04',
                    description: '추가',
                    defaultMessage: '추가',
                  })
            }
            disabled={!(name.length && email.length)}
            onClick={() => {
              if (isUpdateMode) {
                if (typeof updateMedicalStaff === 'function') {
                  updateMedicalStaff(
                    medicalStaff.username,
                    staffType.value,
                    accessLevel
                  );
                }
              } else {
                if (typeof createMedicalStaff === 'function') {
                  if (!TextUtil.verifyInRuleForEmail(email)) {
                    return showDialog('AlertDialog', {
                      message: intl.formatMessage({
                        id: '99-AlertDialog-MessageText-children-01',
                        description: '입력한 이메일 형식이 올바르지 않습니다.',
                        defaultMessage:
                          '입력한 이메일 형식이 올바르지 않습니다.',
                      }),
                    });
                  }
                  createMedicalStaff(
                    name,
                    email,
                    staffType.value,
                    accessLevel,
                    false
                  );
                }
              }
            }}
            pending={medicalStaffCUState.pending}
          />
        </StaffDialogButtonContainer>
      </StaffDialogButtonWrapper>
    </DialogWrapper>
  );
}

export default UserManagementDialog;
