import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';

import Const from 'constant/Const';

import { getLocale } from 'util/Utility';
import { disableReactDevTools } from 'util/disableReactDevTools';

import AppContainer from 'redux/container/AppContainer';

import Korean from 'lang/compiled/ko.json';
import English from 'lang/compiled/en.json';
import configureStore from 'redux/configureStore';

const { store, persistor } = configureStore();
const locale = getLocale();

function loadLocaleData(locale) {
  switch (locale) {
    case 'ko':
      return Korean;
    default:
      return English;
  }
}

if (process.env.REACT_APP_VERCEL_ENV === 'production') {
  disableReactDevTools();
}

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    console.log(
      'Service worker mocking is only available in development mode.'
    );
    return;
  }

  const { worker } = await import('./mocks/browser');

  // msw 사용필요시 주석 해제 후 사용
  // return worker.start({
  //   onUnhandledRequest: 'bypass',
  // });
}

enableMocking().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <IntlProvider
        locale={locale}
        defaultLocale={Const.LANGUAGE.KO}
        messages={loadLocaleData(locale)}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Sentry.ErrorBoundary>
              <AppContainer />
            </Sentry.ErrorBoundary>
          </PersistGate>
        </Provider>
      </IntlProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
