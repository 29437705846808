import styled from 'styled-components';

const OnlyRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  [type='radio'] + label:before {
    top: 2px;
    left: 2px;
    box-sizing: border-box;
  }
  [type='radio'] + label:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
  }
`;

export const RadioContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: stretch;
  justify-content: stretch;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const RadioButtonWrapper = styled.div`
  box-sizing: border-box;
  padding: 8px;
  flex: 1;

  border-radius: 32px;
  background-color: ${(props) =>
    props.checked
      ? props.theme.color.LIGHT_PRIMARY_BLUE
      : props.theme.color.WHITE};
  // border-width: ${(props) => (props.checked ? 2 : 1)}px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.checked || props.borderChecked
      ? props.theme.color.PRIMARY_BLUE
      : props.theme.color.MEDIUM};
  :hover {
    ${(props) =>
      !props.disabled && `border-color: ${props.theme.color.PRIMARY_BLUE}`}
  }

  ${(props) =>
    props.focused &&
    `
        border-color: ${props.theme.color.PRIMARY_BLUE};
    `}
  transition: border-color 0.2s ease-in-out;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
`;

const Label = styled.label`
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

export function OnlyRadio(props) {
  const {
    id,
    value,
    checked,
    onChange,
    label,
    className,
    children,
    labelStyle,
  } = props;
  return (
    <OnlyRadioWrapper className={className}>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={typeof onChange !== 'function'}
      />
      <Label style={labelStyle} htmlFor={id}>
        {label}
      </Label>
      {children}
    </OnlyRadioWrapper>
  );
}

export function RadioButton(props) {
  const {
    id,
    value,
    checked,
    onChange,
    label,
    disabled,
    wrapperStyle,
    labelStyle,
    dataTestId,
  } = props;
  return (
    <RadioButtonWrapper
      style={wrapperStyle}
      disabled={disabled}
      onClick={disabled ? null : onChange}
      checked={checked}
      data-testid={dataTestId}>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={() => {}}
      />
      <Label style={labelStyle} htmlFor={id}>
        {label}
      </Label>
    </RadioButtonWrapper>
  );
}

export function RadioButtonWithShortcut(props) {
  const {
    id,
    value,
    checked,
    borderChecked,
    direction,
    onChange,
    label,
    shortcut,
    disabled,
  } = props;
  return (
    <RadioButtonWrapper
      direction={direction || 'row'}
      onClick={disabled ? null : onChange}
      disabled={disabled}
      checked={checked}
      borderChecked={borderChecked}>
      <input
        type="radio"
        id={id}
        value={value}
        checked={checked}
        onChange={() => {}}
      />
      <Label htmlFor={id}>
        {`${label} (`}
        <Label
          style={{ textDecoration: 'underline', textUnderlineOffset: '1px' }}>
          {shortcut}
        </Label>
        {')'}
      </Label>
    </RadioButtonWrapper>
  );
}
