import { Action } from 'redux';

import {
  UNDER_TWO_DAYS,
  UNDER_SEVEN_DAYS,
  UNDER_FOURTEEN_DAYS,
  CONTRACT_TYPE,
  PATCH_STATUS_CODE,
} from 'constant/StatisticsConst';

// Actions
// Get Analytics Statistics
export const GET_ANALYTICS_STATISTICS_REQUESTED =
  'memo-web/hospital/GET_ANALYTICS_STATISTICS_REQUESTED';
export const GET_ANALYTICS_STATISTICS_SUCCEED =
  'memo-web/hospital/GET_ANALYTICS_STATISTICS_SUCCEED';
export const GET_ANALYTICS_STATISTICS_FAILED =
  'memo-web/hospital/GET_ANALYTICS_STATISTICS_FAILED';
// Get Patch Statistics
export const GET_PATCH_STATISTICS_REQUESTED =
  'memo-web/hospital/GET_PATCH_STATISTICS_REQUESTED';
export const GET_PATCH_STATISTICS_SUCCEED =
  'memo-web/hospital/GET_PATCH_STATISTICS_SUCCEED';
export const GET_PATCH_STATISTICS_FAILED =
  'memo-web/hospital/GET_PATCH_STATISTICS_FAILED';

// Reducer
export type HospitalReducerState = {
  statsAnalytics: {
    pending: boolean;
    error: NetworkResponseError | null;
    data: AnalyticsStatistics | null;
  };
  statsPatch: {
    pending: boolean;
    error: NetworkResponseError | null;
    data: PatchStatistics;
    queries: PatchRequestParams;
    metaData: PatchTableMeta;
  };
};

export interface NetworkResponseError extends Error {
  name: string;
  status: number;
  message: string;
}

// Get Analytics Statistics
export type AnalyticsStatistics = {
  contractType: CONTRACT_TYPE;
  prepaidData?: SummarizedData;
  prepaidDetailData?: DetailData;
  ongoingDetailData?: DetailData;
  completedDetailData?: DetailData;
};
export type SummarizedData = {
  /** 누적 충전 크레딧 */
  accumulated: number;
  /** 사용 크레딧 */
  charged: number;
  /** 차감예정 크레딧 */
  pending: number;
};
export type DetailItem = {
  count: number;
  credits?: number;
};
export type DetailData = {
  /** 48시간 이내 */
  [UNDER_TWO_DAYS]: DetailItem;
  /** 7일 이내 */
  [UNDER_SEVEN_DAYS]: DetailItem;
  /** 14일 이내 */
  [UNDER_FOURTEEN_DAYS]: DetailItem;
};

// Get Patch Statistics
export type PatchStatistics = Array<PatchItem>;
export type PatchItem = {
  /** 패치 일련번호 */
  serialNumber: string;
  /** 상태; 00_INVALID | 10_STAND_BY | 20_RECORDING | 30_READY_TO_UPLOAD */
  patchStatus: PATCH_STATUS_CODE;
  /** 사용횟수 */
  usageCount: number;
  /** 잔여사용횟수 */
  remaining: number;
};

// Patch List Filter
export type PatchRequestParams = {
  q: string;
  patchStatus: string;
  ordering: string;
  page: number;
  pageSize: number;
};

export type PatchTableMeta = {
  fetchType: string;
  currentPage: number;
  totalCount: number;
};

// Action Creators
export type HospitalReducerAction =
  | GetAnalyticsStatisticsRequestedAction
  | GetAnalyticsStatisticsSucceedAction
  | GetAnalyticsStatisticsFailedAction
  | GetPatchStatisticsRequestedAction
  | GetPatchStatisticsSucceedAction
  | GetPatchStatisticsFailedAction;
// Get Analytics Statistics
export interface GetAnalyticsStatisticsRequestedAction
  extends Action<typeof GET_ANALYTICS_STATISTICS_REQUESTED> {}
export interface GetAnalyticsStatisticsSucceedAction
  extends Action<typeof GET_ANALYTICS_STATISTICS_SUCCEED> {
  payload: { data: AnalyticsStatistics };
}
export interface GetAnalyticsStatisticsFailedAction
  extends Action<typeof GET_ANALYTICS_STATISTICS_FAILED> {
  payload: { error: NetworkResponseError };
}
// Get Patch Statistics
export interface GetPatchStatisticsRequestedAction
  extends Action<typeof GET_PATCH_STATISTICS_REQUESTED> {
  payload: PatchRequestParams;
}
export interface GetPatchStatisticsSucceedAction
  extends Action<typeof GET_PATCH_STATISTICS_SUCCEED> {
  payload: { data: PatchStatistics; metaData: PatchTableMeta };
}
export interface GetPatchStatisticsFailedAction
  extends Action<typeof GET_PATCH_STATISTICS_FAILED> {
  payload: { error: NetworkResponseError };
}

// API Response
export type HospitalUsageResponse =
  | {
      contractType: CONTRACT_TYPE.PREPAID_CONTRACT;
      totalCredit: number;
      spentCredit: number;
      estimateSpentCredit: number;
      inProgressData?: PrepaidHospitalUsageDetail;
      cloudConfirmedData: PrepaidHospitalUsageDetail;
    }
  | {
      contractType: CONTRACT_TYPE.POSTPAID_CONTRACT;
      inProgressTestCount: PostpaidHospitalUsageDetail;
      cloudConfirmedTestCount: PostpaidHospitalUsageDetail;
    };
export type PrepaidHospitalUsageDetail = {
  /** 48시간 이내 */
  [UNDER_TWO_DAYS]: [number, number];
  /** 7일 이내 */
  [UNDER_SEVEN_DAYS]: [number, number];
  /** 14일 이내 */
  [UNDER_FOURTEEN_DAYS]: [number, number];
};

export type PostpaidHospitalUsageDetail = {
  /** 48시간 이내 */
  [UNDER_TWO_DAYS]: number;
  /** 7일 이내 */
  [UNDER_SEVEN_DAYS]: number;
  /** 14일 이내 */
  [UNDER_FOURTEEN_DAYS]: number;
};
