/* 
각 48시간 이내, 7일(168시간) 이내, 14일(336시간) 이내
API 응답을 참조함 
*/
export const UNDER_TWO_DAYS = 'lte48hr';
export const UNDER_SEVEN_DAYS = 'lte168hr';
export const UNDER_FOURTEEN_DAYS = 'lte336hr';

export enum CONTRACT_TYPE {
  PREPAID_CONTRACT = 'PREPAID',
  POSTPAID_CONTRACT = 'POSTPAID',
}

export const UNDER_TWO_DAYS_CREDIT = 2;
export const UNDER_SEVEN_DAYS_CREDIT = 3;
export const UNDER_FOURTEEN_DAYS_CREDIT = 10;

// Patch Query Option
// export const PATCH_STATUS = {
//   INVALID: '0_INVALID',
//   STAND_BY: '10_STAND_BY',
//   RECORDING: '20_RECORDING',
//   READY_TO_UPLOAD: '30_READY_TO_UPLOAD',
// };
export enum PATCH_STATUS_CODE {
  INVALID = '00_INVALID',
  STAND_BY = '10_STAND_BY',
  RECORDING = '20_RECORDING',
  READY_TO_UPLOAD = '30_READY_TO_UPLOAD',
  EXPIRED = '90_EXPIRED',
}
export const PATCH_STATUS = [
  {
    // ⬇️ 사용불가 ⬇️
    value: PATCH_STATUS_CODE.INVALID,
    color: 'RED_90',
    background: 'RED_50',
  },
  {
    // ⬇️ 사용 가능 ⬇️
    value: PATCH_STATUS_CODE.STAND_BY,
    color: 'GREEN_90',
    background: 'GREEN_40',
  },
  {
    // ⬇️ 측정중 ⬇️
    value: PATCH_STATUS_CODE.RECORDING,
    color: 'BLUE_80',
    background: 'BLUE_40',
  },
  {
    // ⬇️ 업로드 대기 ⬇️
    value: PATCH_STATUS_CODE.READY_TO_UPLOAD,
    color: 'SVE_80',
    background: 'SVE_40',
  },
  {
    // ⬇️ 사용 만료 ⬇️
    value: PATCH_STATUS_CODE.EXPIRED,
    color: 'COOL_GRAY_80',
    background: 'COOL_GRAY_40',
  },
];

export enum PATCH_LIST_SORT_ORDER {
  SERIAL_NUMBER = 'serial_number',
  PATCH_STATUS = 'patch_status',
  USED = 'usage_count',
  REMAINING = 'remaining',
}

export const PAGE_SIZE = 20;

export const ALERT_REMAINING_CREDIT = 20;
