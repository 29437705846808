import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager/ApiManager';
import ResultCode from 'network/ResultCode';
import Const from 'constant/Const';

// ResetPassword
const RESET_PASSWORD_REQUESTED = 'memo-web/password/RESET_PASSWORD_REQUESTED';
const RESET_PASSWORD_SUCCEED = 'memo-web/password/RESET_PASSWORD_SUCCEED';
const RESET_PASSWORD_FAILED = 'memo-web/password/RESET_PASSWORD_FAILED';
// ResetPasswordByEmail
const RESET_PASSWORD_BY_EMAIL_REQUESTED =
  'memo-web/password/RESET_PASSWORD_BY_EMAIL_REQUESTED';
const RESET_PASSWORD_BY_EMAIL_SUCCEED =
  'memo-web/password/RESET_PASSWORD_BY_EMAIL_SUCCEED';
const RESET_PASSWORD_BY_EMAIL_FAILED =
  'memo-web/password/RESET_PASSWORD_BY_EMAIL_FAILED';
// ResetPasswordByEmailWithToken
const RESET_PASSWORD_WITH_TOKEN_REQUESTED =
  'memo-web/password/RESET_PASSWORD_WITH_TOKEN_REQUESTED';
const RESET_PASSWORD_WITH_TOKEN_SUCCEED =
  'memo-web/password/RESET_PASSWORD_WITH_TOKEN_SUCCEED';
const RESET_PASSWORD_WITH_TOKEN_FAILED =
  'memo-web/password/RESET_PASSWORD_WITH_TOKEN_FAILED';
// CompleteResetPasswordByEmail
const COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED =
  'memo-web/password/COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED';
const COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED =
  'memo-web/password/COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED';
const COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED =
  'memo-web/password/COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED';
// ResetPasswordNextTime
const RESET_PASSWORD_NEXT_TIME_REQUESTED =
  'memo-web/password/RESET_PASSWORD_NEXT_TIME_REQUESTED';
const RESET_PASSWORD_NEXT_TIME_SUCCEED =
  'memo-web/password/RESET_PASSWORD_NEXT_TIME_SUCCEED';
const RESET_PASSWORD_NEXT_TIME_FAILED =
  'memo-web/password/RESET_PASSWORD_NEXT_TIME_FAILED';
// ResetPasswordWithOld
const RESET_PASSWORD_WITH_OLD_REQUESTED =
  'memo-web/password/RESET_PASSWORD_WITH_OLD_REQUESTED';
const RESET_PASSWORD_WITH_OLD_SUCCEED =
  'memo-web/password/RESET_PASSWORD_WITH_OLD_SUCCEED';
const RESET_PASSWORD_WITH_OLD_FAILED =
  'memo-web/password/RESET_PASSWORD_WITH_OLD_FAILED';

// Reducer
const initialState = {
  pending: false,
  data: null,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_PASSWORD_REQUESTED:
    case RESET_PASSWORD_BY_EMAIL_REQUESTED:
    case RESET_PASSWORD_WITH_TOKEN_REQUESTED:
    case COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED:
    case RESET_PASSWORD_NEXT_TIME_REQUESTED:
    case RESET_PASSWORD_WITH_OLD_REQUESTED:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCEED:
    case RESET_PASSWORD_BY_EMAIL_SUCCEED:
    case RESET_PASSWORD_WITH_TOKEN_SUCCEED:
    case COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED:
    case RESET_PASSWORD_NEXT_TIME_SUCCEED:
    case RESET_PASSWORD_WITH_OLD_SUCCEED:
      return {
        ...state,
        pending: false,
        data: action.data,
      };
    case RESET_PASSWORD_FAILED:
    case RESET_PASSWORD_BY_EMAIL_FAILED:
    case RESET_PASSWORD_WITH_TOKEN_FAILED:
    case COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED:
    case RESET_PASSWORD_NEXT_TIME_FAILED:
    case RESET_PASSWORD_WITH_OLD_FAILED:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Action Creators
// ResetPassword
export function resetPasswordRequested(newPassword) {
  return { type: RESET_PASSWORD_REQUESTED, newPassword: newPassword };
}
export function resetPasswordSucceed(data) {
  return { type: RESET_PASSWORD_SUCCEED, data: data };
}
export function resetPasswordFailed(error) {
  return { type: RESET_PASSWORD_FAILED, error: error };
}
// ResetPasswordByEmail
export function resetPasswordByEmailRequested(email, code) {
  return {
    type: RESET_PASSWORD_BY_EMAIL_REQUESTED,
    email: email,
    code: code,
  };
}
export function resetPasswordByEmailSucceed(data) {
  return { type: RESET_PASSWORD_BY_EMAIL_SUCCEED, data: data };
}
export function resetPasswordByEmailFailed(error) {
  return { type: RESET_PASSWORD_BY_EMAIL_FAILED, error: error };
}
// ResetPasswordByEmailWithToken
export function resetPasswordWithTokenRequested(email) {
  return {
    type: RESET_PASSWORD_WITH_TOKEN_REQUESTED,
    email: email,
  };
}
function resetPasswordWithTokenSucceed(data) {
  return { type: RESET_PASSWORD_WITH_TOKEN_SUCCEED, data: data };
}
function resetPasswordWithTokenFailed(error) {
  return { type: RESET_PASSWORD_WITH_TOKEN_FAILED, error: error };
}
// CompleteResetPasswordByEmail
export function completeResetPasswordByEmailRequested(password, token, uidb64) {
  return {
    type: COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED,
    password: password,
    token: token,
    uidb64: uidb64,
  };
}
export function completeResetPasswordByEmailSucceed(data) {
  return { type: COMPLETE_RESET_PASSWORD_BY_EMAIL_SUCCEED, data: data };
}
export function completeResetPasswordByEmailFailed(error) {
  return { type: COMPLETE_RESET_PASSWORD_BY_EMAIL_FAILED, error: error };
}
// ResetPasswordNextTime
export function resetPasswordNextTimeRequested() {
  return { type: RESET_PASSWORD_NEXT_TIME_REQUESTED };
}
export function resetPasswordNextTimeSucceed(data) {
  return { type: RESET_PASSWORD_NEXT_TIME_SUCCEED, data: data };
}
export function resetPasswordNextTimeFailed(error) {
  return { type: RESET_PASSWORD_NEXT_TIME_FAILED, error: error };
}
// ResetPasswordWithOld
export function resetPasswordWithOldRequested(oldPassword, newPassword) {
  return {
    type: RESET_PASSWORD_WITH_OLD_REQUESTED,
    oldPassword: oldPassword,
    newPassword: newPassword,
  };
}
export function resetPasswordWithOldSucceed(data) {
  return { type: RESET_PASSWORD_WITH_OLD_SUCCEED, data: data };
}
export function resetPasswordWithOldFailed(error) {
  return { type: RESET_PASSWORD_WITH_OLD_FAILED, error: error };
}

// Sagas
/**
 * Authorized
 * 1.5 휴면 계정 복구
 * @param {*} action
 * @returns
 */
function* resetPassword(action) {
  try {
    const { status, data } = yield call(
      ApiManager.resetPassword,
      action.newPassword
    );

    const { result, error } = data;

    yield put(resetPasswordSucceed(result));
  } catch (error) {
    yield put(resetPasswordFailed(error));
  }
}

function* resetPasswordWithToken(action) {
  try {
    const { status, data } = yield call(
      ApiManager.resetPasswordByEmailWithToken,
      action.email,
      `${window.origin}/change-password/new`
    );

    const { result, error } = data;

    yield put(resetPasswordWithTokenSucceed(result));
  } catch (error) {
    yield put(resetPasswordWithTokenFailed(error));
  }
}

/**
 * 전달 받은 이메일로 비밀번호 초기화 링크 전송 요청
 * FIXME: redirectPath 로 받은 코드 분기 처리 필요
 * 1.3.1 비밀번호 찾기
 * @param {*} action
 * @returns
 */
function* resetPasswordByEmail(action) {
  try {
    let redirectPath;
    let changeType = Const.PASSWORD_CHANGE_TYPE.DEFAULT;
    switch (action.code) {
      // 일반 비밀번호 찾기
      case ResultCode.AUTH_STATUS.NORMAL:
        redirectPath = `${window.origin}/change-password/forgot`;
        changeType = Const.PASSWORD_CHANGE_TYPE.FORGOT;
        break;
      // 휴면 계정
      case ResultCode.AUTH_STATUS.DORMANT:
        redirectPath = `${window.origin}/release-dormant`;
        changeType = Const.PASSWORD_CHANGE_TYPE.DORMANT;
        break;
      // 비밀번호 최초 설정
      case ResultCode.AUTH_STATUS.INITIAL_PASSWORD_REQUIRED:
        redirectPath = `${window.origin}/change-password/new`;
        changeType = Const.PASSWORD_CHANGE_TYPE.NEW;
        break;
      default:
        // FIXME: 자세한 오류 처리
        yield put(resetPasswordByEmailFailed({ status: 500, message: {} }));
        return;
    }
    const { status, data } = yield call(
      ApiManager.resetPasswordByEmail,
      action.email,
      redirectPath,
      changeType
    );

    const { result, error } = data;
    yield put(resetPasswordByEmailSucceed(result));
  } catch (error) {
    yield put(resetPasswordByEmailFailed(error));
  }
}

/**
 * 1.3.2 비밀번호 분실 후 변경
 * 1.4 최초 비밀번호 설정
 * 1.5 휴면 계정 복구(이메일 링크로 접근)
 * @param {*} action
 * @returns
 */
function* completeResetPasswordByEmail(action) {
  try {
    const { status, data } = yield call(
      ApiManager.completeResetPasswordByEmail,
      action.password,
      action.token,
      action.uidb64
    );

    const { result, error } = data;
    yield put(completeResetPasswordByEmailSucceed(result));
  } catch (error) {
    yield put(completeResetPasswordByEmailFailed(error));
  }
}

/**
 * Authorized
 * 1.6 비밀번호 변경 권고
 * @param {*} action
 * @returns
 */
function* resetPasswordNextTime(action) {
  try {
    const { status, data } = yield call(ApiManager.resetPasswordNextTime);

    const { result, error } = data;

    yield put(resetPasswordNextTimeSucceed(result));
  } catch (error) {
    console.error(error);
    yield put(resetPasswordNextTimeFailed(error));
  }
}

/**
 * Authorized
 * 1.6 비밀번호 변경 권고
 * 7.2 비밀번호 변경
 * @param {*} action
 * @returns
 */
function* resetPasswordWithOld(action) {
  try {
    const { status, data } = yield call(
      ApiManager.resetPasswordWithOld,
      action.oldPassword,
      action.newPassword
    );

    const { result, error } = data;

    yield put(resetPasswordWithOldSucceed(result));
  } catch (error) {
    yield put(resetPasswordWithOldFailed(error));
  }
}

export function* saga() {
  yield takeLatest(RESET_PASSWORD_REQUESTED, resetPassword);
  yield takeLatest(RESET_PASSWORD_BY_EMAIL_REQUESTED, resetPasswordByEmail);
  yield takeLatest(RESET_PASSWORD_WITH_TOKEN_REQUESTED, resetPasswordWithToken);
  yield takeLatest(
    COMPLETE_RESET_PASSWORD_BY_EMAIL_REQUESTED,
    completeResetPasswordByEmail
  );
  yield takeLatest(RESET_PASSWORD_NEXT_TIME_REQUESTED, resetPasswordNextTime);
  yield takeLatest(RESET_PASSWORD_WITH_OLD_REQUESTED, resetPasswordWithOld);
}
