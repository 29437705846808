import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ApiManager from 'network/ApiManager/ApiManager';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';
import usePrevious from 'component/hook/usePrevious';
import useHospitalStats from 'component/hook/useHospitalStats';
import useNoticeDialogDisplay from 'component/hook/useNoticeDialogDisplay';

import { authLogoutRequested } from 'redux/duck/authDuck';
import { hideSidePanel } from 'redux/duck/ecgTestList/ecgTestListDuck';
import Header from 'component/base/Header';

function HeaderContainer(props) {
  const [memoDataloaderMfdFiles, setMemoDataloaderMfdFiles] = useState({});

  // CustomHooks
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useNoticeDialogDisplay();

  // Selectors
  const authState = useShallowEqualSelector((state) => state.authReducer);

  // Dispatches
  const handleLogout = () => dispatch(authLogoutRequested());
  const handleHideSidePanel = () => dispatch(hideSidePanel());

  // Page 이동 시 분석통계 데이터 재 조회
  const { handelGetAnalyticsStatistics } = useHospitalStats();
  const prevPath = usePrevious(location.pathname);
  useEffect(() => {
    const path = location.pathname;
    if (prevPath === path) {
      return;
    }
    handelGetAnalyticsStatistics();
  }, [location.pathname]);

  async function getMfdNDataloaderFiles() {
    try {
      const response = await ApiManager.getMfdNDataloaderFile();
      const { MEMOForDesktopApp, dataLoader } = response.data;
      setMemoDataloaderMfdFiles({ MEMOForDesktopApp, dataLoader });
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getMfdNDataloaderFiles();
  }, []);

  return (
    <Header
      history={history}
      // Selectors
      authState={authState}
      // Dispatches
      handleLogout={handleLogout}
      handleHideSidePanel={handleHideSidePanel}
      memoDataloaderMfdFiles={memoDataloaderMfdFiles}
    />
  );
}

export default HeaderContainer;
