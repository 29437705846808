import StatusCode from 'network/StatusCode';
import RestClient from 'network/RestClient';

const serialNumberRegExp = new RegExp(/HPTA[0-9A-Z]{9}$/g);
const timestampRegExp = new RegExp(/[0-9]{10}$/g);

function byteArrayToTimestamp(byteArray) {
  let value = 0;
  for (let i = 0; i < byteArray.length; i++) {
    value = value * 256 + byteArray[i];
  }
  return value;
}

const FileUtil = {
  extractMetadata: async (file) => {
    const buffer = await file.arrayBuffer();
    /**
     * 22" 10. 25. Binary 파일의 시리얼 넘버 포멧 변경에 따른 수정 진행
     *
     * 시리얼 넘버는 12자리 문자열(숫자와 대문자 영문자로 구성)
     *
     * 13 번째 자리 문자는 유니코드 Null 문자로 구분되어 있음
     */
    // const serialSlice = buffer.slice(0, 13);
    const serialSlice = buffer.slice(0, 12);
    const startTimestampSlice = buffer.slice(13, 18);

    let serialSliceUintArr = new Uint8Array(serialSlice);
    // XXX: 준호 - 13자리 우선 추출 후 마지막 자리 문자열이 Null 이면 12자리로 변경하는 로직??
    // if (serialSliceUintArr[12] === 0xff) {
    //   serialSliceUintArr = serialSliceUintArr.slice(0, 12);
    // }

    const serialNumber = String.fromCharCode.apply(null, serialSliceUintArr);
    const startTimestamp = byteArrayToTimestamp(
      new Uint8Array(startTimestampSlice)
    );

    if (!Number.isInteger(startTimestamp)) {
      throw new Error('Invalid ECG file.');
    }

    return [serialNumber, startTimestamp];
  },

  readFileAsync: (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsArrayBuffer(file);
    });
  },
};

/**
 * 응답된 파일 데이터를 로컬에 저장
 *
 * 가능하면 다른이름으로 파일저장 Dialog(`showSaveFilePicker`) 사용
 *
 * @param {*} data 응답 Data
 * @param {*} headers 응답 Header
 * @param {string | null} suggestedFileName 저장할 파일 이름
 * @param {function | null } handleReportDownloadStatusCheck 리포트 조회/ 다운로드 상태 변경 함수
 */
export async function handleSaveFile(
  data,
  headers,
  suggestedFileName = null,
  handleReportDownloadStatusCheck = null
) {
  const contentType = headers['content-type'];
  const contentDisposition = headers['content-disposition'];

  let fileName = suggestedFileName ?? 'download';
  const contentDispositionValue = `${contentDisposition}`
    .split(';')
    .map((value) => value.trim())
    .reduce(
      (acc, cur) => ({ ...acc, [cur.split('=')[0]]: cur.split('=')[1] }),
      {}
    );

  if (contentDispositionValue['filename*']) {
    fileName = decodeURIComponent(contentDispositionValue['filename*']).split(
      "''"
    )[1];
  } else if (contentDispositionValue['filename']) {
    fileName = contentDispositionValue['filename'].replaceAll('"', '');
  }

  const blobData = getBlobData(data, contentType);
  try {
    if ('showSaveFilePicker' in window) {
      await downloadByDialog(
        blobData,
        fileName,
        contentType,
        handleReportDownloadStatusCheck
      );
    } else {
      downloadByTag(
        URL.createObjectURL(blobData),
        fileName,
        handleReportDownloadStatusCheck
      );
    }
  } catch (error) {
    throw new Error(error);
  }
}
/**
 * 파일 데이터를 BLOB 으로 생성하여 반환
 * @param {*} data 파일 데이터
 * @param {string} contentType 파일의 타입
 * @returns
 */
function getBlobData(data, contentType) {
  const blobData = new Blob([data], { type: `${contentType}` });
  return blobData;
}

/**
 * `<a>` 태그를 활용한 파일 저장
 *
 * @param {string} blobUrl BLOB Object URL
 * @param {string} fileName 저장할 파일 이름
 * @param {function} handleReportDownloadStatusCheck 리포트 조회/ 다운로드 상태 변경 함수
 */
function downloadByTag(blobUrl, fileName, handleReportDownloadStatusCheck) {
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  handleReportDownloadStatusCheck(true);

  setTimeout(() => {
    URL.revokeObjectURL(blobUrl);
    link.remove();
  }, 1000);
}

/**
 * `showSaveFilePicker` 를 활용한 파일 저장
 *
 * @param {Blob} blobData
 * @param {string} fileName 저장할 파일 이름
 * @param {string} contentType 파일 확장자 정보
 * @param {function} handleReportDownloadStatusCheck 리포트 조회/ 다운로드 상태 변경 함수
 */
async function downloadByDialog(
  blobData,
  fileName,
  contentType,
  handleReportDownloadStatusCheck
) {
  const fileExtension = fileName.split('.')[1];
  const opts = {
    suggestedName: fileName,
    types: [
      {
        description: 'My configuration file',
        accept: { [contentType]: [`.${fileExtension}`] },
      },
    ],
  };
  try {
    const handle = await window.showSaveFilePicker(opts);
    const writableStream = await handle.createWritable();
    await writableStream.write(blobData);
    await writableStream.close();
    handleReportDownloadStatusCheck(true);
  } catch (error) {
    console.error(error);
  }
}

/**
 * 리포트 관련 파일 다운로드 함수
 *
 * @param {string} fileUrl 다운로드 받을 리포트 관련 파일 URL
 * @param {string | null} suggestedFileName 저장할 파일 이름
 * @param {function} handleReportDownloadStatusCheck 리포트 조회/ 다운로드 상태 변경 함수
 * @returns void
 */
export async function downloadReportAssociatedFile(
  fileUrl,
  suggestedFileName,
  handleReportDownloadStatusCheck
) {
  try {
    if (!fileUrl) {
      throw new Error('fileUrl is not valid. fileUrl: ', fileUrl);
    }

    const response = await RestClient.getFile(fileUrl);

    const { status, data, headers } = response;
    if (status !== StatusCode.OK) {
      throw new Error('File download failed. fileUrl: ', fileUrl);
    }

    handleSaveFile(
      data,
      headers,
      suggestedFileName,
      handleReportDownloadStatusCheck
    );
  } catch (error) {
    console.error(error);
  }
}

export default FileUtil;
