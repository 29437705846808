import styled from 'styled-components';

export const labelAlignConst = { start: 'start', center: 'center', end: 'end' };
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  > :nth-child(1) {
    align-self: ${(props) => (props.labelAlign ? props.labelAlign : 'center')};
  }
`;

const LabelText = styled.div`
  /* width: 96px; */
  width: ${(props) => (props.labelWidth ? props.labelWidth : 96)}px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
  ${({ required, theme }) =>
    required &&
    `
    &:after {
      content: ' *';
      display: inline;
      color: ${theme.color.RED_70};
    }
  `}
`;

const InputContainer = styled.div`
  width: ${(props) => (props.inputWidth ? props.inputWidth : 255)}px;
`;

function DialogRowItem(props) {
  const {
    className,
    labelAlign,
    labelWidth,
    required,
    label,
    inputWidth,
    children,
    dataTestId,
  } = props;

  return (
    <Wrapper
      className={className}
      labelAlign={labelAlign}
      data-testid={dataTestId}>
      <LabelText labelWidth={labelWidth} required={required}>
        {label}
      </LabelText>
      <InputContainer inputWidth={inputWidth}>{children}</InputContainer>
    </Wrapper>
  );
}

export default DialogRowItem;
