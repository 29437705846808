export const GaUtil = {
  NOTICE_POP_UP_VIEWED: () => {
    try {
      window.gtag('event', 'view', {
        content_id: 'notice_popup',
      });
    } catch (error) {
      console.log('Error : view_notice_popup');
    }
  },
  NOTICE_POPUP_IMAGE_CLICK: () => {
    try {
      window.gtag('event', 'click', {
        content_id: 'notice_popup_image',
      });
    } catch (error) {
      console.log('Error : click_notice_popup_image');
    }
  },
  NOTICE_POPUP_DO_NOW_SHOW_TODAY_CLICK: () => {
    try {
      window.gtag('event', 'click', {
        content_id: 'notice_popup_do_not_show_today',
      });
    } catch (error) {
      console.log('Error : click_notice_popup_do_not_show_today');
    }
  },
};
