import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  // Reducer
  HospitalReducerState,
  // Actions
  GET_ANALYTICS_STATISTICS_REQUESTED,
  GET_ANALYTICS_STATISTICS_SUCCEED,
  GET_ANALYTICS_STATISTICS_FAILED,
  GET_PATCH_STATISTICS_REQUESTED,
  GET_PATCH_STATISTICS_SUCCEED,
  GET_PATCH_STATISTICS_FAILED,
  // AC Return Type
  HospitalReducerAction,
  GetAnalyticsStatisticsRequestedAction,
  GetAnalyticsStatisticsSucceedAction,
  GetAnalyticsStatisticsFailedAction,
  GetPatchStatisticsRequestedAction,
  GetPatchStatisticsSucceedAction,
  GetPatchStatisticsFailedAction,
  //
  NetworkResponseError,
  AnalyticsStatistics,
  PatchStatistics,
  PatchRequestParams,
  HospitalUsageResponse,
  PrepaidHospitalUsageDetail,
  PostpaidHospitalUsageDetail,
  DetailData,
  PatchTableMeta,
} from './hospitalDuckType';

import ApiManager from 'network/ApiManager/ApiManager';

import { selectUserHospital } from '../authDuck';

import Const from 'constant/Const';

import {
  ALERT_REMAINING_CREDIT,
  CONTRACT_TYPE,
} from 'constant/StatisticsConst';
import { showCreditAlert } from '../feedback/feedbackDuck';

const {
  FETCH_TYPE: { SEARCH_MODE, FETCH_MODE },
} = Const;

// Selector
// Actions
// etc function
// Reducer
// Action Creators
// Saga functions
// Saga

// Selector
export const selectStatsAnalyticsData = (state: {
  hospitalReducer: typeof initialState;
  [x: string]: any;
}) => state.hospitalReducer.statsAnalytics.data;
export const selectStatsPatchData = (state: {
  hospitalReducer: typeof initialState;
  [x: string]: any;
}) => state.hospitalReducer.statsPatch.data;
export const selectStatsPatchPending = (state: {
  hospitalReducer: typeof initialState;
  [x: string]: any;
}) => state.hospitalReducer.statsPatch.pending;
export const selectStatsPatchQueries = (state: {
  hospitalReducer: typeof initialState;
  [x: string]: any;
}) => state.hospitalReducer.statsPatch.queries;
export const selectStatsPatchMetaData = (state: {
  hospitalReducer: typeof initialState;
  [x: string]: any;
}) => state.hospitalReducer.statsPatch.metaData;

// Reducer
const initialState: HospitalReducerState = {
  statsAnalytics: {
    pending: false,
    error: null,
    data: null,
  },
  statsPatch: {
    pending: false,
    error: null,
    data: [],
    queries: {
      q: '',
      patchStatus: ``,
      ordering: ``,
      page: 1,
      pageSize: 20,
    },
    metaData: {
      fetchType: FETCH_MODE,
      currentPage: 1,
      totalCount: 0,
    },
  },
};
export default function reducer(
  state = initialState,
  action: HospitalReducerAction
): typeof initialState {
  switch (action.type) {
    // Get Analytics Statistics
    case GET_ANALYTICS_STATISTICS_REQUESTED:
      return {
        ...state,
        statsAnalytics: {
          ...state.statsAnalytics,
          pending: true,
          error: null,
        },
      };
    case GET_ANALYTICS_STATISTICS_SUCCEED:
      return {
        ...state,
        statsAnalytics: {
          ...state.statsAnalytics,
          pending: false,
          data: action.payload.data,
        },
      };
    case GET_ANALYTICS_STATISTICS_FAILED:
      return {
        ...state,
        statsAnalytics: {
          ...state.statsAnalytics,
          pending: false,
          error: action.payload.error,
        },
      };
    // Get Patch Statistics
    case GET_PATCH_STATISTICS_REQUESTED:
      return {
        ...state,
        statsPatch: {
          ...state.statsPatch,
          pending: true,
          error: null,
        },
      };
    case GET_PATCH_STATISTICS_SUCCEED:
      return {
        ...state,
        statsPatch: {
          ...state.statsPatch,
          pending: false,
          data: action.payload.data,
          metaData: action.payload.metaData,
        },
      };
    case GET_PATCH_STATISTICS_FAILED:
      return {
        ...state,
        statsPatch: {
          ...state.statsPatch,
          pending: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
}

// Action Creators
// Get Analytics Statistics
export function getAnalyticsStatisticsRequested(): GetAnalyticsStatisticsRequestedAction {
  return {
    type: GET_ANALYTICS_STATISTICS_REQUESTED,
  };
}
function getAnalyticsStatisticsSucceed(
  data: AnalyticsStatistics
): GetAnalyticsStatisticsSucceedAction {
  return {
    type: GET_ANALYTICS_STATISTICS_SUCCEED,
    payload: { data },
  };
}
function getAnalyticsStatisticsFailed(
  error: NetworkResponseError
): GetAnalyticsStatisticsFailedAction {
  return {
    type: GET_ANALYTICS_STATISTICS_FAILED,
    payload: { error },
  };
}
// Get Patch Statistics
export function getPatchStatisticsRequested(
  payload: PatchRequestParams
): GetPatchStatisticsRequestedAction {
  return {
    type: GET_PATCH_STATISTICS_REQUESTED,
    payload,
  };
}
function getPatchStatisticsSucceed(
  data: PatchStatistics,
  metaData: PatchTableMeta
): GetPatchStatisticsSucceedAction {
  return {
    type: GET_PATCH_STATISTICS_SUCCEED,
    payload: { data, metaData },
  };
}
function getPatchStatisticsFailed(
  error: NetworkResponseError
): GetPatchStatisticsFailedAction {
  return {
    type: GET_PATCH_STATISTICS_FAILED,
    payload: { error },
  };
}

// Saga functions
// Get Analytics Statistics
function* _getAnalyticsStatistics() {
  try {
    const {
      hid: hospitalId,
    }: {
      hid: number | undefined;
    } = yield select(selectUserHospital);
    if (hospitalId === undefined) {
      throw getError();
    }

    const params = { hospitalId };

    const {
      data: { result },
    }: { data: { result: HospitalUsageResponse } } = yield call(
      ApiManager.getAnalyticsStatistic,
      params
    );

    let data: AnalyticsStatistics | null = null;
    if (result.contractType === CONTRACT_TYPE.PREPAID_CONTRACT) {
      const { totalCredit, cloudConfirmedData, estimateSpentCredit } = result;
      const accumulated = totalCredit;
      const charged = getSumValue(cloudConfirmedData);
      const pending = estimateSpentCredit;
      data = {
        contractType: CONTRACT_TYPE.PREPAID_CONTRACT,
        prepaidData: {
          accumulated,
          charged,
          pending,
        },
        prepaidDetailData: getDetailItemMap(cloudConfirmedData),
      };
    }
    if (result.contractType === CONTRACT_TYPE.POSTPAID_CONTRACT) {
      const { inProgressTestCount, cloudConfirmedTestCount } = result;
      data = {
        contractType: CONTRACT_TYPE.POSTPAID_CONTRACT,
        ongoingDetailData: getDetailItemMap(inProgressTestCount),
        completedDetailData: getDetailItemMap(cloudConfirmedTestCount),
      };
    }

    if (data === null) {
      throw getError();
    }

    yield put(getAnalyticsStatisticsSucceed(data));

    // 선불 병원에 한하여, 잔여 Credit 이 임계값 이하일 경우 Credit Alert 이 제공될 수 있는 로직
    // yield put(showCreditAlert());
    if (
      result.contractType !== CONTRACT_TYPE.PREPAID_CONTRACT ||
      !data.prepaidData
    ) {
      return;
    }
    const remaining = data.prepaidData.accumulated - data.prepaidData.charged;
    if (remaining <= ALERT_REMAINING_CREDIT) {
      yield put(showCreditAlert());
    }
  } catch (error) {
    yield put(getAnalyticsStatisticsFailed(error as NetworkResponseError));
  }
}
// Get Patch Statistics
function* _getPatchStatistics(action: GetPatchStatisticsRequestedAction) {
  try {
    const {
      payload: {
        //
        q,
        patchStatus,
        ordering,
        page,
        pageSize,
      },
    } = action;
    const {
      hid,
    }: {
      hid: number | undefined;
    } = yield select(selectUserHospital);
    if (hid === undefined) {
      throw getError();
    }
    const params = {
      hid,
      q,
      patchStatus,
      ordering,
      page,
      pageSize,
    };

    const {
      data: { count, results },
    } = yield call(ApiManager.getPatchStatistics, params);

    const data: PatchStatistics = results as PatchStatistics;
    const metaData = {
      fetchType: `${q}${patchStatus}`.length === 0 ? FETCH_MODE : SEARCH_MODE,
      currentPage: page,
      totalCount: count as number,
    };
    yield put(getPatchStatisticsSucceed(data, metaData));
  } catch (error) {
    yield put(getPatchStatisticsFailed(error as NetworkResponseError));
  }
}

// Saga
export function* saga() {
  // Get Analytics Statistics
  yield takeLatest(GET_ANALYTICS_STATISTICS_REQUESTED, _getAnalyticsStatistics);
  // Get Patch Statistics
  yield takeLatest(GET_PATCH_STATISTICS_REQUESTED, _getPatchStatistics);
}

function getError() {
  const error = new Error() as NetworkResponseError;
  error.name = 'hospitalDuck Error in Get Patch Statistics';
  error.status = 0;
  error.message = 'Need Hospital ID';

  return error;
}

function getSumValue(
  usageDetailMap: PrepaidHospitalUsageDetail | PostpaidHospitalUsageDetail
): number {
  const result: number = Object.values(usageDetailMap).reduce(
    (acc: number, cur: number | [number, number]) => {
      if (Array.isArray(cur)) {
        return (acc += cur[1]);
      }
      return (acc += cur);
    },
    0
  );

  return result;
}

function getDetailItemMap(
  usageDetailMap: PrepaidHospitalUsageDetail | PostpaidHospitalUsageDetail
): DetailData {
  const result: DetailData = Object.entries(usageDetailMap).reduce(
    (acc, [key, value]: [string, number | [number, number]]) => {
      if (Array.isArray(value)) {
        return {
          ...acc,
          [key]: {
            count: value[0],
            credits: value[1],
          },
        };
      }
      return {
        ...acc,
        [key]: {
          count: value,
        },
      };
    },
    {} as DetailData
  );

  return result;
}
