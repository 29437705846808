import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';
import {
  createTheme,
  ThemeProvider as EmotionThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import ResultCode from 'network/ResultCode';

import useHeaderHeight from 'component/hook/useHeaderHeight';

import CentralizedDialogContainer from 'redux/container/dialog/CentralizedDialogContainer';

import CreditAlert from 'component/ui/feedback/CreditAlert';

import StyledComponentsTheme from 'theme/StyledComponentsTheme';
import FullScreenLayout from 'component/base/FullScreenLayout';
import Layout from 'component/base/Layout';
import UnderMaintenancePage, {
  isMaintenanceMode,
} from 'component/page/UnderMaintenancePage';

import ShortcutGuide from './ShortcutGuide';

const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

// Pages
const LoginPageContainer = loadable(() =>
  import('redux/container/LoginPageContainer')
);
const ChangePasswordPageContainer = loadable(() =>
  import('redux/container/ChangePasswordPageContainer')
);
const SoftwareInformationPageContainer = loadable(() =>
  import('redux/container/SoftwareInformationPageContainer')
);
const UdiInformationPageContainer = loadable(() =>
  import('redux/container/UdiInformationPageContainer')
);
const ReleaseDormantPageContainer = loadable(() =>
  import('redux/container/ReleaseDormantPageContainer')
);
const MainPageContainer = loadable(() =>
  import('redux/container/MainPageContainer')
);
const TestResultPageContainer = loadable(() =>
  import('redux/container/TestResultPageContainer')
);
const MyAccountPageContainer = loadable(() =>
  import('redux/container/MyAccountPageContainer')
);
const UserManagementPageContainer = loadable(() =>
  import('redux/container/UserManagementPageContainer')
);
const UsageAgreementPageContainer = loadable(() =>
  import('redux/container/UsageAgreementPageContainer')
);
const PrivacyPolicyPage = loadable(() =>
  import('component/page/PrivacyPolicyPage')
);
const HospitalUsageStatisticsPage = loadable(() =>
  import('component/page/StatisticsPage')
);
const NotFoundPage = loadable(() => import('component/page/NotFoundPage'));
const TestModalPageContainer = loadable(() =>
  import('redux/container/TestModalPageContainer')
);
const UserGuidePageContainer = loadable(() =>
  import('redux/container/UserGuidePageContainer')
);
const TestNotificationHistoryPageContainer = loadable(() =>
  import('redux/container/TestNotificationHistoryPageContainer')
);
const PaymentPageContainer = loadable(() =>
  import('redux/container/fragment/payment/PaymentPageContainer')
);

// Sentry Initialize
if (process.env.REACT_APP_SENTRY_ENABLE === 'true') {
  const tracesSampleRate = Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE);
  if (process.env.REACT_APP_SENTRY_DNS && tracesSampleRate) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      environment: `${process.env.REACT_APP_VERCEL_ENV ?? ''}_${
        process.env.REACT_APP_CUSTOM_ENV ?? ''
      }`,

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: tracesSampleRate,

      // for avoid Spam Error Exception; ResizeObserver loop completed with undelivered notifications. ResizeObserver loop limit exceeded
      ignoreErrors: [/^ResizeObserver loop.+$/],
    });
    console.log('Sentry Activate');
  }
}

function App(props) {
  const {
    // Redux state
    authState,
  } = props;

  const headerHeight = useHeaderHeight();
  const theme = createTheme({
    ...StyledComponentsTheme,
    size: { headerHeight },
  });

  return (
    <EmotionThemeProvider theme={theme}>
      <ThemeProvider
        theme={{ ...StyledComponentsTheme, size: { headerHeight } }}>
        <SnackbarProvider>
          <ShortcutGuide>
            <Router>
              <CreditAlert />
              <Switch>
                {/* Password Reset Cases Without Login (Layout) */}
                <SentryRoute
                  path={['/change-password/forgot', '/change-password/new']}>
                  <Layout>
                    <Route component={ChangePasswordPageContainer} />
                  </Layout>
                </SentryRoute>
                <SentryRoute path={['/release-dormant']}>
                  <Layout>
                    <Route component={ReleaseDormantPageContainer} />
                  </Layout>
                </SentryRoute>

                {/* Other pages (Layout) */}
                <Route
                  path="*"
                  render={(routeProps) => {
                    if (isMaintenanceMode()) {
                      return (
                        <Switch>
                          <Route
                            path="/under-maintenance"
                            component={UnderMaintenancePage}
                          />
                          <Redirect to="/under-maintenance" />
                        </Switch>
                      );
                    }
                    if (!authState.isLoggedIn) {
                      return (
                        <FullScreenLayout>
                          <Switch>
                            <Route
                              path="/login"
                              component={LoginPageContainer}
                            />
                            <Redirect
                              to={{
                                pathname: '/login',
                                state: { from: routeProps.location },
                              }}
                            />
                          </Switch>
                        </FullScreenLayout>
                      );
                    } else if (
                      authState.data.code ===
                      ResultCode.AUTH_STATUS.CHANGE_PASSWORD_REQUIRED
                    ) {
                      return (
                        <Switch>
                          <SentryRoute path="/change-password/recommended">
                            <Layout>
                              <Route component={ChangePasswordPageContainer} />
                            </Layout>
                          </SentryRoute>
                          <Redirect to="/change-password/recommended" />
                        </Switch>
                      );
                    } else if (
                      authState.data.code === ResultCode.AUTH_STATUS.DORMANT
                    ) {
                      return (
                        <Switch>
                          <SentryRoute path="/release-dormant">
                            <Layout>
                              <Route component={ReleaseDormantPageContainer} />
                            </Layout>
                          </SentryRoute>
                          <Redirect to="/release-dormant" />
                        </Switch>
                      );
                    }

                    if (!authState.user?.isConfirmed) {
                      return (
                        <Layout>
                          <Switch>
                            <SentryRoute
                              path="/usage-agreement"
                              component={UsageAgreementPageContainer}
                            />
                            <SentryRoute
                              path="/privacy"
                              component={PrivacyPolicyPage}
                            />
                            <Redirect to="/usage-agreement" />
                          </Switch>
                        </Layout>
                      );
                    }

                    if (
                      !authState.isAdmin &&
                      routeProps.location.pathname === '/user-management'
                    ) {
                      return <Redirect to="/" />;
                    }

                    //token 기반 로그인 for dataloader
                    //login?accessToken=xxx&refreshToken=xxx 인 경우
                    const isLoginByToken =
                      routeProps.location.pathname === '/login' &&
                      routeProps.location.search.includes('accessToken') &&
                      routeProps.location.search.includes('refreshToken');

                    if (isLoginByToken) {
                      return (
                        <FullScreenLayout>
                          <Switch>
                            <Route
                              path="/login"
                              component={LoginPageContainer}
                            />
                            <Redirect to="/login" />
                          </Switch>
                        </FullScreenLayout>
                      );
                    }

                    return (
                      <Layout>
                        <Switch>
                          <SentryRoute
                            exact
                            path="/"
                            component={MainPageContainer}
                          />
                          <SentryRoute
                            exact
                            path="/stats"
                            component={HospitalUsageStatisticsPage}
                          />
                          <SentryRoute
                            exact
                            path="/payment"
                            component={PaymentPageContainer}
                          />
                          {process.env.REACT_APP_CUSTOM_ENV !== 'prod' && (
                            <Route
                              exact
                              path="/testPageModal"
                              component={TestModalPageContainer}
                            />
                          )}
                          <SentryRoute
                            exact
                            path="/test/:ecgTestId"
                            component={TestResultPageContainer}
                          />
                          <SentryRoute
                            exact
                            path="/my-account"
                            component={MyAccountPageContainer}
                          />
                          <SentryRoute path="/usage-agreement">
                            <Redirect to="/" />
                          </SentryRoute>
                          <SentryRoute
                            path="/change-password"
                            component={ChangePasswordPageContainer}
                          />
                          <SentryRoute
                            path="/software-information"
                            component={SoftwareInformationPageContainer}
                          />
                          <SentryRoute
                            path="/udi-information"
                            component={UdiInformationPageContainer}
                          />
                          <SentryRoute
                            path="/release-dormant"
                            component={ReleaseDormantPageContainer}
                          />
                          <SentryRoute
                            path="/privacy"
                            component={PrivacyPolicyPage}
                          />
                          <SentryRoute
                            path="/user-management"
                            component={UserManagementPageContainer}
                          />
                          <SentryRoute
                            path="/test-notification-history"
                            component={TestNotificationHistoryPageContainer}
                          />
                          <SentryRoute
                            path="/user-guide"
                            component={UserGuidePageContainer}
                          />

                          <SentryRoute
                            exact
                            path="/not-found"
                            component={NotFoundPage}
                          />

                          {authState.isLoggedIn && (
                            //login된 상태에서 login page로 접근 시도한 경우
                            <Redirect to="/" />
                          )}

                          <Redirect to="/not-found" />
                        </Switch>
                      </Layout>
                    );
                  }}
                />
              </Switch>
            </Router>

            {/* Centralized Dialogs */}
            <CentralizedDialogContainer {...props} />
          </ShortcutGuide>
        </SnackbarProvider>
      </ThemeProvider>
    </EmotionThemeProvider>
  );
}

export default Sentry.withProfiler(App);
