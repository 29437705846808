import { forwardRef } from 'react';
import styled from 'styled-components';

import ButtonSpinner from './ButtonSpinner';

const Wrapper = styled.button.attrs((props) => {
  const { autoFocus, tabindex } = props;
  return { autoFocus, tabindex };
})`
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
  background-color: transparent;
  ${(props) => {
    let result;
    if (!props.disabled) {
      result = `:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
      }`;
    }
    return result;
  }}

  color: ${(props) => props.textColor || props.theme.color.PRIMARY_BLUE};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  transition: background-color 0.2s ease-in-out;
  border: none;
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
    border: none;
    outline: none;
  }
`;

function TextButton(props, ref) {
  const {
    style,
    disabled,
    pending,
    title,
    textColor,
    onClick,
    autoFocus = false,
    tabindex = 0,
    dataTestId,
  } = props;

  return (
    <Wrapper
      data-testid={dataTestId}
      autoFocus={autoFocus}
      tabindex={tabindex}
      ref={ref}
      style={style}
      disabled={disabled}
      onClick={(event) => {
        if (!(disabled || pending)) {
          onClick(event);
        }
      }}
      textColor={textColor}>
      {pending ? <ButtonSpinner reverse={true} /> : title}
    </Wrapper>
  );
}

export default forwardRef(TextButton);
