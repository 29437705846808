import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import usePrevious from 'component/hook/usePrevious';
import StatusCode from 'network/StatusCode';
import TextUtil from 'util/TextUtil';
import {
  DialogWrapper,
  DialogContentContainer,
  DialogButtonWrapper,
} from 'component/ui/dialog/Dialog';
import TextInput from 'component/ui/input/TextInput';
import TextButton from 'component/ui/button/TextButton';

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.32px;
  color: ${(props) => props.theme.color.BLACK};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const LabelText = styled.div`
  width: 72px;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

const StaffDialogButtonWrapper = styled(DialogButtonWrapper)`
  display: flex;
  align-items: center;
`;
const StaffDialogButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    :not(:last-child) {
      margin-right: 6px;
    }
  }
`;

function NewDoctorDialog(props) {
  const intl = useIntl();
  const theme = useTheme();

  const {
    // Dialog Props
    open,
    params: {},
    callback,
    onClose,
    // Redux State
    medicalStaffCUState,
    // Redux Dispatch
    showDialog,
    createDoctor,
  } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  // 생성, 수정 API 응답 처리
  const prevMedicalStaffCreateState = usePrevious(medicalStaffCUState);
  useEffect(() => {
    if (
      prevMedicalStaffCreateState &&
      prevMedicalStaffCreateState.pending &&
      !medicalStaffCUState.pending
    ) {
      if (medicalStaffCUState.error) {
        const { status, message } = medicalStaffCUState.error;
        switch (parseInt(status)) {
          case StatusCode.BAD_REQUEST:
            if (message.email)
              showDialog('AlertDialog', {
                message: intl.formatMessage({
                  id: '99-NewDoctorDialog-AlertDialog-message-01',
                  description: '이미 등록된 이메일 주소입니다.',
                  defaultMessage: '이미 등록된 이메일 주소입니다.',
                }),
              });
            break;
          default:
          // showDialog('AlertNetworkDialog');
        }
      } else {
        if (typeof callback === 'function') {
          callback(medicalStaffCUState.data);
        }
        onClose();
      }
    }
  }, [medicalStaffCUState.pending]);

  const onDoctorNameChangeCallback = (text) => {
    const specialCharacterAndNumberPattern =
      /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-\d]/;

    const emojiPresentation = /\p{Emoji_Presentation}/gu;

    const isInValidText =
      specialCharacterAndNumberPattern.test(text) ||
      emojiPresentation.test(text);

    if (isInValidText) return;
    setName(text);
  };

  return (
    <DialogWrapper open={open} maxWidth={360} zIndex={11}>
      <TitleText>
        {intl.formatMessage({
          id: '99-NewDoctorDialog-TitleText-children-01',
          description: '의사 추가',
          defaultMessage: '의사 추가',
        })}
      </TitleText>

      <DialogContentContainer>
        <InfoContainer>
          <LabelText>
            {intl.formatMessage({
              id: '99-NewDoctorDialog-LabelText-children-firstName',
              description: '이름',
              defaultMessage: '이름',
            })}
          </LabelText>

          <TextInput
            style={{ flex: 1 }}
            value={name}
            onChange={onDoctorNameChangeCallback}
          />
        </InfoContainer>

        <InfoContainer>
          <LabelText>
            {intl.formatMessage({
              id: '99-NewDoctorDialog-LabelText-children-email',
              description: '이메일',
              defaultMessage: '이메일',
            })}
          </LabelText>

          <TextInput
            style={{ flex: 1 }}
            inputType="email"
            value={email}
            onChange={(text) => {
              setEmail(text);
            }}
          />
        </InfoContainer>
      </DialogContentContainer>

      <StaffDialogButtonWrapper>
        <StaffDialogButtonContainer>
          <TextButton
            textColor={theme.color.MEDIUM_DARK}
            title={intl.formatMessage({
              id: '99-NewDoctorDialog-TextButton-title-01',
              description: '취소',
              defaultMessage: '취소',
            })}
            onClick={onClose}
          />
          <TextButton
            title={intl.formatMessage({
              id: '99-NewDoctorDialog-TextButton-title-02',
              description: '추가',
              defaultMessage: '추가',
            })}
            disabled={!(name.length && email.length)}
            onClick={() => {
              if (typeof createDoctor === 'function') {
                if (!TextUtil.verifyInRuleForEmail(email)) {
                  return showDialog('AlertDialog', {
                    message: intl.formatMessage({
                      id: '99-AlertDialog-MessageText-children-01',
                      description: '입력한 이메일 형식이 올바르지 않습니다.',
                      defaultMessage: '입력한 이메일 형식이 올바르지 않습니다.',
                    }),
                  });
                }
                createDoctor(name, email);
              }
            }}
            pending={medicalStaffCUState.pending}
          />
        </StaffDialogButtonContainer>
      </StaffDialogButtonWrapper>
    </DialogWrapper>
  );
}

export default NewDoctorDialog;
