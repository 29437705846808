import { useState, useRef } from 'react';

import DateUtil from 'util/DateUtil';
import NumberUtil from 'util/NumberUtil';

const DayInMilliseconds = 24 * 60 * 60 * 1000;
const { formatDateOnly, getUserLocationTime } = DateUtil;
const { getPhoneNumberFormat } = NumberUtil;

function useManageParcelFormData(ecgTest, parcel) {
  const isFormModified = useRef(false);

  const { estimatedEndDatetime, patientName, patientPhoneNumber } = ecgTest;

  const [formData, setFormData] = useState(() => {
    const nextDayOfEstimatedEndDatetime = formatDateOnly(
      new Date(estimatedEndDatetime).getTime() + DayInMilliseconds
    );

    return {
      senderName: parcel.senderName || patientName || '',
      senderPhoneNumber: getPhoneNumberFormat(
        parcel.senderPhoneNumber || patientPhoneNumber || ''
      ),
      address1: parcel.address1 || '',
      address2: parcel.address2 || '',
      pickUpDate:
        parcel.pickUpDate ||
        (estimatedEndDatetime
          ? nextDayOfEstimatedEndDatetime
          : formatDateOnly(getUserLocationTime())),
    };
  });

  function onChangeInput(value, key) {
    setFormData((prev) => ({ ...prev, [key]: value }));
    isFormModified.current = true;
  }

  return {
    formData,
    onChangeInput,
    isFormModified: isFormModified.current,
  };
}

export default useManageParcelFormData;
